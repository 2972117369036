import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { toast, ToastContainer } from "react-toastify";
import {
  PlusCircle,
  MinusCircle,
  Edit2,
  Trash2,
  Plus,
  AlertCircle,
} from "lucide-react";
import fetchUtil from "./utils/fetchUtil";

const API_ROOT = process.env.REACT_APP_API_ROOT;
const API_ROOT_SECOND = process.env.REACT_APP_API_ROOT;

const componentTypes = {
  windows: "Windows",
  rooftops: "Rooftops",
  walls: "Walls",
};

const componentFields = {
  windows: ["window_type", "number_of_windows", "area", "u_value", "g_value"],
  rooftops: [
    "description",
    "area",
    "thickness",
    "u_value",
    "color",
    "modernization",
  ],
  walls: [
    "description",
    "area",
    "thickness",
    "u_value",
    "color",
    "modernization",
  ],
};

const layerFieldsWhenEdit = {
  windows: ["heating", "cooling", "hggg"],
  rooftops: [
    "name",
    "thickness",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
  walls: [
    "name",
    "thickness",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
};

const BORDER_COLORS = {
  windows: "border-blue-500",
  rooftops: "border-green-500",
  walls: "border-red-500",
};

const API_URLS = {
  windows: `${API_ROOT}/api/layers/get_windows_list_with_layers_of_project`,
  rooftops: `${API_ROOT}/api/layers/get_rooftop_list_with_layers_of_project`,
  walls: `${API_ROOT}/api/layers/get_facade_list_with_layers_of_project`,
};

const POST_API_URLS = {
  windows: `${API_ROOT}/api/windows_interaction/`,
  rooftops: `${API_ROOT}/api/rooftop_interaction/`,
  walls: `${API_ROOT}/api/facade_interaction/`,
};

const POST_LAYER_API_URLS = {
  windows: `${API_ROOT}/api/windows_layer_interaction/`,
  rooftops: `${API_ROOT}/api/rooftop_layer_interaction/`,
  walls: `${API_ROOT}/api/facade_layer_interaction/`,
};

const TechnicalData = () => {
  // State
  const [components, setComponents] = useState({
    windows: [],
    rooftops: [],
    walls: [],
  });
  const [newComponentType, setNewComponentType] = useState("windows");
  const [newComponentData, setNewComponentData] = useState({});
  const [layerEdits, setLayerEdits] = useState({});
  const [showLayerCreation, setShowLayerCreation] = useState(null);
  const [newLayerData, setNewLayerData] = useState({});
  const [openCollapsible, setOpenCollapsible] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const projectID = localStorage.getItem("projectID");
  const token = localStorage.getItem("token");

  const transformData = (apiData) => {
    const transformedData = {
      windows: [],
      rooftops: [],
      walls: [],
    };

    Object.keys(apiData).forEach((key) => {
      apiData[key].forEach((component) => {
        const transformedComponent = {
          id: component.id,
          type: component.type,
          data: {
            description: component.description,
            area: component.area,
            ...component,
          },
          layers: component.layers.map((layer) => ({
            id: layer.id,
            name: layer.name,
            thickness: layer.thickness,
            density: layer.density,
            thermal_conductivity: layer.thermal_conductivity,
            heat_capacity: layer.heat_capacity,
          })),
        };
        transformedData[component.type].push(transformedComponent);
      });
    });

    return transformedData;
  };

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const windowsData = await fetchUtil(
          `${API_URLS.windows}/?project_id=${projectID}`,
          "GET",
          token
        );
        const rooftopsData = await fetchUtil(
          `${API_URLS.rooftops}/?project_id=${projectID}`,
          "GET",
          token
        );
        const wallsData = await fetchUtil(
          `${API_URLS.walls}/?project_id=${projectID}`,
          "GET",
          token
        );

        const windowsWithTypes = windowsData.map((item) => ({
          ...item,
          type: "windows",
        }));
        const rooftopsWithTypes = rooftopsData.map((item) => ({
          ...item,
          type: "rooftops",
        }));
        const wallsWithTypes = wallsData.map((item) => ({
          ...item,
          type: "walls",
        }));

        const transformedData = transformData({
          windows: windowsWithTypes,
          rooftops: rooftopsWithTypes,
          walls: wallsWithTypes,
        });

        setComponents(transformedData);
      } catch (error) {
        setError(error.message);
        toast.error("Failed to load components");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectID, token]);

  // Event Handlers
  const toggleCollapsible = (componentId, type) => {
    const uniqueId = `${type}-${componentId}`;
    setOpenCollapsible((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(uniqueId)) {
        newSet.delete(uniqueId);
      } else {
        newSet.add(uniqueId);
      }
      return newSet;
    });
  };

  const handleAddComponentClick = async () => {
    try {
      const postData = {
        ...newComponentData,
        project: projectID,
      };

      const response = await fetch(POST_API_URLS[newComponentType], {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to create the component"
        );
      }

      setComponents((prev) => ({
        ...prev,
        [newComponentType]: [
          ...prev[newComponentType],
          {
            id: responseData.id,
            type: newComponentType,
            data: responseData,
            layers: [],
          },
        ],
      }));

      toast.success("Component added successfully!");
      setNewComponentData({});
    } catch (error) {
      toast.error("Error adding component: " + error.message);
    }
  };

  const handleEditComponent = (componentId, field, value) => {
    setComponents((prev) => {
      const updatedComponents = { ...prev };
      Object.keys(updatedComponents).forEach((type) => {
        updatedComponents[type] = updatedComponents[type].map((comp) =>
          comp.id === componentId
            ? { ...comp, data: { ...comp.data, [field]: value } }
            : comp
        );
      });
      return updatedComponents;
    });
  };

  const updateComponentEdit = async (componentId, componentType) => {
    try {
      const component = components[componentType].find(
        (comp) => comp.id === componentId
      );
      if (!component) {
        throw new Error("Component not found");
      }

      const url = `${POST_API_URLS[componentType]}${componentId}/`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(component.data),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update component");
      }

      toast.success("Component updated successfully!");
    } catch (error) {
      toast.error("Error updating component: " + error.message);
    }
  };

  const handleDeleteComponent = async (componentId, componentType) => {
    try {
      const url = `${POST_API_URLS[componentType]}${componentId}/`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete component");
      }

      setComponents((prev) => ({
        ...prev,
        [componentType]: prev[componentType].filter(
          (comp) => comp.id !== componentId
        ),
      }));

      toast.success("Component deleted successfully!");
    } catch (error) {
      toast.error("Error deleting component: " + error.message);
    }
  };

  const handleInputChange = (field, value, layerId) => {
    setLayerEdits((prev) => ({
      ...prev,
      [layerId]: {
        ...prev[layerId],
        [field]: value,
      },
    }));
  };

  const saveLayerEdits = async (layerId, type, componentId) => {
    if (!layerEdits[layerId]) {
      toast.error("No changes to save");
      return;
    }

    try {
      const url = `${POST_LAYER_API_URLS[type]}${layerId}/`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(layerEdits[layerId]),
      });

      const updatedLayer = await response.json();
      if (!response.ok) {
        throw new Error("Failed to update layer");
      }

      setComponents((prev) => ({
        ...prev,
        [type]: prev[type].map((comp) => {
          if (comp.id === componentId) {
            return {
              ...comp,
              layers: comp.layers.map((layer) =>
                layer.id === layerId
                  ? { ...layer, ...layerEdits[layerId] }
                  : layer
              ),
            };
          }
          return comp;
        }),
      }));

      toast.success("Layer updated successfully!");
      setLayerEdits((prev) => {
        const newEdits = { ...prev };
        delete newEdits[layerId];
        return newEdits;
      });
    } catch (error) {
      toast.error("Error updating layer: " + error.message);
    }
  };

  const handleDeleteLayer = async (componentId, layerId, type) => {
    try {
      const url = `${POST_LAYER_API_URLS[type]}${layerId}/`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete layer");
      }

      setComponents((prev) => ({
        ...prev,
        [type]: prev[type].map((comp) => {
          if (comp.id === componentId) {
            return {
              ...comp,
              layers: comp.layers.filter((layer) => layer.id !== layerId),
            };
          }
          return comp;
        }),
      }));

      toast.success("Layer deleted successfully!");
    } catch (error) {
      toast.error("Error deleting layer: " + error.message);
    }
  };

  const handleConfirmNewLayer = async (componentId, componentType) => {
    try {
      const url = POST_LAYER_API_URLS[componentType];
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...newLayerData,
          [componentType === "windows"
            ? "window"
            : componentType === "rooftops"
            ? "rooftop"
            : "facade"]: componentId,
        }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to create layer");
      }

      setComponents((prev) => ({
        ...prev,
        [componentType]: prev[componentType].map((comp) => {
          if (comp.id === componentId) {
            return {
              ...comp,
              layers: [...comp.layers, responseData],
            };
          }
          return comp;
        }),
      }));

      toast.success("Layer created successfully!");
      setShowLayerCreation(null);
      setNewLayerData({});
    } catch (error) {
      toast.error("Error creating layer: " + error.message);
    }
  };

  if (isLoading) {
    return (
      <MainLayout>
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
          <div className="flex items-center gap-3">
            <div className="w-4 h-4 rounded-full bg-blue-500 animate-ping"></div>
            <span className="text-lg font-medium text-gray-700">
              Loading...
            </span>
          </div>
        </div>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
          <div className="flex items-center gap-3 text-red-600">
            <AlertCircle className="w-6 h-6" />
            <span className="text-lg font-medium">Error: {error}</span>
          </div>
        </div>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Component Creation Section */}
          <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
            <div className="flex flex-wrap gap-4 items-center">
              <select
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors cursor-pointer"
                value={newComponentType}
                onChange={(e) => setNewComponentType(e.target.value)}
              >
                {Object.keys(componentTypes).map((type) => (
                  <option key={type} value={type}>
                    {componentTypes[type]}
                  </option>
                ))}
              </select>

              {componentFields[newComponentType].map((field) => (
                <input
                  key={field}
                  type="text"
                  placeholder={field}
                  value={newComponentData[field] || ""}
                  onChange={(e) =>
                    setNewComponentData({
                      ...newComponentData,
                      [field]: e.target.value,
                    })
                  }
                  className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              ))}

              <button
                onClick={handleAddComponentClick}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center gap-2"
              >
                <Plus className="w-4 h-4" />
                Add Component
              </button>
            </div>
          </div>

          {/* Component List */}
          <div className="space-y-6">
            {Object.keys(components).map((type) => (
              <div
                key={type}
                className={`bg-white rounded-lg shadow-sm overflow-hidden border-l-4 ${BORDER_COLORS[type]}`}
              >
                <h2 className="text-xl font-semibold px-6 py-4 bg-gray-50">
                  {componentTypes[type]}
                </h2>

                <div className="divide-y divide-gray-200">
                  {components[type].map((component) => (
                    <div key={component.id} className="p-4">
                      {/* Component Header */}
                      <div className="flex items-center justify-between">
                        <button
                          onClick={() => toggleCollapsible(component.id, type)}
                          className="flex items-center gap-2 text-gray-700 hover:text-gray-900"
                        >
                          {openCollapsible.has(`${type}-${component.id}`) ? (
                            <MinusCircle className="w-5 h-5" />
                          ) : (
                            <PlusCircle className="w-5 h-5" />
                          )}
                        </button>

                        <div className="flex-1 flex gap-4 mx-4">
                          {componentFields[type].map((field) => (
                            <input
                              key={field}
                              type="text"
                              placeholder={field}
                              value={component.data[field] || ""}
                              onChange={(e) =>
                                handleEditComponent(
                                  component.id,
                                  field,
                                  e.target.value
                                )
                              }
                              className="px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 flex-1"
                            />
                          ))}
                        </div>

                        <div className="flex gap-2">
                          <button
                            onClick={() =>
                              updateComponentEdit(component.id, type)
                            }
                            className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
                          >
                            <Edit2 className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteComponent(
                                component.id,
                                component.type
                              )
                            }
                            className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </div>

                      {/* Collapsible Content */}
                      {openCollapsible.has(`${type}-${component.id}`) && (
                        <div className="mt-4 pl-8">
                          {/* Layers */}
                          <div className="space-y-4">
                            {component.layers.map((layer) => (
                              <div
                                key={layer.id}
                                className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg"
                              >
                                <div className="flex-1 grid grid-cols-5 gap-4">
                                  {Object.keys(layer).map((field) => (
                                    <input
                                      key={`${layer.id}-${field}`}
                                      type="text"
                                      value={
                                        layerEdits[layer.id]?.[field] !==
                                        undefined
                                          ? layerEdits[layer.id][field]
                                          : layer[field]
                                      }
                                      onChange={(e) =>
                                        handleInputChange(
                                          field,
                                          e.target.value,
                                          layer.id
                                        )
                                      }
                                      className="px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                  ))}
                                </div>
                                <div className="flex gap-2">
                                  <button
                                    onClick={() =>
                                      saveLayerEdits(
                                        layer.id,
                                        component.type,
                                        component.id
                                      )
                                    }
                                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
                                  >
                                    <Edit2 className="w-5 h-5" />
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDeleteLayer(
                                        component.id,
                                        layer.id,
                                        component.type
                                      )
                                    }
                                    className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                                  >
                                    <Trash2 className="w-5 h-5" />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>

                          {/* New Layer Section */}
                          {showLayerCreation === component.id ? (
                            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                              <div className="grid grid-cols-5 gap-4 mb-4">
                                {layerFieldsWhenEdit[component.type].map(
                                  (field) => (
                                    <input
                                      key={field}
                                      type="text"
                                      placeholder={`New Layer ${field}`}
                                      value={newLayerData[field] || ""}
                                      onChange={(e) =>
                                        setNewLayerData({
                                          ...newLayerData,
                                          [field]: e.target.value,
                                        })
                                      }
                                      className="px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                  )
                                )}
                              </div>
                              <div className="flex justify-end gap-4">
                                <button
                                  onClick={() =>
                                    handleConfirmNewLayer(
                                      component.id,
                                      component.type
                                    )
                                  }
                                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                                >
                                  Confirm
                                </button>
                                <button
                                  onClick={() => setShowLayerCreation(null)}
                                  className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          ) : (
                            <button
                              onClick={() => setShowLayerCreation(component.id)}
                              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
                            >
                              <Plus className="w-4 h-4" />
                              Add New Layer
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <ToastContainer />
      </div>
    </MainLayout>
  );
};

export default TechnicalData;
