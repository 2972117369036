import React, { useState } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import {
  Card,
  FormInput,
  Button,
  Alert,
  FormGroup,
} from "./ui/base-components";

const RegisterUser = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: "", text: "" });

    if (formData.password !== formData.confirmPassword) {
      setMessage({ type: "error", text: "Passwords do not match" });
      return;
    }

    try {
      const formPayload = new FormData();
      formPayload.append("email", formData.username);
      formPayload.append("password", formData.password);
      formPayload.append("confirmPassword", formData.confirmPassword);

      const response = await fetch(`${apiRoot}/api/user`, {
        method: "POST",
        body: formPayload,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to register");
      }

      setMessage({ type: "success", text: "User created successfully!" });
      setFormData({ username: "", password: "", confirmPassword: "" });
    } catch (error) {
      setMessage({
        type: "error",
        text: error.message || "Failed to register",
      });
    }
  };

  return (
    <MainLayout>
      <div className="p-6 mt-4">
        <div className="max-w-md mx-auto">
          <Card>
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  Register New User
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Create a new user account by providing the required
                  information.
                </p>
              </div>

              {message.text && (
                <Alert type={message.type} message={message.text} />
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <FormGroup>
                  <FormInput
                    id="username"
                    label="Username"
                    type="text"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />

                  <FormInput
                    id="password"
                    label="Password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />

                  <FormInput
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <Button type="submit" className="w-full">
                  Register User
                </Button>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
};

export default RegisterUser;
