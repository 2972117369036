import React from "react";
import { useNavigate } from "react-router-dom";
import "./RayTracingConfig.css";

const RayTracingConfig = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Raytracing Configuration submitted");
    navigate("/");
  };

  return (
    <div className="raytracing-config">
      <h1>Raytracing Configuration</h1>
      <button className="back-button" onClick={() => navigate("/")}>
        Back to Menu
      </button>
      <form onSubmit={handleSubmit}>
        <section className="pre-raytracing">
          <h2>Pre Ray Tracing</h2>
          <div className="form-group">
            <label>Meshing Method</label>
            <select>
              <option>Area Meshing</option>
              <option>Grid Meshing</option>
            </select>
          </div>

          <div className="form-group">
            <label>Distance Between Sensors</label>
            <input type="number" defaultValue="1" />
          </div>

          <div className="form-group">
            <label>Minimum Distance From Sides</label>
            <input type="number" defaultValue="1" />
          </div>

          <div className="form-group">
            <label>Sensor Offset</label>
            <input type="number" defaultValue="0" />
          </div>

          <div className="form-group">
            <label>Surface Orientation</label>
            <select>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="form-group">
            <label>Window Schedule File</label>
            <input type="file" accept=".csv" />
          </div>

          <div className="form-group">
            <label>Room Window Clustering</label>
            <select>
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>

          <h3>Cluster Hierarchy</h3>
          <p>Note: Not all the combinations are logical for algorithms!</p>
          <div className="cluster-hierarchy">
            <select>
              <option>WINDOW</option>
            </select>
            <select>
              <option>ROOM</option>
            </select>
            <select>
              <option>FLOOR</option>
            </select>
            <select>
              <option>BUILDING</option>
            </select>
            <select>
              <option>NONE</option>
            </select>
          </div>
        </section>

        <section className="raytracing">
          <h2>Ray Tracing</h2>
          <div className="form-group">
            <label>Consider Terrain</label>
            <select>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>

          <div className="form-group">
            <label>Assigning Diagrams</label>
            <select>
              <option>DOUBLE</option>
              <option>SINGLE</option>
            </select>
          </div>

          <div className="form-group">
            <label>Consider Vegetation</label>
            <select>
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>

          <div className="form-group">
            <label>Plot Skyline</label>
            <select>
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>

          <div className="form-group">
            <label>Store Skyline</label>
            <select>
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>
        </section>

        <button className="submit-button" type="submit">
          Next
        </button>
      </form>
    </div>
  );
};

export default RayTracingConfig;
