import React from "react";

export const TabButton = ({
  active,
  onClick,
  children,
  step,
  disabled = false,
  className = "",
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`
      px-6 py-3 text-sm font-medium transition-all duration-200
      ${
        active
          ? "bg-blue-50 text-blue-600 border-b-2 border-blue-500"
          : "text-gray-500 hover:text-gray-700"
      }
      ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
      ${className}
    `}
  >
    {step && (
      <span className="inline-block w-6 h-6 rounded-full bg-current text-white text-center mr-2">
        {step}
      </span>
    )}
    {children}
  </button>
);
