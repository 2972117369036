import React from "react";
import "./TopNavbar.css";

const TopNavbar = () => {
  return (
    <nav className="top-navbar">
      <div className="navbar-content">
        <h1>Digital Twin Platform</h1>
        <div className="nav-links">
          <a href="#features" className="nav-link">
            Features
          </a>
          <a href="#activity-report" className="nav-link">
            Activity Report
          </a>
        </div>
      </div>
    </nav>
  );
};

export default TopNavbar;
