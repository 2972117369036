import React from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import { Settings, ChevronRight, AlertCircle } from "lucide-react";

const FeatureCard = ({ title, description, onClick }) => (
  <div
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyPress={(e) => e.key === "Enter" && onClick()}
    className="bg-white rounded-xl shadow-sm p-6 transition-all duration-200 
      hover:shadow-lg hover:scale-105 cursor-pointer border border-gray-100"
  >
    <div className="flex items-start gap-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Settings className="w-6 h-6 text-blue-600" />
      </div>
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      <ChevronRight className="w-5 h-5 text-gray-400" />
    </div>
  </div>
);

const Features = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Project Generic Information Setup",
      description: "Create a project for platform or digital twin viewer",
      path: "viewer-insertion",
    },
    {
      title: "Project Generic Information Setup",
      description:
        "Create a project for platform or digital twin viewer with uploading a file",
      path: "excel-uploader",
    },
  ];

  const handleFeatureClick = (path) => {
    navigate(path);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        Project Setup Options
      </h2>
      <div className="grid grid-cols-1 gap-6">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            title={feature.title}
            description={feature.description}
            onClick={() => handleFeatureClick(`/${feature.path}`)}
          />
        ))}
      </div>
    </div>
  );
};

const NewProjectCreation = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (!token) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="bg-white p-8 rounded-xl shadow-sm max-w-md w-full">
          <div className="flex flex-col items-center gap-4">
            <AlertCircle className="w-12 h-12 text-red-500" />
            <h2 className="text-2xl font-bold text-gray-900">
              Authentication Required
            </h2>
            <p className="text-gray-600 text-center mb-4">
              Please log in to access the project creation page
            </p>
            <button
              onClick={() => navigate("/login")}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                transition-colors w-full max-w-xs"
            >
              Go to Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 text-center">
                Create New Project
              </h1>
              <p className="mt-2 text-center text-gray-600">
                Set up and configure your new digital twin project
              </p>
            </div>

            <div className="p-6">
              <Features />
            </div>

            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
              <div className="flex items-start gap-3 text-sm text-gray-600">
                <div className="flex-shrink-0 mt-1">
                  <Settings className="w-5 h-5 text-gray-400" />
                </div>
                <p>
                  Configure your project's basic settings before proceeding with
                  detailed setup options. You'll be able to customize more
                  specific settings after the initial configuration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default NewProjectCreation;
