import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import {
  Loader,
  AlertCircle,
  ChevronRight,
  Activity,
  Users,
  Folder,
} from "lucide-react";
import fetchUtil from "./utils/fetchUtil";
import useActivityStore from "./hooks/useActivity";

// Feature Card Component
const FeatureCard = ({ title, description, onClick, icon: Icon }) => (
  <div
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyPress={(e) => e.key === "Enter" && onClick()}
    className="bg-white rounded-xl shadow-sm p-6 transition-all duration-200 hover:shadow-lg hover:scale-105 cursor-pointer"
  >
    <div className="flex items-start gap-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      <ChevronRight className="w-5 h-5 text-gray-400" />
    </div>
  </div>
);

// Features Section Component
const FeaturesSection = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Project Management",
      description: "Create new projects or edit existing ones",
      path: "project-management",
      icon: Folder,
    },
    {
      title: "User Management",
      description: "Create and manage user accounts with dedicated credentials",
      path: "user-management",
      icon: Users,
    },
    {
      title: "Project Execution",
      description:
        "Edit and manage existing platform or digital twin viewer projects",
      path: "project-processing",
      icon: Activity,
    },
  ];

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        Platform Features
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {features.map((feature) => (
          <FeatureCard
            key={feature.path}
            title={feature.title}
            description={feature.description}
            onClick={() => navigate(`/${feature.path}`)}
            icon={feature.icon}
          />
        ))}
      </div>
    </div>
  );
};
export const ActivityTable = ({ reportRows }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Action
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Status
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Description
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Project Name
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Feature
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Datetime
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {reportRows.map((row, index) => (
          <tr key={`${row.datetime}-${index}`} className="hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
              {row.action}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm">
              <span
                className={`px-2 py-1 rounded-full text-xs font-medium
                ${
                  row.status >= 400
                    ? "bg-red-100 text-red-800"
                    : "bg-green-100 text-green-800"
                }`}
              >
                {row.status}
              </span>
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 max-w-xs truncate">
              {row.description}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {row.project_name}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {row.feature || "-"}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {new Date(row.datetime).toLocaleString()}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

// const ActivityTable = ({ reportRows }) => (
//   <div className="overflow-x-auto">
//     <table className="min-w-full divide-y divide-gray-200">
//       <thead className="bg-gray-50">
//         <tr>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Action
//           </th>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Status
//           </th>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Description
//           </th>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Project Name
//           </th>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Feature
//           </th>
//           <th
//             scope="col"
//             className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//           >
//             Datetime
//           </th>
//         </tr>
//       </thead>
//       <tbody className="bg-white divide-y divide-gray-200">
//         {reportRows.map((row, index) => (
//           <tr key={`${row.datetime}-${index}`} className="hover:bg-gray-50">
//             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
//               {row.action_type}
//             </td>
//             <td className="px-6 py-4 whitespace-nowrap text-sm">
//               <span
//                 className={`px-2 py-1 rounded-full text-xs font-medium
//                 ${
//                   row.status_code >= 400
//                     ? "bg-red-100 text-red-800"
//                     : "bg-green-100 text-green-800"
//                 }`}
//               >
//                 {row.status_code}
//               </span>
//             </td>
//             <td className="px-6 py-4 text-sm text-gray-500 max-w-xs truncate">
//               {row.description}
//             </td>
//             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//               {row.project}
//             </td>
//             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//               {row.feature || "-"}
//             </td>
//             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//               {new Date(row.datetime).toLocaleString()}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
// );

// Activity Report Section
// const ActivityReport = () => {
//   const [reportRows, setReportRows] = useState([]);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(true);

//   const apiRoot = process.env.REACT_APP_API_ROOT;
//   const token = localStorage.getItem("token");
//   const userId = localStorage.getItem("userID");
//   const projectID = localStorage.getItem("projectID");

//   useEffect(() => {
//     const fetchActivityReport = async () => {
//       try {
//         const data = await fetchUtil(
//           `${apiRoot}/api/report/?project_id=${projectID}&user_id=${userId}`,
//           "GET",
//           token
//         );

//         const sortedData = data
//           .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
//           .slice(0, 30);

//         setReportRows(sortedData);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (token) {
//       fetchActivityReport();
//     }
//   }, [apiRoot, token, userId, projectID]);

//   return (
//     <div>
//       <h2 className="text-2xl font-bold text-gray-900 mb-6">
//         Last Activity Report
//       </h2>

//       {loading ? (
//         <div className="flex items-center justify-center py-12">
//           <Loader className="w-8 h-8 text-blue-500 animate-spin" />
//         </div>
//       ) : error ? (
//         <div className="flex items-center gap-2 p-4 bg-red-50 border border-red-200 rounded-lg">
//           <AlertCircle className="w-5 h-5 text-red-500" />
//           <p className="text-sm text-red-700">{error}</p>
//         </div>
//       ) : (
//         <div className="bg-white rounded-xl shadow-sm overflow-hidden">
//           <ActivityTable reportRows={reportRows} />
//         </div>
//       )}
//     </div>
//   );
// };

export const ActivityReport = () => {
  const { activities, loading, error } = useActivityStore();

  React.useEffect(() => {
    useActivityStore.getState().fetchActivities();
  }, []);

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">
        Last Activity Report
      </h2>

      {loading ? (
        <div className="flex items-center justify-center py-12">
          <Loader className="w-8 h-8 text-blue-500 animate-spin" />
        </div>
      ) : error ? (
        <div className="flex items-center gap-2 p-4 bg-red-50 border border-red-200 rounded-lg">
          <AlertCircle className="w-5 h-5 text-red-500" />
          <p className="text-sm text-red-700">{error}</p>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <ActivityTable reportRows={activities} />
        </div>
      )}
    </div>
  );
};

// Main Component
const PlatformFeatures = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  if (!token) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex items-center gap-2 p-4 bg-red-50 border border-red-200 rounded-lg">
          <AlertCircle className="w-5 h-5 text-red-500" />
          <p className="text-sm text-red-700">
            Please log in to view this page.
          </p>
        </div>
      </div>
    );
  }

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <FeaturesSection />
          <ActivityReport />
        </div>
      </div>
    </MainLayout>
  );
};

export default PlatformFeatures;
