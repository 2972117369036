import React from "react";

import { Users, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";

const Feature = ({ title, description, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white rounded-xl shadow-sm p-6 hover:shadow-lg transition-all duration-200 
        hover:-translate-y-1 cursor-pointer border border-gray-200 relative group"
    >
      <h2 className="text-xl font-semibold text-gray-900 mb-3">{title}</h2>
      <p className="text-gray-600 text-sm mb-8">{description}</p>
      <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity">
        <ArrowRight className="w-5 h-5 text-blue-600" />
      </div>
    </div>
  );
};

const Features = () => {
  const userId = localStorage.getItem("userID");
  const navigate = useNavigate();

  const features = [
    {
      title: "CREATE YOUR OWN USER",
      description:
        "You can create your own user with dedicated username and password.",
      path: "user-registeration",
    },
    {
      title: "ASSIGN PROJECT TO USERS",
      description: "Here you can assign specific project to users.",
      path: "assign-projects",
    },
    {
      title: "DELETE USER",
      description: "Here, you can delete your specific user",
      path: "delete-user",
    },
    {
      title: "USERS'S PROJECTS",
      description:
        "Here, you can edit an existing project for platform or digital twin viewer",
      path: "project-user-relation",
    },
    {
      title: "RESET PASSWORD",
      description: "Here, you can reset password for your desired users",
      path: `password-reset/${userId}`,
    },
  ];

  const handleFeatureClick = (path) => {
    navigate(path);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {features.map((feature, index) => (
        <Feature
          key={index}
          title={feature.title}
          description={feature.description}
          onClick={() => handleFeatureClick(`/${feature.path}`)}
        />
      ))}
    </div>
  );
};

// Main Component
const UserManagement = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <p className="text-xl text-gray-600 mb-4">
            Please login to view this page.
          </p>
          <a
            href="/login"
            className="inline-block px-6 py-3 bg-blue-600 text-white rounded-lg 
              hover:bg-blue-700 transition-colors"
          >
            Go to Login
          </a>
        </div>
      </div>
    );
  }

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Header */}
            <div className="px-6 py-8 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    User Management
                  </h1>
                  <p className="mt-1 text-gray-600">
                    Manage users, permissions, and access controls
                  </p>
                </div>
                <div className="p-3 bg-blue-50 rounded-lg">
                  <Users className="w-6 h-6 text-blue-600" />
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="p-6">
              <Features />

              {/* Helper Information */}
              <div className="mt-8 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <h3 className="text-sm font-medium text-gray-900 mb-2">
                  Quick Tips
                </h3>
                <ul className="space-y-2">
                  <li className="text-sm text-gray-600 flex items-start">
                    <span className="mr-2">•</span>
                    Create and manage user accounts with specific permissions
                  </li>
                  <li className="text-sm text-gray-600 flex items-start">
                    <span className="mr-2">•</span>
                    Assign users to projects and control their access levels
                  </li>
                  <li className="text-sm text-gray-600 flex items-start">
                    <span className="mr-2">•</span>
                    Manage user credentials and reset passwords when needed
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default UserManagement;
