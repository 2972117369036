import React, { useState, useCallback } from "react";
import { UploadCloud } from "lucide-react";

export const useFileUpload = (onFileSelect, acceptedFileTypes) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
          alert("Invalid file type. Please upload a supported file.");
          return;
        }
        setFileName(file.name);
        onFileSelect(file);
      }
    },
    [onFileSelect, acceptedFileTypes]
  );

  const handleFileSelect = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
          alert("Invalid file type. Please upload a supported file.");
          return;
        }
        setFileName(file.name);
        onFileSelect(file);
      }
    },
    [onFileSelect, acceptedFileTypes]
  );

  return {
    isDragging,
    fileName,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileSelect,
    setFileName,
  };
};

// Reusable FileUpload component
export const FileUpload = ({
  onFileSelect,
  acceptedFileTypes = [],
  acceptedExtensions = "",
  label = "Upload File",
  description = "Drag and drop your file here or click to browse",
  className = "",
}) => {
  const {
    isDragging,
    fileName,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    handleFileSelect,
  } = useFileUpload(onFileSelect, acceptedFileTypes);

  return (
    <div className="space-y-2">
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div
        className={`relative border-2 border-dashed rounded-lg p-6 
          ${
            isDragging
              ? "border-blue-500 bg-blue-50"
              : "border-gray-300 hover:border-gray-400"
          }
          transition-all duration-200 cursor-pointer
          ${className}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept={acceptedExtensions}
          onChange={handleFileSelect}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <div className="text-center">
          <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">{description}</p>
          <p className="mt-1 text-xs text-gray-500">{fileName}</p>
        </div>
      </div>
    </div>
  );
};

// Status message component for upload feedback
export const UploadStatus = ({ status, successMessage, errorMessage }) => {
  if (!status) return null;

  const statusConfig = {
    success: {
      bgColor: "bg-green-50",
      borderColor: "border-green-200",
      textColor: "text-green-800",
      message: successMessage || "File uploaded successfully!",
    },
    error: {
      bgColor: "bg-red-50",
      borderColor: "border-red-200",
      textColor: "text-red-800",
      message: errorMessage || "Error uploading file. Please try again.",
    },
  };

  const config = statusConfig[status];
  if (!config) return null;

  return (
    <div
      className={`p-4 ${config.bgColor} border ${config.borderColor} rounded-lg`}
    >
      <p className={`text-sm ${config.textColor} text-center`}>
        {config.message}
      </p>
    </div>
  );
};

export const SubmitButton = ({
  isSubmitting,
  isDisabled,
  text = "Upload File",
}) => (
  <button
    type="submit"
    disabled={isSubmitting || isDisabled}
    className={`w-full px-4 py-3 text-sm font-medium rounded-lg
      ${
        isSubmitting || isDisabled
          ? "bg-gray-300 cursor-not-allowed"
          : "bg-blue-600 hover:bg-blue-700"
      }
      text-white transition-colors duration-200`}
  >
    {isSubmitting ? "Uploading..." : text}
  </button>
);
