import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { FileUpload, UploadStatus, SubmitButton } from "./FileUpload";
import JSZip from "jszip";

const ListDisplay = ({ title, list }) => {
  if (list.length === 0) return null;
  return (
    <div className="mb-6">
      <h3 className="text-lg font-medium text-gray-800 mb-3 pb-2 border-b border-gray-200">
        {title}
      </h3>
      <div className="flex flex-wrap gap-2">
        {list.map((item, index) => (
          <span
            key={index}
            className="inline-block px-3 py-1.5 bg-white border border-gray-200 
              rounded-md text-sm text-gray-700"
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

const SceneSetupInsertion = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [zipFileNames, setZipFileNames] = useState([]);
  const [projectData, setProjectData] = useState({
    ceilingList: [],
    floorList: [],
    externalList: [],
    internalList: [],
    doorsList: [],
    stairsList: [],
    pvList: [],
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [zipFile, setZipFile] = useState(null);
  const [floorOptions, setFloorOptions] = useState([]);
  const [orderedFloors, setOrderedFloors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const projectID = localStorage.getItem("projectID");
  const userID = localStorage.getItem("userID");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchProjectData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiRoot}/api/project_info/?project_id=${projectID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch project data");
        }

        const data = await response.json();
        if (data.length > 0 && data[0]) {
          const parseJsonField = (field, defaultValue = []) => {
            try {
              return field ? JSON.parse(field) : defaultValue;
            } catch (e) {
              console.error(`Error parsing field:`, e);
              return defaultValue;
            }
          };

          setProjectData({
            ceilingList: parseJsonField(data[0].ceiling_list),
            floorList: parseJsonField(data[0].floor_list),
            externalList: parseJsonField(data[0].external_list),
            internalList: parseJsonField(data[0].internal_list),
            doorsList: parseJsonField(data[0].doors),
            stairsList: parseJsonField(data[0].stairs),
            pvList: parseJsonField(data[0].pv_panels),
          });
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (token && projectID) {
      fetchProjectData();
    }
  }, [apiRoot, projectID, token]);

  const handleFileSelect = async (file) => {
    setZipFile(file);
    if (file) {
      try {
        const zip = new JSZip();
        const contents = await zip.loadAsync(file);
        const fileNames = Object.keys(contents.files);
        setZipFileNames(fileNames);
        const floorNames = extractFloorNames(fileNames);
        setFloorOptions([...new Set(floorNames)]);
      } catch (error) {
        console.error("Error reading ZIP file:", error);
        setUploadStatus("error");
      }
    }
  };

  const categorizeFiles = (fileNames, orderedFloors) => {
    const facadeRegex = /(.+?)_facade.*\.glb/i;
    const floorRegex = /(.+?)_floors.*\.glb/i;
    const interiorRegex = /(.+?)_(interior|internal|inner).*\.glb/i;
    const pvRegex = /(.+?)_(pvs|PVs|pv).*\.glb/i;
    const stairsRegex = /(\d+\.\w+)?_stairs.*\.glb/i;
    const doorsRegex = /(\d+\.\w+)?_doors.*\.glb/i;

    const categorizedFiles = {
      facadeList: [],
      floorList: [],
      interiorList: [],
      pvPanelList: [],
      stairsList: [],
      doorsList: [],
    };

    const relevantFileNames = fileNames.filter(
      (fileName) =>
        !fileName.includes("__MACOSX") &&
        !fileName.includes(".DS_Store") &&
        fileName.endsWith(".glb")
    );

    orderedFloors.forEach((floor) => {
      relevantFileNames.forEach((fileName) => {
        const parts = fileName.split("/");
        const baseFileName = parts.pop();

        if (baseFileName.toLowerCase().includes(floor.toLowerCase())) {
          if (facadeRegex.test(baseFileName)) {
            categorizedFiles.facadeList.push(baseFileName.replace(".glb", ""));
          } else if (floorRegex.test(baseFileName)) {
            categorizedFiles.floorList.push(baseFileName.replace(".glb", ""));
          } else if (interiorRegex.test(baseFileName)) {
            categorizedFiles.interiorList.push(
              baseFileName.replace(".glb", "")
            );
          } else if (pvRegex.test(baseFileName)) {
            categorizedFiles.pvPanelList.push(baseFileName.replace(".glb", ""));
          } else if (doorsRegex.test(baseFileName)) {
            categorizedFiles.doorsList.push(baseFileName.replace(".glb", ""));
          } else if (stairsRegex.test(baseFileName)) {
            categorizedFiles.stairsList.push(baseFileName.replace(".glb", ""));
          }
        }
      });
    });

    return categorizedFiles;
  };

  const extractFloorNames = (filePaths) => {
    const uniqueFloorNames = new Set();

    filePaths.forEach((path) => {
      if (!path.includes("__MACOSX") && !path.includes(".DS_Store")) {
        const parts = path.split("/").filter((part) => part);
        if (parts.length >= 2) {
          const floorName = parts[parts.length - 2];
          uniqueFloorNames.add(floorName);
        }
      }
    });

    return Array.from(uniqueFloorNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!zipFile) {
      setUploadStatus("error");
      return;
    }

    setIsSubmitting(true);
    try {
      // Upload zip file
      const uploadFormData = new FormData();
      uploadFormData.append("file", zipFile);

      const uploadResponse = await fetch(
        `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userID}&file_type=glb`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: uploadFormData,
        }
      );

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload file");
      }

      const categorizedFiles = categorizeFiles(zipFileNames, orderedFloors);

      const formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("floor_list", JSON.stringify(orderedFloors));
      formData.append(
        "internal_list",
        JSON.stringify(categorizedFiles.interiorList)
      );
      formData.append(
        "ceiling_list",
        JSON.stringify(categorizedFiles.floorList)
      );
      formData.append(
        "external_list",
        JSON.stringify(categorizedFiles.facadeList)
      );
      formData.append("doors", JSON.stringify(categorizedFiles.doorsList));
      formData.append("stairs", JSON.stringify(categorizedFiles.stairsList));
      formData.append("pv_panels", JSON.stringify(["PV", "PVs"]));

      let projectInfoUrl = `${apiRoot}/api/project_info/`;
      let projectInfoMethod = "POST";

      if (isEditMode) {
        const getResponse = await fetch(
          `${apiRoot}/api/project_info/?project_id=${projectID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (getResponse.ok) {
          const existingData = await getResponse.json();
          if (existingData && existingData.length > 0) {
            projectInfoUrl = `${apiRoot}/api/project_info/${existingData[0].id}/`;
            projectInfoMethod = "PATCH";
          }
        }
      }

      const projectInfoResponse = await fetch(projectInfoUrl, {
        method: projectInfoMethod,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!projectInfoResponse.ok) {
        throw new Error(`Error: ${projectInfoResponse.statusText}`);
      }

      setUploadStatus("success");
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      console.error("Error:", error);
      setUploadStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addToOrderedFloors = (floor) => {
    if (!orderedFloors.includes(floor)) {
      setOrderedFloors([...orderedFloors, floor]);
      setFloorOptions(floorOptions.filter((f) => f !== floor));
    }
  };

  const removeFromOrderedFloors = (floor) => {
    setOrderedFloors(orderedFloors.filter((f) => f !== floor));
    setFloorOptions([...floorOptions, floor]);
  };

  if (isLoading) {
    return <div className="loading-state">Loading project data...</div>;
  }

  if (error) {
    return <div className="error-state">{error}</div>;
  }
  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6">
            <div className="px-6 py-8 text-center">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Scene Setup Configuration
              </h1>
              <p className="text-gray-500">
                Configure and manage your project's 3D scene settings
              </p>
            </div>

            {/* Data Lists */}
            <div className="px-6 py-4 bg-gray-50 border-t border-b border-gray-200">
              <ListDisplay
                title="External/Facade List"
                list={projectData.externalList}
              />
              <ListDisplay
                title="Ceiling GLB List"
                list={projectData.ceilingList}
              />
              <ListDisplay
                title="Internal Walls List"
                list={projectData.internalList}
              />
              <ListDisplay
                title="Stairs GLB List"
                list={projectData.stairsList}
              />
              <ListDisplay title="Doors List" list={projectData.doorsList} />
            </div>

            {/* File Upload Section */}
            <div className="p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                <FileUpload
                  label="Upload GLB Files"
                  description="Drag and drop your ZIP file here or click to browse"
                  onFileSelect={handleFileSelect}
                  acceptedExtensions=".zip"
                  acceptedFileTypes={[
                    "application/zip",
                    "application/x-zip-compressed",
                  ]}
                />

                <UploadStatus
                  status={uploadStatus}
                  successMessage="Scene configuration updated successfully!"
                  errorMessage="Error updating scene configuration. Please try again."
                />

                {/* Floor Management */}
                <div className="mt-8">
                  <h3 className="text-lg font-medium text-gray-900 mb-4">
                    Floor Configuration
                  </h3>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Available Floors */}
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-2">
                        Available Floors
                      </h4>
                      <select
                        multiple
                        size="10"
                        className="w-full border border-gray-300 rounded-md shadow-sm 
                          focus:ring-blue-500 focus:border-blue-500"
                        onDoubleClick={(e) => {
                          if (e.target.value)
                            addToOrderedFloors(e.target.value);
                        }}
                      >
                        {floorOptions.map((floor, index) => (
                          <option key={index} value={floor}>
                            {floor}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex lg:flex-col justify-center gap-3">
                      <button
                        type="button"
                        onClick={() => {
                          const selected =
                            document.querySelector("select[multiple]").value;
                          if (selected) addToOrderedFloors(selected);
                        }}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700
                          transition-colors duration-200"
                      >
                        Add →
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const selected =
                            document.querySelectorAll("select[multiple]")[1]
                              .value;
                          if (selected) removeFromOrderedFloors(selected);
                        }}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700
                          transition-colors duration-200"
                      >
                        ← Remove
                      </button>
                    </div>

                    {/* Ordered Floors */}
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-2">
                        Ordered Floors
                      </h4>
                      <select
                        multiple
                        size="10"
                        className="w-full border border-gray-300 rounded-md shadow-sm 
                          focus:ring-blue-500 focus:border-blue-500"
                        onDoubleClick={(e) => {
                          if (e.target.value)
                            removeFromOrderedFloors(e.target.value);
                        }}
                      >
                        {orderedFloors.map((floor, index) => (
                          <option key={index} value={floor}>
                            {floor}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {/* Edit Mode Toggle */}
                <div className="mt-8 p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      id="editMode"
                      checked={isEditMode}
                      onChange={(e) => setIsEditMode(e.target.checked)}
                      className="h-4 w-4 text-blue-600 rounded border-gray-300 
                        focus:ring-blue-500"
                    />
                    <label htmlFor="editMode" className="text-sm text-gray-700">
                      Edit Scene Order
                    </label>
                    <span className="text-sm text-gray-500">
                      Check this to modify existing scene configuration
                    </span>
                  </div>
                </div>

                {/* Submit Button */}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  isDisabled={!zipFile}
                  text="Save Configuration"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default SceneSetupInsertion;
