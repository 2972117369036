import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadCloud, Loader } from "lucide-react";
import useActivityStore from "./hooks/useActivity";
import { logProjectActivity } from "./utils/activityLogger";
const VirtualWholeSensorSetup = () => {
  const [fileData, setFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [sensorsCount, setSensorsCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const apiRootSecond = process.env.REACT_APP_API_ROOT_OPERATIONAL;

  const fetchSensorCount = async () => {
    try {
      const response = await fetch(
        `${apiRoot}/api/sensorslight/count/?project_id=${projectID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch sensor count");
      }

      const data = await response.json();
      setSensorsCount(data.count);
    } catch (err) {
      console.error("Error fetching sensor count:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSensorCount();
  }, [apiRoot, projectID, token]);

  const showCustomToast = (message, type, duration = 3000) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);

    if (type !== "loading") {
      setTimeout(() => {
        setShowToast(false);
      }, duration);
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      setFileData(file);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fileData) {
      setSubmitStatus("error");
      showCustomToast("Please select a file to upload", "error");
      await logProjectActivity(
        "VIRTUAL_SENSOR_UPLOAD",
        400,
        "Failed to upload virtual sensors - No file selected",
        "Virtual Sensor Setup"
      );
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);
    showCustomToast("Importing virtual sensors. Please wait...", "loading");

    try {
      const formData = new FormData();
      formData.append("file", fileData);
      formData.append("project_id", projectID);

      const response = await fetch(`${apiRootSecond}/api/import-sensors`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();

      if (data.message === "Data imported successfully") {
        showCustomToast("Virtual sensors imported successfully!", "success");
        setSubmitStatus("success");
        setFileData(null);
        setFileName("No file chosen");
        await fetchSensorCount();

        await logProjectActivity(
          "VIRTUAL_SENSOR_UPLOAD",
          200,
          `Successfully imported virtual sensors from ${fileName}`,
          "Virtual Sensor Setup"
        );
      } else {
        throw new Error(data.error || "Upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      showCustomToast(
        "Error importing virtual sensors. Please try again.",
        "error"
      );
      setSubmitStatus("error");

      await logProjectActivity(
        "VIRTUAL_SENSOR_UPLOAD",
        500,
        `Failed to import virtual sensors: ${error.message}`,
        "Virtual Sensor Setup"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        {showToast && (
          <div className="fixed top-4 right-4 z-50">
            <div
              className={`rounded-lg px-4 py-3 shadow-lg max-w-md ${
                toastType === "success"
                  ? "bg-green-50 border border-green-200"
                  : toastType === "error"
                  ? "bg-red-50 border border-red-200"
                  : "bg-blue-50 border border-blue-200"
              }`}
            >
              <div className="flex items-center space-x-3">
                {toastType === "loading" && (
                  <Loader className="h-5 w-5 animate-spin text-blue-500" />
                )}
                <p
                  className={`text-sm font-medium ${
                    toastType === "success"
                      ? "text-green-800"
                      : toastType === "error"
                      ? "text-red-800"
                      : "text-blue-800"
                  }`}
                >
                  {toastMessage}
                </p>
                {toastType !== "loading" && (
                  <button
                    onClick={hideToast}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    ×
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 text-center border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Virtual Sensor Configuration
              </h1>
              <p className="text-gray-500">
                Configure virtual sensors for environmental monitoring
              </p>
            </div>

            {!isLoading && sensorsCount !== null && (
              <div className="px-6 py-4">
                <div className="bg-white rounded-lg border border-gray-200 p-6 text-center">
                  <p className="text-sm font-medium text-gray-500 uppercase tracking-wide">
                    Current Sensors
                  </p>
                  <p className="mt-2 text-3xl font-bold text-gray-900">
                    {sensorsCount}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Active virtual sensors in this project
                  </p>
                </div>
              </div>
            )}

            <div className="p-6">
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Virtual Sensor Data File
                  </label>
                  <div
                    className={`relative border-2 border-dashed rounded-lg p-6 
                      ${
                        isDragging
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-300 hover:border-gray-400"
                      }
                      transition-all duration-200 cursor-pointer
                    `}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileSelect}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                    <div className="text-center">
                      <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
                      <p className="mt-2 text-sm text-gray-600">
                        Drag and drop your CSV file here or click to browse
                      </p>
                      <p className="mt-1 text-xs text-gray-500">{fileName}</p>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting || !fileData}
                  className={`w-full px-4 py-3 text-sm font-medium rounded-lg
                    ${
                      isSubmitting || !fileData
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }
                    text-white transition-colors duration-200
                  `}
                >
                  {isSubmitting
                    ? "Importing Sensors..."
                    : "Import Virtual Sensors"}
                </button>
              </form>

              <div className="mt-8 bg-gray-50 rounded-lg p-6">
                <h2 className="text-sm font-medium text-gray-900 mb-4">
                  File Requirements
                </h2>
                <ul className="space-y-2">
                  <li className="flex items-start text-sm text-gray-600">
                    <span className="mr-2">•</span>
                    CSV file with UTM coordinates and sensor data
                  </li>
                  <li className="flex items-start text-sm text-gray-600">
                    <span className="mr-2">•</span>
                    Required columns: UTM, sun_hours, direct_energy_intake,
                    diffuse_energy_intake, global_energy_intake
                  </li>
                  <li className="flex items-start text-sm text-gray-600">
                    <span className="mr-2">•</span>
                    UTM format should be in X_Y_Z format
                  </li>
                  <li className="flex items-start text-sm text-gray-600">
                    <span className="mr-2">•</span>
                    All energy values should be in kWh/m²
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default VirtualWholeSensorSetup;
