import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { ToastContainer, toast } from "react-toastify";
import {
  Search,
  ChevronRight,
  X,
  Loader,
  AlertCircle,
  Users,
} from "lucide-react";

const ProjectUserList = () => {
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const toggleExpandUser = (userId) => {
    setExpandedUserId(expandedUserId === userId ? null : userId);
  };

  const fetchUsers = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${apiRoot}/api/project_assignment/user_projects/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch: " + response.statusText);
      }

      const data = await response.json();
      setUsers(data.filter((user) => user.projects.length > 0));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const usersToShow =
    searchTerm.length >= 3
      ? users.filter((user) =>
          user.projects.some((projectName) =>
            projectName.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : users;

  const revokeAccess = async (userName, projectName) => {
    try {
      const response = await fetch(
        `${apiRoot}/api/project_assignment/revoke_project_access/?user=${userName}&project=${projectName}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 204) {
        toast.success(`Access revoked for ${userName} on ${projectName}`);
        fetchUsers();
        return;
      }

      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      } else {
        toast.success(`Access revoked for ${userName} on ${projectName}`);
        fetchUsers();
      }
    } catch (err) {
      toast.error(`Failed to revoke access: ${err.message}`);
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Header */}
            <div className="px-6 py-8 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    Project User Management
                  </h1>
                  <p className="mt-1 text-gray-600">
                    Manage user access to projects
                  </p>
                </div>
                <div className="p-3 bg-blue-50 rounded-lg">
                  <Users className="w-6 h-6 text-blue-600" />
                </div>
              </div>
            </div>

            {/* Search Section */}
            <div className="p-6 border-b border-gray-200 bg-gray-50">
              <div className="relative max-w-md mx-auto">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Type at least 3 characters to search projects..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg
                    focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                />
              </div>
            </div>

            {/* Users List */}
            <div className="p-6">
              {loading ? (
                <div className="flex items-center justify-center py-12">
                  <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                </div>
              ) : error ? (
                <div className="flex items-center gap-2 p-4 bg-red-50 rounded-lg text-red-700">
                  <AlertCircle className="w-5 h-5 flex-shrink-0" />
                  <p>{error}</p>
                </div>
              ) : usersToShow.length === 0 ? (
                <div className="text-center py-12 text-gray-500">
                  No users found matching your search criteria.
                </div>
              ) : (
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                  {usersToShow.map((user) => (
                    <div
                      key={user.id}
                      className="bg-white rounded-lg border border-gray-200 overflow-hidden 
                        hover:shadow-md transition-shadow"
                    >
                      {/* User Header */}
                      <div
                        onClick={() => toggleExpandUser(user.id)}
                        className="flex items-center justify-between p-4 cursor-pointer 
                          hover:bg-gray-50 border-b border-gray-200"
                      >
                        <span className="font-medium text-gray-900">
                          {user.name}
                        </span>
                        <ChevronRight
                          className={`w-5 h-5 text-gray-400 transition-transform duration-200
                            ${expandedUserId === user.id ? "rotate-90" : ""}`}
                        />
                      </div>

                      {/* Projects List */}
                      <div
                        className={`transition-all duration-200 overflow-hidden
                          ${
                            expandedUserId === user.id ? "max-h-96" : "max-h-0"
                          }`}
                      >
                        <div className="p-4 space-y-2">
                          {user.projects.map((project, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between bg-gray-50 
                                px-3 py-2 rounded-lg text-sm"
                            >
                              <span className="text-gray-700">{project}</span>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  revokeAccess(user.id, project);
                                }}
                                className="p-1 hover:bg-red-100 rounded-full 
                                  text-red-600 transition-colors"
                                title="Revoke access"
                              >
                                <X className="w-4 h-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </MainLayout>
  );
};

export default ProjectUserList;
