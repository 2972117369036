import * as XLSX from "xlsx";

/**

 * @param {File} file 
 * @returns {Promise<Object>}
 */
export const processExcelFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const firstRow = jsonData[0] || {};
        const columnNames = Object.keys(firstRow);

        console.log("Excel structure:", columnNames);
        const hasFieldValueStructure = columnNames.some(
          (col) =>
            col.toLowerCase() === "field" ||
            col.toLowerCase() === "field name" ||
            col.toLowerCase() === "leaftech information" ||
            col.toLowerCase() === "parameter"
        );

        let transformedData = {};

        if (hasFieldValueStructure) {
          const fieldColumn = columnNames.find(
            (col) =>
              col.toLowerCase() === "field" ||
              col.toLowerCase() === "field name" ||
              col.toLowerCase() === "leaftech information" ||
              col.toLowerCase() === "parameter"
          );

          const valueColumn =
            columnNames.find(
              (col) =>
                col.toLowerCase() === "value" ||
                col.toLowerCase() === "data" ||
                col.toLowerCase() === "result"
            ) || columnNames.find((col) => col !== fieldColumn); // Fallback to any other column

          console.log(
            `Detected row-based format with fields in '${fieldColumn}' and values in '${valueColumn}'`
          );

          jsonData.forEach((row) => {
            const field = row[fieldColumn];
            const value = row[valueColumn];

            if (field) {
              const cleanField = String(field).trim();
              transformedData[cleanField] = value;
            }
          });
        } else {
          transformedData = firstRow;
        }

        console.log("Transformed data:", transformedData);

        const previewData = [
          ["Field", "Value"],
          ...Object.entries(transformedData)
            .slice(0, 20)
            .map(([key, value]) => [key, value]),
        ];

        resolve({
          jsonData: [transformedData],
          previewData: previewData,
          headers: ["Field", "Value"],
          isRowBased: hasFieldValueStructure,
        });
      } catch (error) {
        console.error("Error processing Excel file:", error);
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });
};

/**
 * @param {Object} rowData
 * @param {string} userID
 * @returns {Object}
 */
export const mapExcelRowToProjectInput = (rowData, userID) => {
  if (!rowData) {
    rowData = {};
  }

  console.log("Project mapping from data:", rowData);

  const formatDate = (dateValue) => {
    if (!dateValue) return null;
    if (typeof dateValue === "string" && dateValue.includes("/")) {
      const parts = dateValue.split("/");
      return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
        2,
        "0"
      )}`;
    }
    return dateValue;
  };

  // Convert boolean to string representation
  const parseBooleanToString = (value) => {
    if (value === undefined || value === null) return "";
    if (typeof value === "boolean") {
      return value ? "yes" : "no";
    }
    if (typeof value === "string") {
      const lowercaseValue = value.toLowerCase();
      return lowercaseValue === "yes" ||
        lowercaseValue === "true" ||
        lowercaseValue === "y" ||
        lowercaseValue === "1"
        ? "yes"
        : "no";
    }
    return String(value);
  };

  // Helper to get value with fallbacks
  const getValue = (keys) => {
    for (const key of keys) {
      if (rowData[key] !== undefined) return rowData[key];
    }
    return null;
  };

  const getNumericValue = (keys) => {
    const value = getValue(keys);
    if (value === null || value === undefined) return 0;

    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? 0 : numberValue;
  };

  const getStringValue = (keys) => {
    const value = getValue(keys);
    if (value === null || value === undefined) return "";
    return String(value);
  };

  return {
    customerID: parseInt(userID, 10),
    name: getStringValue(["Building Name", "Name", "name"]),
    type: getStringValue(["Building Use Type", "Type", "type"]),
    portfolio: getStringValue(["Portfolio ID", "Portfolio", "portfolio"]),
    assetID: getStringValue(["Building ID", "AssetID", "assetID"]),

    latitude: getNumericValue([
      "LATITUDE",
      "Latitude",
      "latitude",
      "Lat",
      "lat",
    ]),
    longitude: getNumericValue([
      "LONGITUDE",
      "Longitude",
      "longitude",
      "Long",
      "long",
    ]),
    country: getStringValue(["Country", "country"]),
    city: getStringValue(["City", "city"]),
    mainDistrict: getStringValue([
      "Main District",
      "MainDistrict",
      "mainDistrict",
    ]),
    subDistrict: getStringValue(["Sub-District", "SubDistrict", "subDistrict"]),
    street: getStringValue(["Street", "street"]),
    address: getStringValue(["Full address", "Address", "address"]),

    premiseArea: getNumericValue([
      "Premise area",
      "PremiseArea",
      "premiseArea",
    ]),
    footprintArea: getNumericValue([
      "Building Footprint",
      "FootprintArea",
      "footprintArea",
    ]),
    floorAmount: parseInt(
      getValue(["Floor amount", "FloorAmount", "floorAmount"]) || 0
    ),
    grossAssetBasementIncluded: getNumericValue([
      "Gross area (incl. Basement)",
      "GrossAssetBasementIncluded",
    ]),
    netAreaBasementIncluded: getNumericValue([
      "Net Area (incl. Basement)",
      "NetAreaBasementIncluded",
    ]),
    grossLettableAreaWithoutBasement: getNumericValue([
      "Gross Lettable Area (without Basement)",
      "GrossLettableAreaWithoutBasement",
    ]),
    netLettableAreaWithoutBasement: getNumericValue([
      "Net Lettable Area (without Basement)",
      "NetLettableAreaWithoutBasement",
    ]),
    rentalUnits: parseInt(
      getValue(["Rental Units", "RentalUnits", "rentalUnits"]) || 0
    ),
    heritageProtected: parseBooleanToString(
      getValue(["Heritage Protected", "HeritageProtected", "heritageProtected"])
    ),
    constructionYear: parseInt(
      getValue([
        "Year of construction",
        "ConstructionYear",
        "constructionYear",
      ]) || 0
    ),

    districtHeatingAvailable: parseBooleanToString(
      getValue(["District Heating available", "DistrictHeatingAvailable"])
    ),
    naturalGasAvailable: parseBooleanToString(
      getValue(["Natural Gas Available", "NaturalGasAvailable"])
    ),
    heatEnergyConsumptionKWhPerAnnum: getNumericValue([
      "Heat Energy Consumption (Year)",
      "HeatEnergyConsumptionKWhPerAnnum",
    ]),
    electricityDemandKWhPerAnnum: getNumericValue([
      "Electricity consumption (Year)",
      "ElectricityDemandKWhPerAnnum",
    ]),
    carbonDioxideEmissionValue: getNumericValue([
      "EPC CO2 Emissions",
      "CarbonDioxideEmissionValue",
    ]),
    epcConsumptionKWhPerSquareMeterAnnum: getNumericValue([
      "EPC End Energy kWh/m²",
      "EPCConsumptionKWhPerSquareMeterAnnum",
    ]),

    boughtOn: formatDate(getValue(["Bought on", "BoughtOn", "boughtOn"])),
    soldOn: formatDate(getValue(["Sold on", "SoldOn", "soldOn"])),
  };
};

/**
 * @param {Object} rowData
 * @param {number} projectId
 * @returns {Object}
 */
export const mapExcelRowToESGInput = (rowData, projectId) => {
  if (!rowData) {
    rowData = {};
  }

  console.log("ESG mapping from data:", rowData);

  const getValue = (keys) => {
    for (const key of keys) {
      if (rowData[key] !== undefined) return rowData[key];
    }
    return null;
  };

  let spatialInfo = {};

  const buildingTypes = [
    "Office",
    "Logistics",
    "Residential",
    "Hotel",
    "Healthcare",
    "Retail",
  ];

  let foundPercentages = false;

  buildingTypes.forEach((type) => {
    const possibleKeys = [
      `${type}%`,
      `${type} %`,
      `${type} Percentage`,
      `${type} Percent`,
      `${type}`,
    ];

    for (const key of Object.keys(rowData)) {
      const lowerKey = key.toLowerCase();
      if (possibleKeys.some((pk) => lowerKey.includes(pk.toLowerCase()))) {
        let value = rowData[key];

        if (typeof value === "string" && value.includes("%")) {
          value = parseFloat(value.replace("%", ""));
        }

        if (!isNaN(parseFloat(value)) && parseFloat(value) > 0) {
          spatialInfo[type] = parseFloat(value);
          foundPercentages = true;
        }
      }
    }
  });

  if (!foundPercentages) {
    try {
      const spatialData = getValue([
        "Spatial Information",
        "SpatialInformation",
        "spatial_information",
        "Spatial",
        "spatial",
      ]);

      if (
        typeof spatialData === "string" &&
        (spatialData.includes("{") || spatialData.includes("["))
      ) {
        try {
          spatialInfo = JSON.parse(spatialData);
        } catch (e) {
          console.error("Error parsing JSON string:", e);
          spatialInfo = { Office: 100 };
        }
      } else if (spatialData) {
        spatialInfo = { Office: 100 };
      } else {
        spatialInfo = { Office: 100 };
      }
    } catch (e) {
      console.error("Error parsing spatial information:", e);
      spatialInfo = { Office: 100 };
    }
  }

  console.log("Extracted spatial info:", spatialInfo);

  const formatEnergyValue = (value) => {
    if (!value) return JSON.stringify({ building_value: 0 });

    if (
      typeof value === "string" &&
      (value.startsWith("{") || value.startsWith("["))
    ) {
      try {
        JSON.parse(value);
        return value;
      } catch (e) {
        console.error("Invalid JSON string:", e);
      }
    }

    return JSON.stringify({ building_value: parseFloat(value) || 0 });
  };

  const extractNumericValue = (value) => {
    if (!value) return null;
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      const match = value.match(/(\d+(?:\.\d+)?)/);
      if (match && match[1]) {
        return parseFloat(match[1]);
      }
    }
    return parseFloat(value) || 0;
  };

  const endEnergyValue = extractNumericValue(
    getValue([
      "End Energy Demand",
      "EndEnergyDemand",
      "end_energy_demand",
      "End Energy",
      "end_energy",
    ])
  );

  const primaryEnergyValue = extractNumericValue(
    getValue([
      "Primary Energy Demand",
      "PrimaryEnergyDemand",
      "primary_energy_demand",
      "Primary Energy",
      "primary_energy",
    ])
  );

  const co2Value = extractNumericValue(
    getValue([
      "CO2 Emission",
      "CO2 emissions",
      "CarbondioxidEmission",
      "carbondioxid_emission",
      "CO2",
      "co2",
    ])
  );

  const endEnergyDemand = formatEnergyValue(endEnergyValue);
  const primaryEnergyDemand = formatEnergyValue(primaryEnergyValue);
  const co2Emission = formatEnergyValue(co2Value);

  return {
    project_id: projectId,
    spatial_information:
      typeof spatialInfo === "string"
        ? spatialInfo
        : JSON.stringify(spatialInfo),
    end_energy_demand: endEnergyDemand,
    primary_energy_demand: primaryEnergyDemand,
    carbondioxid_emission: co2Emission,
  };
};

/**
 * @param {Object} rowData
 * @param {number} projectId
 * @returns {Object}
 */
/**
 *
 * @param {Object} rowData
 * @param {number} projectId
 * @returns {Object}
 */
export const mapExcelRowToEPCInput = (rowData, projectId) => {
  if (!rowData) {
    rowData = {};
  }

  console.log("EPC mapping from data:", rowData);

  const getValue = (keys) => {
    for (const key of keys) {
      if (rowData[key] !== undefined) return rowData[key];
    }
    return null;
  };

  const getStringValue = (keys) => {
    const value = getValue(keys);
    if (value === null || value === undefined) return "";
    return String(value);
  };

  const getNumericValue = (keys) => {
    const value = getValue(keys);
    if (value === null || value === undefined) return 0;

    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? 0 : numberValue;
  };

  const parseDateToString = (dateValue) => {
    console.log("Raw date value:", dateValue, typeof dateValue);

    if (!dateValue) {
      return "";
    }

    if (typeof dateValue === "number" || !isNaN(Number(dateValue))) {
      const excelSerialDate = Number(dateValue);

      if (excelSerialDate > 59) {
        try {
          const date = new Date(Date.UTC(1900, 0, excelSerialDate - 1));
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, "0");
          const day = String(date.getUTCDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          console.log("Converted Excel serial date to:", formattedDate);
          return formattedDate;
        } catch (e) {
          console.error("Error converting Excel serial date:", e);
        }
      }
    }

    if (typeof dateValue === "string") {
      if (dateValue.includes("-")) {
        return dateValue;
      }

      if (dateValue.includes("/")) {
        const parts = dateValue.split("/");
        if (parts.length === 3) {
          return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
            2,
            "0"
          )}`;
        }
      }

      return dateValue;
    }

    return "";
  };
  const classification = {
    class_a_plus_min: getStringValue([
      "EPC_Class_A+_Min",
      "Class A+ Min",
      "class_a_plus_min",
    ]),
    class_a_plus_max: getStringValue([
      "EPC_Class_A+_Max",
      "Class A+ Max",
      "class_a_plus_max",
    ]),
    class_a_min: getStringValue([
      "EPC_Class_A_Min",
      "Class A Min",
      "class_a_min",
    ]),
    class_a_max: getStringValue([
      "EPC_Class_A_Max",
      "Class A Max",
      "class_a_max",
    ]),
    class_b_min: getStringValue([
      "EPC_Class_B_Min",
      "Class B Min",
      "class_b_min",
    ]),
    class_b_max: getStringValue([
      "EPC_Class_B_Max",
      "Class B Max",
      "class_b_max",
    ]),
    class_c_min: getStringValue([
      "EPC_Class_C_Min",
      "Class C Min",
      "class_c_min",
    ]),
    class_c_max: getStringValue([
      "EPC_Class_C_Max",
      "Class C Max",
      "class_c_max",
    ]),
    class_d_min: getStringValue([
      "EPC_Class_D_Min",
      "Class D Min",
      "class_d_min",
    ]),
    class_d_max: getStringValue([
      "EPC_Class_D_Max",
      "Class D Max",
      "class_d_max",
    ]),
    class_e_min: getStringValue([
      "EPC_Class_E_Min",
      "Class E Min",
      "class_e_min",
    ]),
    class_e_max: getStringValue([
      "EPC_Class_E_Max",
      "Class E Max",
      "class_e_max",
    ]),
    class_f_min: getStringValue([
      "EPC_Class_F_Min",
      "Class F Min",
      "class_f_min",
    ]),
    class_f_max: getStringValue([
      "EPC_Class_F_Max",
      "Class F Max",
      "class_f_max",
    ]),
    class_g_min: getStringValue([
      "EPC_Class_G_Min",
      "Class G Min",
      "class_g_min",
    ]),
    class_g_max: getStringValue([
      "EPC_Class_G_Max",
      "Class G Max",
      "class_g_max",
    ]),
    class_h_min: getStringValue([
      "EPC_Class_H_Min",
      "Class H Min",
      "class_h_min",
    ]),
    class_h_max: getStringValue([
      "EPC_Class_H_Max",
      "Class H Max",
      "class_h_max",
    ]),
  };

  // Prepare delta_to_classes dictionary
  const deltaToClasses = {
    to_class_a_plus: getStringValue([
      "Delta_to_Class_A+",
      "Delta to Class A+",
      "to_class_a_plus",
    ]),
    to_class_a: getStringValue([
      "Delta_to_Class_A",
      "Delta to Class A",
      "to_class_a",
    ]),
    to_class_b: getStringValue([
      "Delta_to_Class_B",
      "Delta to Class B",
      "to_class_b",
    ]),
    to_class_c: getStringValue([
      "Delta_to_Class_C",
      "Delta to Class C",
      "to_class_c",
    ]),
    to_class_d: getStringValue([
      "Delta_to_Class_D",
      "Delta to Class D",
      "to_class_d",
    ]),
    to_class_e: getStringValue([
      "Delta_to_Class_E",
      "Delta to Class E",
      "to_class_e",
    ]),
    to_class_f: getStringValue([
      "Delta_to_Class_F",
      "Delta to Class F",
      "to_class_f",
    ]),
    to_class_g: getStringValue([
      "Delta_to_Class_G",
      "Delta to Class G",
      "to_class_g",
    ]),
  };

  const issuedOn = parseDateToString(
    getValue(["EPC Issued on", "EPC Issued date", "issued_on"])
  );
  const validUntil = parseDateToString(
    getValue(["EPC Valid until", "valid_until"])
  );

  console.log("Date fields:", { issuedOn, validUntil });

  return {
    projectID: projectId,
    epc_number:
      getStringValue(["EPC Number", "EPCNumber", "epc_number"]) ||
      "EPC-" + Date.now(),
    issued_on: issuedOn,
    valid_until: validUntil,
    expires_in: getStringValue(["EPC expires in", "expires_in"]),
    issued_by: getStringValue(["EPC Issued by", "issued_by"]),
    type: getStringValue(["EPC Type", "type"]),
    according_to: getStringValue(["EPC according to", "according_to"]),
    class_defined_via: getStringValue([
      "EPC Class defined via",
      "class_defined_via",
    ]),
    primary_energy_kwh_per_m2: getNumericValue([
      "EPC Primary Energy ",
      "EPC Primary Energy kWh/m²",
      "primary_energy_kwh_per_m2",
    ]),
    end_energy_kwh_per_m2: getNumericValue([
      "EPC End Energy kWh/m²",
      "end_energy_kwh_per_m2",
    ]),
    primary_energy_reference_kwh_per_m2: getNumericValue([
      "EPC Primary Energy Reference value kWh/m²",
      "primary_energy_reference_kwh_per_m2",
    ]),
    end_energy_reference_kwh_per_m2: getNumericValue([
      "EPC End Energy Reference value kWh/m²",
      "end_energy_reference_kwh_per_m2",
    ]),
    class: getStringValue(["EPC Class", "class"]),
    target_2030: getStringValue(["EPC Target 2030", "target_2030"]),
    classification: classification,
    delta_to_classes: deltaToClasses,
    epc_carbon_dioxide_emission: getNumericValue([
      "EPC CO2 Emissions",
      "epc_carbon_dioxide_emission",
    ]),
  };
};
