import React, { useState } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadCloud, Loader, X } from "lucide-react";
import JSZip from "jszip";

const VirtualWindComponent = () => {
  const [fileData, setFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");
  const [toast, setToast] = useState({ show: false, type: "", message: "" });
  const [uploadProgress, setUploadProgress] = useState({
    stage: "",
    progress: 0,
  });

  const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");

  const showToast = (type, message) => {
    setToast({ show: true, type, message });
  };

  const hideToast = () => {
    setToast({ show: false, type: "", message: "" });
  };

  // Helper function to extract wind directions from zip file
  const extractWindDirections = async (file) => {
    try {
      const zip = await JSZip.loadAsync(file);
      const directions = [];

      for (const filename of Object.keys(zip.files)) {
        if (
          filename.endsWith(".json") &&
          !filename.startsWith("__MACOSX") &&
          !filename.startsWith("._")
        ) {
          const direction = filename.split("/").pop().replace(".json", "");
          directions.push(direction);
        }
      }

      return directions.sort((a, b) => Number(a) - Number(b));
    } catch (error) {
      console.error("Error extracting wind directions:", error);
      throw error;
    }
  };

  // Function to upload wind data
  // const uploadWindData = async (file, windDirections) => {
  //   try {
  //     setUploadProgress({ stage: "Uploading wind data...", progress: 66 });

  //     const formData = new FormData();
  //     formData.append("file", file);

  //     // Create the request body with project_id
  //     const requestBody = {
  //       project_id: parseInt(projectID),
  //     };

  //     // Make the request
  //     const response = await fetch(`${apiRootOperational}/api/upload/zip`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //       body: JSON.stringify({
  //         ...formData,
  //         ...requestBody,
  //       }),
  //     });

  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.error || "Failed to upload wind data");
  //     }

  //     const result = await response.json();

  //     // Handle warnings if any
  //     if (result.warnings && result.warnings.length > 0) {
  //       showToast("warning", "Upload completed with some warnings");
  //       console.warn("Upload warnings:", result.warnings);
  //     }

  //     setUploadProgress({
  //       stage: "Wind data uploaded successfully",
  //       progress: 100,
  //     });

  //     return result;
  //   } catch (error) {
  //     console.error("Error uploading wind data:", error);
  //     throw error;
  //   }
  // };
  const uploadWindData = async (file, windDirections) => {
    try {
      setUploadProgress({ stage: "Uploading wind data...", progress: 66 });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("project_id", projectID); // Send as form field

      const response = await fetch(`${apiRootOperational}/api/upload/zip`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // Let browser set the correct content type with boundary
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to upload wind data");
      }

      const result = await response.json();

      // Handle warnings if any
      if (result.warnings && result.warnings.length > 0) {
        showToast("warning", "Upload completed with some warnings");
        console.warn("Upload warnings:", result.warnings);
      }

      setUploadProgress({
        stage: "Wind data uploaded successfully",
        progress: 100,
      });

      return result;
    } catch (error) {
      console.error("Error uploading wind data:", error);
      throw error;
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.name.endsWith(".zip")) {
        showToast("error", "Please select a ZIP file");
        return;
      }
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      if (!file.name.endsWith(".zip")) {
        showToast("error", "Please select a ZIP file");
        return;
      }
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fileData) {
      showToast("error", "Please select a file to upload");
      return;
    }

    if (!projectID) {
      showToast(
        "error",
        "No project ID found. Please ensure you're in a project context"
      );
      return;
    }

    setIsSubmitting(true);
    showToast("loading", "Processing upload...");
    setUploadProgress({ stage: "Starting upload process...", progress: 0 });

    try {
      // Extract wind directions from zip
      setUploadProgress({
        stage: "Extracting wind directions...",
        progress: 33,
      });
      const windDirections = await extractWindDirections(fileData);

      if (windDirections.length === 0) {
        throw new Error("No valid JSON files found in the ZIP archive");
      }

      // Upload wind data with project ID
      await uploadWindData(fileData, windDirections);

      showToast("success", "Upload completed successfully!");
      setFileData(null);
      setFileName("No file chosen");
    } catch (error) {
      console.error("Error in upload process:", error);
      showToast("error", `Upload failed: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setUploadProgress({ stage: "", progress: 0 });
      }, 3000);
    }
  };

  return (
    <MainLayout>
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        {/* Header */}
        <div className="px-6 py-8 text-center border-b border-gray-200">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            Virtual Wind Configuration
          </h1>
          <p className="text-gray-500">
            Upload wind direction data for the current project
          </p>
        </div>

        {/* Upload Form */}
        <div className="p-6">
          <form onSubmit={handleSubmit}>
            {/* File Upload Area */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Virtual Wind Data File (ZIP)
              </label>
              <div
                className={`relative border-2 border-dashed rounded-lg p-6 
                  ${
                    isDragging
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-300 hover:border-gray-400"
                  }
                  transition-all duration-200 cursor-pointer`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept=".zip"
                  onChange={handleFileSelect}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div className="text-center">
                  <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
                  <p className="mt-2 text-sm text-gray-600">
                    Drag and drop your ZIP file here or click to browse
                  </p>
                  <p className="mt-1 text-xs text-gray-500">{fileName}</p>
                </div>
              </div>
            </div>

            {/* Progress Indicator */}
            {uploadProgress.stage && (
              <div className="mb-6">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                    style={{ width: `${uploadProgress.progress}%` }}
                  ></div>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  {uploadProgress.stage}
                </p>
              </div>
            )}

            {/* Toast Notification */}
            {toast.show && (
              <div
                className={`mb-6 p-4 rounded-lg ${
                  toast.type === "success"
                    ? "bg-green-50 border border-green-200"
                    : toast.type === "error"
                    ? "bg-red-50 border border-red-200"
                    : "bg-blue-50 border border-blue-200"
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    {toast.type === "loading" && (
                      <Loader className="h-5 w-5 animate-spin text-blue-500" />
                    )}
                    <p
                      className={`text-sm font-medium ${
                        toast.type === "success"
                          ? "text-green-800"
                          : toast.type === "error"
                          ? "text-red-800"
                          : "text-blue-800"
                      }`}
                    >
                      {toast.message}
                    </p>
                  </div>
                  {toast.type !== "loading" && (
                    <button
                      onClick={hideToast}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting || !fileData}
              className={`w-full px-4 py-3 text-sm font-medium rounded-lg
                ${
                  isSubmitting || !fileData
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }
                text-white transition-colors duration-200`}
            >
              {isSubmitting ? "Uploading..." : "Upload Wind Data"}
            </button>
          </form>

          {/* Requirements Info */}
          <div className="mt-8 bg-gray-50 rounded-lg p-6">
            <h2 className="text-sm font-medium text-gray-900 mb-4">
              Upload Requirements
            </h2>
            <ul className="space-y-2">
              {[
                "Upload a ZIP file containing your virtual wind data",
                "Each JSON file in the ZIP should be named with its wind direction (e.g., 315.json)",
                "The system will automatically process and validate the wind data",
                "Make sure all JSON files follow the required data format",
              ].map((requirement, index) => (
                <li
                  key={index}
                  className="flex items-start text-sm text-gray-600"
                >
                  <span className="mr-2">•</span>
                  {requirement}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default VirtualWindComponent;
