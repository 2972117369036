import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadCloud, Loader, AlertCircle, Trash2 } from "lucide-react";
import fetchUtil from "./utils/fetchUtil";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FileManager = () => {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const projectID = localStorage.getItem("projectID");
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const data = await fetchUtil(
        `${apiRoot}/api/layers/get_all_floors_plan_of_floors_layer/?project_id=${projectID}`,
        "GET",
        token
      );
      setFiles(data);
      setError(null);
    } catch (error) {
      setError("Failed to load files. Please try again.");
      toast.error("Failed to load files");
    } finally {
      setLoading(false);
    }
  };

  const handleFilesUpload = async (files) => {
    for (const file of files) {
      try {
        // Step 1: Create floor layer record
        setUploadProgress((prev) => ({
          ...prev,
          [file.name]: "Creating floor layer record...",
        }));

        const floorLayerData = {
          project: projectID,
          name: file.name,
          type: getFileType(file.name),
        };

        const floorLayerResponse = await fetchUtil(
          `${apiRoot}/api/floor_interaction/`,
          "POST",
          token,
          floorLayerData
        );

        if (!floorLayerResponse || !floorLayerResponse.id) {
          throw new Error("Failed to create floor layer record");
        }

        // Step 2: Upload the file
        setUploadProgress((prev) => ({
          ...prev,
          [file.name]: "Uploading file...",
        }));

        const formData = new FormData();
        formData.append("layer_file", file);
        formData.append("floors_layer", floorLayerResponse.id);

        await fetchUtil(
          `${apiRoot}/api/floor_file_interaction/`,
          "POST",
          token,
          formData,
          true
        );

        setUploadProgress((prev) => ({
          ...prev,
          [file.name]: "Complete",
        }));

        toast.success(`Successfully uploaded ${file.name}`);
        await fetchFiles();
        setError(null);
      } catch (error) {
        console.error("Upload error:", error);
        setError(`Failed to upload ${file.name}: ${error.message}`);
        setUploadProgress((prev) => ({
          ...prev,
          [file.name]: "Failed",
        }));
        toast.error(`Failed to upload ${file.name}`);
      }
    }

    // Clear upload states after delay
    setTimeout(() => {
      setUploadFiles([]);
      setUploadProgress({});
    }, 2000);
  };

  const getFileType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "ifc":
        return "ifc";
      case "rvt":
        return "rvt";
      case "pdf":
        return "pdf";
      default:
        return "other";

      case "xlsx":
        return "xlsx";
      case "zip":
        return "zip";
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    setUploadFiles(droppedFiles);
    handleFilesUpload(droppedFiles);
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setUploadFiles(selectedFiles);
    handleFilesUpload(selectedFiles);
  };

  const handleSelectFile = (fileId) => {
    setSelectedFiles((prev) =>
      prev.includes(fileId)
        ? prev.filter((id) => id !== fileId)
        : [...prev, fileId]
    );
  };

  // const handleDeleteFiles = async () => {
  //   if (
  //     !selectedFiles.length ||
  //     !window.confirm("Are you sure you want to delete the selected files?")
  //   ) {
  //     return;
  //   }

  //   try {
  //     await fetchUtil(`${apiRoot}/api/layers/delete`, "DELETE", token, {
  //       fileIds: selectedFiles,
  //     });

  //     setFiles((prev) =>
  //       prev.filter((file) => !selectedFiles.includes(file.id))
  //     );
  //     setSelectedFiles([]);
  //     toast.success("Files deleted successfully");
  //   } catch (error) {
  //     setError("Failed to delete files. Please try again.");
  //     toast.error("Failed to delete files");
  //   }
  // };
  const handleDeleteFiles = async () => {
    if (
      !selectedFiles.length ||
      !window.confirm("Are you sure you want to delete the selected files?")
    ) {
      return;
    }
    const failedDeletes = [];

    try {
      for (const fileId of selectedFiles) {
        console.log(fileId);
        try {
          await fetchUtil(
            `${apiRoot}/api/floor_interaction/${fileId}/`,
            "DELETE",
            token
          );
        } catch (error) {
          console.error(`Failed to delete file ${fileId}:`, error);
          failedDeletes.push(fileId);
        }
      }
      setFiles((prev) =>
        prev.filter((file) => !selectedFiles.includes(file.id))
      );
      setSelectedFiles([]);
      toast.success("Files deleted successfully");
    } catch (error) {
      setError("Failed to delete files. Please try again.");
      toast.error("Failed to delete files");
    }
  };
  const UploadProgressDisplay = ({ files, progress }) => {
    if (Object.keys(progress).length === 0) return null;

    return (
      <div className="mt-4 space-y-2">
        {Object.entries(progress).map(([fileName, status]) => (
          <div
            key={fileName}
            className="flex items-center justify-between bg-gray-50 p-2 rounded"
          >
            <span className="text-sm text-gray-700">{fileName}</span>
            <span
              className={`text-sm ${
                status === "Complete"
                  ? "text-green-600"
                  : status === "Failed"
                  ? "text-red-600"
                  : "text-blue-600"
              }`}
            >
              {status}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Header */}
            <div className="px-6 py-8 border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 text-center">
                File Management
              </h1>
              <p className="mt-2 text-center text-gray-600">
                Manage and organize your project files
              </p>
            </div>

            <div className="p-6 space-y-6">
              {/* Error Banner */}
              {error && (
                <div className="flex items-center gap-2 p-4 bg-red-50 border border-red-200 rounded-lg">
                  <AlertCircle className="h-5 w-5 text-red-500" />
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}

              {/* Upload Section */}
              <div className="bg-gray-50 rounded-lg p-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">
                  Upload Files
                </h2>
                <div
                  className={`relative border-2 border-dashed rounded-lg p-6 transition-all duration-200
                    ${
                      isDragging
                        ? "border-blue-500 bg-blue-50"
                        : "border-gray-300 hover:border-gray-400"
                    }
                    ${
                      Object.keys(uploadProgress).length > 0
                        ? "opacity-50 pointer-events-none"
                        : ""
                    }
                    bg-white`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    onChange={handleFileSelect}
                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    multiple
                    accept=".ifc,.rvt,.pdf,.xlsx,.zip"
                    disabled={Object.keys(uploadProgress).length > 0}
                  />
                  <div className="text-center">
                    <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
                    <p className="mt-2 text-sm text-gray-600">
                      Drag and drop files here or click to browse
                    </p>
                    <p className="mt-1 text-xs text-gray-500">
                      Supported files: IFC, RVT, PDF
                    </p>
                  </div>
                </div>

                {/* Upload Progress Display */}
                <UploadProgressDisplay
                  files={uploadFiles}
                  progress={uploadProgress}
                />
              </div>

              {/* Files Section */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    Project Files
                  </h2>
                  {selectedFiles.length > 0 && (
                    <button
                      onClick={handleDeleteFiles}
                      className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                      Delete Selected ({selectedFiles.length})
                    </button>
                  )}
                </div>

                {loading ? (
                  <div className="flex flex-col items-center justify-center py-12">
                    <Loader className="w-8 h-8 text-blue-500 animate-spin mb-4" />
                    <p className="text-gray-600">Loading files...</p>
                  </div>
                ) : files.length === 0 ? (
                  <div className="text-center py-12 bg-gray-50 rounded-lg">
                    <p className="text-gray-600">
                      No files available. Drop files above to get started.
                    </p>
                  </div>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3 px-4 w-12">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              checked={selectedFiles.length === files.length}
                              onChange={() =>
                                setSelectedFiles(
                                  selectedFiles.length === files.length
                                    ? []
                                    : files.map((file) => file.id)
                                )
                              }
                            />
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Created
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Updated
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {files.map((file) => (
                          <tr key={file.id} className="hover:bg-gray-50">
                            <td className="py-4 px-4">
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                checked={selectedFiles.includes(file.id)}
                                onChange={() => handleSelectFile(file.id)}
                              />
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">
                              {file.name}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {file.type}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {new Date(file._created_at).toLocaleDateString()}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                              {new Date(file._updated_at).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default FileManager;
