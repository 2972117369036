// import React, { useState, useEffect } from "react";
// import { MainLayout } from "../components/layout/MainLayout";
// import { UploadCloud, Edit2, Trash2, Loader } from "lucide-react";

// const CrremComponent = () => {
//   const [scenarios, setScenarios] = useState([]);
//   const [newScenario, setNewScenario] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [editingScenario, setEditingScenario] = useState(null);
//   const [editedName, setEditedName] = useState("");
//   const [isLoading, setIsLoading] = useState(true);

//   const userId = localStorage.getItem("userID");
//   const token = localStorage.getItem("token");
//   const projectID = localStorage.getItem("projectID");
//   const apiRoot = process.env.REACT_APP_API_ROOT;

//   useEffect(() => {
//     const fetchScenarios = async () => {
//       try {
//         const response = await fetch(
//           `${apiRoot}/api/project_info/?project_id=${projectID}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         const data = await response.json();
//         let scenarioList = data[0]?.crrem_scenario_list;
//         if (typeof scenarioList === "string") {
//           scenarioList = JSON.parse(scenarioList);
//         }
//         setScenarios(Array.isArray(scenarioList) ? scenarioList : []);
//       } catch (error) {
//         console.error("Error fetching scenarios:", error);
//         setScenarios([]);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchScenarios();
//   }, [apiRoot, projectID, token]);

//   const handleDeleteScenario = async (scenario) => {
//     const confirmDelete = window.confirm(
//       `Are you sure you want to delete "${scenario}"?`
//     );
//     if (!confirmDelete) return;

//     try {
//       const response = await fetch(
//         `${apiRoot}/project/scenarios/delete/${projectID}?scenario=${scenario}`,
//         {
//           method: "DELETE",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.ok) throw new Error("Failed to delete scenario");

//       setScenarios((prevScenarios) =>
//         prevScenarios.filter((s) => s !== scenario)
//       );
//       alert(`Scenario "${scenario}" deleted successfully.`);
//     } catch (error) {
//       console.error("Error deleting scenario:", error);
//       alert("Error deleting scenario.");
//     }
//   };

//   const handleRenameScenario = async (originalName) => {
//     if (!editedName.trim()) {
//       alert("Scenario name cannot be empty.");
//       return;
//     }

//     try {
//       const response = await fetch(
//         `${apiRoot}/project/scenarios/update/${projectID}`,
//         {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({ originalName, newName: editedName }),
//         }
//       );

//       if (!response.ok) throw new Error("Failed to rename scenario");

//       setScenarios((prevScenarios) =>
//         prevScenarios.map((scenario) =>
//           scenario === originalName ? editedName : scenario
//         )
//       );
//       setEditingScenario(null);
//       alert(`Scenario renamed successfully to "${editedName}".`);
//     } catch (error) {
//       console.error("Error renaming scenario:", error);
//       alert("Error renaming scenario.");
//     }
//   };

//   const handleUploadScenario = async () => {
//     if (!newScenario || !selectedFile) {
//       alert("Please enter a scenario name and select a file.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", selectedFile);
//     formData.append("scenario", newScenario);

//     try {
//       const response = await fetch(
//         `${apiRoot}/project/scenarios/upload/${projectID}`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//           body: formData,
//         }
//       );

//       if (!response.ok) throw new Error("Failed to upload scenario");

//       setScenarios((prevScenarios) => [...prevScenarios, newScenario]);
//       setNewScenario("");
//       setSelectedFile(null);
//       alert(`Scenario "${newScenario}" uploaded successfully.`);
//     } catch (error) {
//       console.error("Error uploading scenario:", error);
//       alert("Error uploading scenario.");
//     }
//   };

//   return (
//     <MainLayout>
//       <div className="min-h-screen bg-gray-50 py-8">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="bg-white rounded-xl shadow-sm overflow-hidden">
//             {/* Header */}
//             <div className="px-6 py-8 border-b border-gray-200">
//               <h1 className="text-2xl font-bold text-gray-900 text-center">
//                 CRREM Configuration
//               </h1>
//               <p className="mt-2 text-center text-gray-600">
//                 Manage scenarios for Carbon Risk Real Estate Monitor analysis
//               </p>
//             </div>

//             <div className="p-6">
//               {isLoading ? (
//                 <div className="flex items-center justify-center py-12">
//                   <Loader className="w-6 h-6 text-blue-500 animate-spin" />
//                   <span className="ml-2 text-gray-600">
//                     Loading scenarios...
//                   </span>
//                 </div>
//               ) : (
//                 <>
//                   {/* Scenarios Section */}
//                   <div className="mb-8">
//                     <h2 className="text-lg font-semibold text-gray-900 mb-4">
//                       Available Scenarios
//                     </h2>

//                     {scenarios.length > 0 ? (
//                       <div className="space-y-4">
//                         {scenarios.map((scenario, index) => (
//                           <div
//                             key={index}
//                             className="bg-gray-50 rounded-lg p-4 flex items-center justify-between"
//                           >
//                             <div className="flex-1">
//                               {editingScenario === scenario ? (
//                                 <input
//                                   type="text"
//                                   className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//                                   value={editedName}
//                                   onChange={(e) =>
//                                     setEditedName(e.target.value)
//                                   }
//                                   onBlur={() => handleRenameScenario(scenario)}
//                                   autoFocus
//                                 />
//                               ) : (
//                                 <span className="text-gray-900">
//                                   {scenario}
//                                 </span>
//                               )}
//                             </div>
//                             <div className="flex gap-2 ml-4">
//                               <button
//                                 onClick={() => {
//                                   setEditingScenario(scenario);
//                                   setEditedName(scenario);
//                                 }}
//                                 className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
//                               >
//                                 <Edit2 className="w-4 h-4" />
//                               </button>
//                               <button
//                                 onClick={() => handleDeleteScenario(scenario)}
//                                 className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
//                               >
//                                 <Trash2 className="w-4 h-4" />
//                               </button>
//                             </div>
//                           </div>
//                         ))}
//                       </div>
//                     ) : (
//                       <div className="text-center py-12 bg-gray-50 rounded-lg">
//                         <p className="text-gray-600">
//                           No scenarios available. Upload your first scenario
//                           below.
//                         </p>
//                       </div>
//                     )}
//                   </div>

//                   {/* Upload Section */}
//                   <div className="bg-gray-50 rounded-lg p-6">
//                     <h2 className="text-lg font-semibold text-gray-900 mb-4">
//                       Upload New Scenario
//                     </h2>
//                     <div className="space-y-6">
//                       <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-2">
//                           Scenario Name
//                         </label>
//                         <input
//                           type="text"
//                           className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//                           placeholder="Enter scenario name"
//                           value={newScenario}
//                           onChange={(e) => setNewScenario(e.target.value)}
//                         />
//                       </div>

//                       <div>
//                         <label className="block text-sm font-medium text-gray-700 mb-2">
//                           Scenario File
//                         </label>
//                         <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-6 hover:border-gray-400 transition-colors">
//                           <input
//                             type="file"
//                             className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
//                             onChange={(e) => setSelectedFile(e.target.files[0])}
//                           />
//                           <div className="text-center">
//                             <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
//                             <p className="mt-2 text-sm text-gray-600">
//                               Choose a file or drag it here
//                             </p>
//                             <p className="mt-1 text-xs text-gray-500">
//                               {selectedFile
//                                 ? selectedFile.name
//                                 : "No file chosen"}
//                             </p>
//                           </div>
//                         </div>
//                       </div>

//                       <button
//                         onClick={handleUploadScenario}
//                         disabled={!newScenario || !selectedFile}
//                         className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors
//                           disabled:bg-gray-400 disabled:cursor-not-allowed"
//                       >
//                         Upload Scenario
//                       </button>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </MainLayout>
//   );
// };

// export default CrremComponent;
import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { Edit2, Trash2, Loader } from "lucide-react";
import { FileUpload } from "./FileUpload";

const CrremComponent = () => {
  const [scenarios, setScenarios] = useState([]);
  const [newScenario, setNewScenario] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingScenario, setEditingScenario] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const apiRoot = process.env.REACT_APP_API_ROOT;

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        const response = await fetch(
          `${apiRoot}/api/project_info/?project_id=${projectID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        let scenarioList = data[0]?.crrem_scenario_list;
        if (typeof scenarioList === "string") {
          scenarioList = JSON.parse(scenarioList);
        }
        setScenarios(Array.isArray(scenarioList) ? scenarioList : []);
      } catch (error) {
        console.error("Error fetching scenarios:", error);
        setScenarios([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScenarios();
  }, [apiRoot, projectID, token]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleDeleteScenario = async (scenario) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${scenario}"?`
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${apiRoot}/project/scenarios/delete/${projectID}?scenario=${scenario}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete scenario");

      setScenarios((prevScenarios) =>
        prevScenarios.filter((s) => s !== scenario)
      );
      alert(`Scenario "${scenario}" deleted successfully.`);
    } catch (error) {
      console.error("Error deleting scenario:", error);
      alert("Error deleting scenario.");
    }
  };

  const handleRenameScenario = async (originalName) => {
    if (!editedName.trim()) {
      alert("Scenario name cannot be empty.");
      return;
    }

    try {
      const response = await fetch(
        `${apiRoot}/project/scenarios/update/${projectID}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ originalName, newName: editedName }),
        }
      );

      if (!response.ok) throw new Error("Failed to rename scenario");

      setScenarios((prevScenarios) =>
        prevScenarios.map((scenario) =>
          scenario === originalName ? editedName : scenario
        )
      );
      setEditingScenario(null);
      alert(`Scenario renamed successfully to "${editedName}".`);
    } catch (error) {
      console.error("Error renaming scenario:", error);
      alert("Error renaming scenario.");
    }
  };

  const handleUploadScenario = async () => {
    if (!newScenario || !selectedFile) {
      alert("Please enter a scenario name and select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("scenario", newScenario);

    try {
      const response = await fetch(
        `${apiRoot}/project/scenarios/upload/${projectID}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to upload scenario");

      setScenarios((prevScenarios) => [...prevScenarios, newScenario]);
      setNewScenario("");
      setSelectedFile(null);
      alert(`Scenario "${newScenario}" uploaded successfully.`);
    } catch (error) {
      console.error("Error uploading scenario:", error);
      alert("Error uploading scenario.");
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Header */}
            <div className="px-6 py-8 border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 text-center">
                CRREM Configuration
              </h1>
              <p className="mt-2 text-center text-gray-600">
                Manage scenarios for Carbon Risk Real Estate Monitor analysis
              </p>
            </div>

            <div className="p-6">
              {isLoading ? (
                <div className="flex items-center justify-center py-12">
                  <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                  <span className="ml-2 text-gray-600">
                    Loading scenarios...
                  </span>
                </div>
              ) : (
                <>
                  {/* Scenarios Section */}
                  <div className="mb-8">
                    <h2 className="text-lg font-semibold text-gray-900 mb-4">
                      Available Scenarios
                    </h2>

                    {scenarios.length > 0 ? (
                      <div className="space-y-4">
                        {scenarios.map((scenario, index) => (
                          <div
                            key={index}
                            className="bg-gray-50 rounded-lg p-4 flex items-center justify-between"
                          >
                            <div className="flex-1">
                              {editingScenario === scenario ? (
                                <input
                                  type="text"
                                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                  value={editedName}
                                  onChange={(e) =>
                                    setEditedName(e.target.value)
                                  }
                                  onBlur={() => handleRenameScenario(scenario)}
                                  autoFocus
                                />
                              ) : (
                                <span className="text-gray-900">
                                  {scenario}
                                </span>
                              )}
                            </div>
                            <div className="flex gap-2 ml-4">
                              <button
                                onClick={() => {
                                  setEditingScenario(scenario);
                                  setEditedName(scenario);
                                }}
                                className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
                              >
                                <Edit2 className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() => handleDeleteScenario(scenario)}
                                className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-12 bg-gray-50 rounded-lg">
                        <p className="text-gray-600">
                          No scenarios available. Upload your first scenario
                          below.
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Upload Section */}
                  <div className="bg-gray-50 rounded-lg p-6">
                    <h2 className="text-lg font-semibold text-gray-900 mb-4">
                      Upload New Scenario
                    </h2>
                    <div className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Scenario Name
                        </label>
                        <input
                          type="text"
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          placeholder="Enter scenario name"
                          value={newScenario}
                          onChange={(e) => setNewScenario(e.target.value)}
                        />
                      </div>

                      <FileUpload
                        onFileSelect={handleFileSelect}
                        acceptedFileTypes={null}
                        acceptedExtensions=".xlsx,.xls"
                        label="Excel File"
                        description="Drag and drop your Excel file here or click to browse"
                      />

                      <button
                        onClick={handleUploadScenario}
                        disabled={!newScenario || !selectedFile}
                        className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors
                          disabled:bg-gray-400 disabled:cursor-not-allowed"
                      >
                        Upload Scenario
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CrremComponent;
