// import React, { useState, useEffect } from "react";
// import FileUploadSingleComponentEnergyData from "./FileUploadSingleComponentEnergyData";
// import { MainLayout } from "../components/layout/MainLayout";

// const EnergyDataInsertion = () => {
//   const [xlsxFileNames, setXlsxFileNames] = useState([]);
//   const [file, setFile] = useState(null);
//   const [excelColumnNames, setExcelColumnNames] = useState([]);
//   const [orderedFloors, setOrderedFloors] = useState([]);
//   const [selectedAvailableFloor, setSelectedAvailableFloor] = useState("");
//   const [selectedOrderedFloor, setSelectedOrderedFloor] = useState("");

//   const projectID = localStorage.getItem("projectID");
//   const userID = localStorage.getItem("userID");
//   const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//   const apiRootSecond = process.env.REACT_APP_API_ROOT;

//   const handleFileSelect = (id, selectedFile) => {
//     setFile(selectedFile);
//   };

//   const handleEnergyZipContentDetected = (columnNames, fileNames) => {
//     setExcelColumnNames((prevNames) => {
//       const allNames = new Set([...prevNames, ...columnNames]);
//       return Array.from(allNames);
//     });
//     setXlsxFileNames(fileNames);
//   };

//   const handleAddFloor = () => {
//     if (
//       selectedAvailableFloor &&
//       !orderedFloors.includes(selectedAvailableFloor)
//     ) {
//       setOrderedFloors((prev) => [...prev, selectedAvailableFloor]);
//       setSelectedAvailableFloor("");
//     }
//   };

//   const handleRemoveFloor = () => {
//     if (selectedOrderedFloor) {
//       setOrderedFloors((prev) =>
//         prev.filter((floor) => floor !== selectedOrderedFloor)
//       );
//       setSelectedOrderedFloor("");
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!file) return;

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       // Upload file
//       const queryParams = new URLSearchParams({
//         project_id: projectID,
//         user_id: userID,
//         file_type: "thermal",
//         values: JSON.stringify(orderedFloors),
//         scenario_order: JSON.stringify(xlsxFileNames),
//       });

//       const uploadUrl = `${apiRoot}/api/rooms/uploadrooms?${queryParams}`;
//       const uploadResponse = await fetch(uploadUrl, {
//         method: "POST",
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//         body: formData,
//       });

//       if (!uploadResponse.ok) throw new Error("Upload failed");

//       // Update project info
//       const projectInfoUrl = `${apiRootSecond}/api/project_info/?project_id=${projectID}`;
//       const getResponse = await fetch(projectInfoUrl, {
//         method: "GET",
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });

//       const projectFormData = new FormData();
//       projectFormData.append("project_id", projectID);
//       projectFormData.append(
//         "energy_value_list",
//         JSON.stringify(orderedFloors)
//       );
//       projectFormData.append(
//         "all_scenario_list",
//         JSON.stringify(
//           xlsxFileNames.map((name) => {
//             const parts = name.split("/");
//             return parts[parts.length - 1].replace(".xlsx", "");
//           })
//         )
//       );
//       projectFormData.append(
//         "scenario_description",
//         JSON.stringify(orderedFloors)
//       );

//       const projectInfoEndpoint = getResponse.ok
//         ? `${apiRootSecond}/api/project_info/${
//             (await getResponse.json())[0].id
//           }/`
//         : `${apiRootSecond}/api/project_info/`;

//       const projectInfoResponse = await fetch(projectInfoEndpoint, {
//         method: getResponse.ok ? "PATCH" : "POST",
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//         body: projectFormData,
//       });

//       if (!projectInfoResponse.ok)
//         throw new Error("Project info update failed");

//       // Show success message or redirect
//     } catch (error) {
//       console.error("Error:", error);
//       // Show error message to user
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-50 py-8">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="bg-white rounded-xl shadow-sm overflow-hidden">
//           <div className="px-6 py-8 border-b border-gray-200">
//             <h2 className="text-2xl font-bold text-gray-900">
//               Energy Data Setup
//             </h2>
//           </div>

//           <div className="p-6">
//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div className="bg-white p-6 rounded-lg border border-gray-200">
//                 <FileUploadSingleComponentEnergyData
//                   label="Upload Energy Data Files"
//                   id="energy-data"
//                   isZip={true}
//                   onZipContentDetected={handleEnergyZipContentDetected}
//                   energyData={true}
//                   onFileSelect={handleFileSelect}
//                 />

//                 {excelColumnNames.length > 0 && (
//                   <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
//                     {/* Available Values */}
//                     <div>
//                       <h3 className="text-lg font-medium mb-2">
//                         Available Energy Values:
//                       </h3>
//                       <select
//                         size="10"
//                         value={selectedAvailableFloor}
//                         onChange={(e) =>
//                           setSelectedAvailableFloor(e.target.value)
//                         }
//                         className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
//                       >
//                         {excelColumnNames
//                           .filter((name) => !orderedFloors.includes(name))
//                           .map((name) => (
//                             <option key={name} value={name} className="py-1">
//                               {name}
//                             </option>
//                           ))}
//                       </select>
//                     </div>

//                     {/* Control Buttons */}
//                     <div className="flex flex-col items-center justify-center space-y-4">
//                       <button
//                         type="button"
//                         onClick={handleAddFloor}
//                         className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors w-24"
//                       >
//                         Add →
//                       </button>
//                       <button
//                         type="button"
//                         onClick={handleRemoveFloor}
//                         className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors w-24"
//                       >
//                         ← Remove
//                       </button>
//                     </div>

//                     {/* Ordered Values */}
//                     <div>
//                       <h3 className="text-lg font-medium mb-2">
//                         Ordered Values:
//                       </h3>
//                       <select
//                         size="10"
//                         value={selectedOrderedFloor}
//                         onChange={(e) =>
//                           setSelectedOrderedFloor(e.target.value)
//                         }
//                         className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
//                       >
//                         {orderedFloors.map((name) => (
//                           <option key={name} value={name} className="py-1">
//                             {name}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               <button
//                 type="submit"
//                 disabled={!file || orderedFloors.length === 0}
//                 className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
//               >
//                 Submit
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EnergyDataInsertion;
import React, { useState } from "react";
import FileUploadSingleComponentEnergyData from "./FileUploadSingleComponentEnergyData";
import Toast from "./Toast";

const EnergyDataInsertion = () => {
  const [xlsxFileNames, setXlsxFileNames] = useState([]);
  const [file, setFile] = useState(null);
  const [excelColumnNames, setExcelColumnNames] = useState([]);
  const [orderedFloors, setOrderedFloors] = useState([]);
  const [selectedAvailableFloor, setSelectedAvailableFloor] = useState("");
  const [selectedOrderedFloor, setSelectedOrderedFloor] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [toast, setToast] = useState(null);

  const projectID = localStorage.getItem("projectID");
  const userID = localStorage.getItem("userID");
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const apiRootSecond = process.env.REACT_APP_API_ROOT;

  const handleFileSelect = (id, selectedFile) => {
    setFile(selectedFile);
  };

  const handleEnergyZipContentDetected = (columnNames, fileNames) => {
    setExcelColumnNames((prevNames) => {
      const allNames = new Set([...prevNames, ...columnNames]);
      return Array.from(allNames);
    });
    setXlsxFileNames(fileNames);
  };

  const handleAddFloor = () => {
    if (
      selectedAvailableFloor &&
      !orderedFloors.includes(selectedAvailableFloor)
    ) {
      setOrderedFloors((prev) => [...prev, selectedAvailableFloor]);
      setSelectedAvailableFloor("");
    }
  };

  const handleRemoveFloor = () => {
    if (selectedOrderedFloor) {
      setOrderedFloors((prev) =>
        prev.filter((floor) => floor !== selectedOrderedFloor)
      );
      setSelectedOrderedFloor("");
    }
  };

  const resetForm = () => {
    setFile(null);
    setXlsxFileNames([]);
    setExcelColumnNames([]);
    setOrderedFloors([]);
    setSelectedAvailableFloor("");
    setSelectedOrderedFloor("");
    // Reset the file input by forcing a re-render of FileUploadSingleComponentEnergyData
    // This is done by changing the key prop
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) fileInput.value = "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || isUploading) return;

    setIsUploading(true);
    setToast({ message: "Uploading files...", type: "success" });

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Upload file
      const queryParams = new URLSearchParams({
        project_id: projectID,
        user_id: userID,
        file_type: "thermal",
        values: JSON.stringify(orderedFloors),
        scenario_order: JSON.stringify(xlsxFileNames),
      });

      const uploadUrl = `${apiRoot}/api/rooms/uploadrooms?${queryParams}`;
      const uploadResponse = await fetch(uploadUrl, {
        method: "POST",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        body: formData,
      });

      if (!uploadResponse.ok) throw new Error("Upload failed");

      // Get project info
      const projectInfoUrl = `${apiRootSecond}/api/project_info/?project_id=${projectID}`;
      const getResponse = await fetch(projectInfoUrl, {
        method: "GET",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      // Prepare project info data
      const projectFormData = new FormData();
      projectFormData.append("project_id", projectID);
      projectFormData.append(
        "energy_value_list",
        JSON.stringify(orderedFloors)
      );
      projectFormData.append(
        "all_scenario_list",
        JSON.stringify(
          xlsxFileNames.map((name) => {
            const parts = name.split("/");
            return parts[parts.length - 1].replace(".xlsx", "");
          })
        )
      );
      projectFormData.append(
        "scenario_description",
        JSON.stringify(orderedFloors)
      );

      // Update project info
      const projectInfoEndpoint = getResponse.ok
        ? `${apiRootSecond}/api/project_info/${
            (await getResponse.json())[0].id
          }/`
        : `${apiRootSecond}/api/project_info/`;

      const projectInfoResponse = await fetch(projectInfoEndpoint, {
        method: getResponse.ok ? "PATCH" : "POST",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        body: projectFormData,
      });

      if (!projectInfoResponse.ok)
        throw new Error("Project info update failed");

      setToast({ message: "Files uploaded successfully!", type: "success" });
      resetForm();
    } catch (error) {
      console.error("Error:", error);
      setToast({ message: "Upload failed. Please try again.", type: "error" });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="px-6 py-8 border-b border-gray-200">
            <h2 className="text-2xl font-bold text-gray-900">
              Energy Data Setup
            </h2>
          </div>

          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <FileUploadSingleComponentEnergyData
                  key={file ? undefined : "reset"} // Force re-render when resetting
                  label="Upload Energy Data Files"
                  id="energy-data"
                  isZip={true}
                  onZipContentDetected={handleEnergyZipContentDetected}
                  energyData={true}
                  onFileSelect={handleFileSelect}
                />

                {excelColumnNames.length > 0 && (
                  <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Available Values */}
                    <div>
                      <h3 className="text-lg font-medium mb-2">
                        Available Energy Values:
                      </h3>
                      <select
                        size="10"
                        value={selectedAvailableFloor}
                        onChange={(e) =>
                          setSelectedAvailableFloor(e.target.value)
                        }
                        className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
                      >
                        {excelColumnNames
                          .filter((name) => !orderedFloors.includes(name))
                          .map((name) => (
                            <option key={name} value={name} className="py-1">
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* Control Buttons */}
                    <div className="flex flex-col items-center justify-center space-y-4">
                      <button
                        type="button"
                        onClick={handleAddFloor}
                        disabled={!selectedAvailableFloor}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors w-24 disabled:bg-gray-400 disabled:cursor-not-allowed"
                      >
                        Add →
                      </button>
                      <button
                        type="button"
                        onClick={handleRemoveFloor}
                        disabled={!selectedOrderedFloor}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors w-24 disabled:bg-gray-400 disabled:cursor-not-allowed"
                      >
                        ← Remove
                      </button>
                    </div>

                    {/* Ordered Values */}
                    <div>
                      <h3 className="text-lg font-medium mb-2">
                        Ordered Values:
                      </h3>
                      <select
                        size="10"
                        value={selectedOrderedFloor}
                        onChange={(e) =>
                          setSelectedOrderedFloor(e.target.value)
                        }
                        className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500"
                      >
                        {orderedFloors.map((name) => (
                          <option key={name} value={name} className="py-1">
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={!file || orderedFloors.length === 0 || isUploading}
                className="w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {isUploading ? "Uploading..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
};

export default EnergyDataInsertion;
