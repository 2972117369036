import React, { useState, useEffect } from "react";
import { Loader, ChevronDown, ChevronUp, AlertCircle } from "lucide-react";
import { ImageUpload } from "../ui/ImageUpload";

export const PreviewStep = ({
  previewData,
  onSubmit,
  onBack,
  isSubmitting,
  projectImage,
  onImageChange,
}) => {
  const [expandedSections, setExpandedSections] = useState({
    preview: true,
    project: true,
    esg: true,
    epc: true,
    image: true,
  });

  const safeJsonData = previewData?.jsonData || [{}];
  const firstRow = safeJsonData[0] || {};

  useEffect(() => {
    if (previewData) {
      console.log("Available Excel columns:", Object.keys(firstRow));
    }
  }, [previewData, firstRow]);

  if (!previewData) {
    return <div>No data to preview</div>;
  }

  const { jsonData, previewData: tableData, headers } = previewData;

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const checkRequiredFields = () => {
    const hasName =
      !!firstRow["Building Name"] || !!firstRow.Name || !!firstRow.name;

    const hasLatitude =
      !!firstRow.LATITUDE ||
      !!firstRow.Latitude ||
      !!firstRow.latitude ||
      !!firstRow.Lat;

    const hasLongitude =
      !!firstRow.LONGITUDE ||
      !!firstRow.Longitude ||
      !!firstRow.longitude ||
      !!firstRow.Long;

    return {
      valid: hasName,
      missingFields: [...(hasName ? [] : ["Name"])],
    };
  };
  const validation = checkRequiredFields();

  const getDataPreview = (type) => {
    const fieldMap = {
      project: {
        Name:
          firstRow["Building Name"] || firstRow.Name || firstRow.name || "-",
        Type: firstRow.Type || firstRow.type || "-",
        Latitude:
          firstRow.LATITUDE ||
          firstRow.Latitude ||
          firstRow.latitude ||
          firstRow.Lat ||
          "-",
        Longitude:
          firstRow.LONGITUDE ||
          firstRow.Longitude ||
          firstRow.longitude ||
          firstRow.Long ||
          "-",
        Country: firstRow.Country || firstRow.country || "-",
        City: firstRow.City || firstRow.city || "-",
        Area:
          firstRow["Total Area"] ||
          firstRow.Area ||
          firstRow.TotalArea ||
          firstRow.totalArea ||
          "-",
        "Construction Year":
          firstRow["Construction Year"] ||
          firstRow.Year ||
          firstRow.ConstructionYear ||
          firstRow.constructionYear ||
          "-",
      },
      esg: {
        "Spatial Information":
          firstRow["Spatial Information"] ||
          firstRow.Spatial ||
          firstRow.SpatialInformation ||
          firstRow.spatial_information ||
          "-",
        "End Energy Demand":
          firstRow["End Energy Demand"] ||
          firstRow["End Energy"] ||
          firstRow.EndEnergyDemand ||
          firstRow.end_energy_demand ||
          "-",
        "Primary Energy Demand":
          firstRow["Primary Energy Demand"] ||
          firstRow["Primary Energy"] ||
          firstRow.PrimaryEnergyDemand ||
          firstRow.primary_energy_demand ||
          "-",
        "CO2 Emission":
          firstRow["CO2 Emission"] ||
          firstRow.CO2 ||
          firstRow.CarbondioxidEmission ||
          firstRow.carbondioxid_emission ||
          "-",
      },
      epc: {
        "EPC Number":
          firstRow["EPC Number"] ||
          firstRow["EPC No"] ||
          firstRow.EPCNumber ||
          firstRow.epc_number ||
          "-",
        "Primary Energy (kWh/m²)":
          firstRow["Primary Energy kWh/m²"] ||
          firstRow["Primary kWh/m²"] ||
          firstRow.PrimaryEnergyKwhPerM2 ||
          firstRow.primary_energy_kwh_per_m2 ||
          "-",
        "End Energy (kWh/m²)":
          firstRow["End Energy kWh/m²"] ||
          firstRow["End kWh/m²"] ||
          firstRow.EndEnergyKwhPerM2 ||
          firstRow.end_energy_kwh_per_m2 ||
          "-",
        "EPC Class":
          firstRow["EPC Class"] ||
          firstRow.EPCClass ||
          firstRow.epc_class ||
          "-",
      },
    };

    return fieldMap[type] || {};
  };

  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">
        Preview Excel Data
      </h3>
      <p className="text-sm text-gray-600 mb-4">
        Review the data that will be imported. Click Submit to create the
        project and associated data.
      </p>

      {!validation.valid && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Required fields missing: {validation.missingFields.join(", ")}.
                Please make sure your Excel has columns for Building Name.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-6">
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div
            className="px-4 py-5 bg-gray-50 flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("image")}
          >
            <h4 className="text-lg font-medium text-gray-900">Project Image</h4>
            {expandedSections.image ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.image && (
            <div className="p-4">
              <p className="text-sm text-gray-600 mb-4">
                Add an image for your project. This will be displayed on the
                project details page.
              </p>
              <div className="p-6 bg-gray-50 rounded-lg">
                <ImageUpload
                  imagePreview={projectImage}
                  onImageChange={onImageChange}
                />
              </div>
              {projectImage && (
                <p className="text-center text-sm text-gray-500 mt-2">
                  Image selected and will be uploaded with your project
                </p>
              )}
            </div>
          )}
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div
            className="px-4 py-5 bg-gray-50 flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("preview")}
          >
            <h4 className="text-lg font-medium text-gray-900">
              Excel Data Preview
            </h4>
            {expandedSections.preview ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.preview && (
            <div className="p-4 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {tableData[0]?.map((header, idx) => (
                      <th
                        key={idx}
                        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header || `Column ${idx + 1}`}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {tableData.slice(1).map((row, rowIdx) => (
                    <tr key={rowIdx}>
                      {row.map((cell, cellIdx) => (
                        <td
                          key={cellIdx}
                          className="px-4 py-3 whitespace-nowrap text-sm text-gray-500"
                        >
                          {cell !== undefined ? String(cell) : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div
            className="px-4 py-5 bg-gray-50 flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("project")}
          >
            <h4 className="text-lg font-medium text-gray-900">Project Data</h4>
            {expandedSections.project ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.project && (
            <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(getDataPreview("project")).map(
                  ([key, value]) => (
                    <div key={`project-${key}`} className="flex items-start">
                      <span className="text-sm font-medium text-gray-500 w-1/2">
                        {key}
                        {["Name", "Latitude", "Longitude"].includes(key) && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                        :
                      </span>
                      <span
                        className={`text-sm w-1/2 ${
                          ["Name", "Latitude", "Longitude"].includes(key) &&
                          (value === "-" || value === "")
                            ? "text-red-600 font-medium"
                            : "text-gray-900"
                        }`}
                      >
                        {value}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>

        {/* ESG Data Section */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div
            className="px-4 py-5 bg-gray-50 flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("esg")}
          >
            <h4 className="text-lg font-medium text-gray-900">ESG Data</h4>
            {expandedSections.esg ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.esg && (
            <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(getDataPreview("esg")).map(([key, value]) => (
                  <div key={`esg-${key}`} className="flex items-start">
                    <span className="text-sm font-medium text-gray-500 w-1/2">
                      {key}:
                    </span>
                    <span className="text-sm text-gray-900 w-1/2">{value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div
            className="px-4 py-5 bg-gray-50 flex justify-between items-center cursor-pointer"
            onClick={() => toggleSection("epc")}
          >
            <h4 className="text-lg font-medium text-gray-900">EPC Data</h4>
            {expandedSections.epc ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.epc && (
            <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(getDataPreview("epc")).map(([key, value]) => (
                  <div key={`epc-${key}`} className="flex items-start">
                    <span className="text-sm font-medium text-gray-500 w-1/2">
                      {key}:
                    </span>
                    <span className="text-sm text-gray-900 w-1/2">{value}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 flex justify-end space-x-4">
        <button
          onClick={onBack}
          type="button"
          disabled={isSubmitting}
          className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Back
        </button>
        <button
          onClick={onSubmit}
          type="button"
          disabled={isSubmitting || !validation.valid}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
        >
          {isSubmitting ? (
            <>
              <Loader className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" />
              Processing...
            </>
          ) : (
            "Create/Update Project"
          )}
        </button>
      </div>
    </div>
  );
};
