// import React, { useState, useEffect, useRef } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { Camera, ArrowLeft } from "lucide-react";
// import { MainLayout } from "../components/layout/MainLayout";
// import { TabButton } from "./ui/TabButton";
// import { FormInput } from "./ui/FormInput";
// import fetchUtil from "./utils/fetchUtil";
// import { toast } from "react-toastify";
// import { logProjectActivity } from "./utils/activityLogger";
// const ProjectDetailPage = () => {
//   const { projectId } = useParams();
//   const navigate = useNavigate();
//   const fileInputRef = useRef(null);

//   const [activeTab, setActiveTab] = useState("basic");
//   const [isLoading, setIsLoading] = useState(true);
//   const [isSaving, setIsSaving] = useState(false);
//   const [error, setError] = useState(null);
//   const [projectImage, setProjectImage] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);

//   const [projectData, setProjectData] = useState({
//     basic: {
//       name: "",
//       descriptiveName: "",
//       type: "",
//       portfolio: "",
//       assetID: "",
//       buildingType: "",
//     },
//     location: {
//       latitude: "",
//       longitude: "",
//       country: "",
//       state: "",
//       city: "",
//       postCode: "",
//       street: "",
//       address: "",
//     },
//     details: {
//       constructionYear: "",
//       totalArea: "",
//       rentalUnits: "",
//       heritageProtected: "",
//       preFabricated: "",
//       buildingTypeText: "",
//       buildingTypeId: "",
//     },
//     technical: {
//       energyLabel: "",
//       energyConsumption: "",
//       heatEnergyConsumption: "",
//       totalHeatEnergyConsumption: "",
//       districtHeatingAvailable: "",
//       naturalGasAvailable: "",
//       weatherApi: "",
//     },
//   });
//   const apiRootSecond = process.env.REACT_APP_API_ROOT;
//   const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//   const token = localStorage.getItem("token");

//   // Fetch project details
//   useEffect(() => {
//     const fetchProject = async () => {
//       setIsLoading(true);
//       try {
//         const response = await fetchUtil(
//           `${apiRoot}/api/projects/${projectId}`,
//           "GET",
//           token
//         );
//         const mappedData = {
//           basic: {
//             name: response.name || "",
//             descriptiveName: response.descriptiveName || "",
//             type: response.type || "",
//             portfolio: response.portfolio || "",
//             assetID: response.assetID || "",
//             buildingType: response.buildingType || "",
//           },
//           location: {
//             latitude: response.latitude || "",
//             longitude: response.longitude || "",
//             country: response.country || "",
//             state: response.state || "",
//             city: response.city || "",
//             postCode: response.postCode || "",
//             street: response.street || "",
//             address: response.address || "",
//           },
//           details: {
//             constructionYear: response.constructionYear || "",
//             totalArea: response.totalArea || "",
//             rentalUnits: response.rentalUnits || "",
//             heritageProtected: response.heritageProtected || "",
//             preFabricated: response.preFabricated || "",
//             buildingTypeText: response.buildingTypeText || "",
//             buildingTypeId: response.buildingTypeId || "",
//           },
//           technical: {
//             energyLabel: response.energyLabel || "",
//             energyConsumption: response.energyConsumption || "",
//             heatEnergyConsumption: response.heatEnergyConsumption || "",
//             totalHeatEnergyConsumption:
//               response.totalHeatEnergyConsumption || "",
//             districtHeatingAvailable: response.districtHeatingAvailable || "",
//             naturalGasAvailable: response.naturalGasAvailable || "",
//             weatherApi: response.weatherApi || "",
//           },
//         };

//         setProjectData(mappedData);
//         setImagePreview(response.imageUrl);
//         setError(null);
//       } catch (err) {
//         setError("Failed to load project. Please try again.");
//         console.error("Error fetching project:", err);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     if (projectId && token) {
//       fetchProject();
//     }
//   }, [projectId, token, apiRoot]);

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setProjectImage(file);
//       const reader = new FileReader();
//       reader.onloadend = () => setImagePreview(reader.result);
//       reader.readAsDataURL(file);
//     }
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (isLoading || isSaving) return;

//     const currentTabData = projectData[activeTab];
//     let submissionData = { ...currentTabData };

//     // Helper functions for parsing
//     const parseNumericValue = (value) =>
//       !value && value !== 0
//         ? null
//         : isNaN(parseFloat(value))
//         ? null
//         : parseFloat(value);
//     const parseIntegerValue = (value) =>
//       !value && value !== 0
//         ? null
//         : isNaN(parseInt(value, 10))
//         ? null
//         : parseInt(value, 10);

//     // Type conversions based on active tab
//     switch (activeTab) {
//       case "location":
//         submissionData = {
//           ...submissionData,
//           latitude: parseNumericValue(submissionData.latitude),
//           longitude: parseNumericValue(submissionData.longitude),
//           country: submissionData.country || null,
//           state: submissionData.state || null,
//           city: submissionData.city || null,
//           postCode: submissionData.postCode || null,
//           street: submissionData.street || null,
//           address: submissionData.address || null,
//         };
//         break;

//       case "details":
//         submissionData = {
//           ...submissionData,
//           constructionYear: parseIntegerValue(submissionData.constructionYear),
//           totalArea: parseIntegerValue(submissionData.totalArea),
//           rentalUnits: parseIntegerValue(submissionData.rentalUnits),
//           buildingTypeId: parseIntegerValue(submissionData.buildingTypeId),
//           heritageProtected: submissionData.heritageProtected || null,
//           preFabricated: submissionData.preFabricated || null,
//           buildingTypeText: submissionData.buildingTypeText || null,
//         };
//         break;

//       case "technical":
//         submissionData = {
//           ...submissionData,
//           energyConsumption: parseNumericValue(
//             submissionData.energyConsumption
//           ),
//           heatEnergyConsumption: parseNumericValue(
//             submissionData.heatEnergyConsumption
//           ),
//           totalHeatEnergyConsumption: parseNumericValue(
//             submissionData.totalHeatEnergyConsumption
//           ),
//           energyLabel: submissionData.energyLabel || null,
//           districtHeatingAvailable:
//             submissionData.districtHeatingAvailable || null,
//           naturalGasAvailable: submissionData.naturalGasAvailable || null,
//           weatherApi: submissionData.weatherApi || null,
//         };
//         break;

//       case "basic":
//         submissionData = {
//           ...submissionData,
//           name: submissionData.name || null,
//           descriptiveName: submissionData.descriptiveName || null,
//           type: submissionData.type || null,
//           portfolio: submissionData.portfolio || null,
//           assetID: submissionData.assetID || null,
//           buildingType: submissionData.buildingType || null,
//         };
//         break;
//     }

//     submissionData.lastUpdatedTab = activeTab;
//     setIsSaving(true);
//     setError(null);

//     try {
//       // Update project details
//       const updateResponse = await fetchUtil(
//         `${apiRoot}/api/projects/${projectId}`,
//         "PUT",
//         token,
//         submissionData
//       );

//       await logProjectActivity(
//         "PROJECT_UPDATE",
//         200,
//         `Updated project ${
//           projectData.basic.name || projectId
//         } - ${activeTab} section`,
//         "Project Management"
//       );

//       // Handle image upload if present
//       if (projectImage) {
//         try {
//           const formData = new FormData();
//           formData.append("image_file", projectImage);
//           formData.append("project", projectId);

//           await fetchUtil(
//             `${apiRootSecond}/api/images/`,
//             "POST",
//             token,
//             formData,
//             true
//           );

//           await logProjectActivity(
//             "PROJECT_IMAGE_UPDATE",
//             200,
//             `Updated image for project ${projectData.basic.name || projectId}`,
//             "Project Management"
//           );
//         } catch (imageError) {
//           await logProjectActivity(
//             "PROJECT_IMAGE_UPDATE",
//             500,
//             `Failed to update project image: ${imageError.message}`,
//             "Project Management"
//           );
//           throw imageError;
//         }
//       }

//       toast.success("Project details updated successfully");

//       // Handle tab navigation
//       const tabs = ["basic", "location", "details", "technical"];
//       const currentIndex = tabs.indexOf(activeTab);
//       if (currentIndex < tabs.length - 1 && activeTab !== "technical") {
//         setActiveTab(tabs[currentIndex + 1]);
//       }
//     } catch (error) {
//       console.error("Project Update Error:", error);
//       const errorMessage =
//         error.response?.data?.details ||
//         error.message ||
//         "Failed to update project details";

//       await logProjectActivity(
//         "PROJECT_UPDATE",
//         500,
//         `Failed to update project ${
//           projectData.basic.name || projectId
//         } - ${activeTab} section: ${errorMessage}`,
//         "Project Management"
//       );

//       setError(errorMessage);
//       toast.error(errorMessage);
//     } finally {
//       setIsSaving(false);
//     }
//   };

//   const handleChange = (e, section) => {
//     const { name, value } = e.target;
//     setProjectData((prev) => ({
//       ...prev,
//       [section]: {
//         ...prev[section],
//         [name]: value,
//       },
//     }));
//   };

//   // Tab navigation
//   const tabs = [
//     { key: "basic", label: "Basic Info" },
//     { key: "location", label: "Location" },
//     { key: "details", label: "Details" },
//     { key: "technical", label: "Technical" },
//   ];

//   if (!token) {
//     return (
//       <MainLayout>
//         <div className="flex items-center justify-center min-h-screen bg-gray-50">
//           <div className="text-center">
//             <div className="bg-white p-6 rounded-lg shadow-sm">
//               <h3 className="text-lg font-medium text-gray-900 mb-2">
//                 Authentication Required
//               </h3>
//               <p className="text-gray-500 mb-4">
//                 Please log in to access this page
//               </p>
//               <button
//                 onClick={() => navigate("/login")}
//                 className="inline-flex items-center px-4 py-2 border border-transparent
//                   text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
//               >
//                 Go to Login
//               </button>
//             </div>
//           </div>
//         </div>
//       </MainLayout>
//     );
//   }

//   return (
//     <MainLayout>
//       <div className="max-w-4xl mx-auto p-6">
//         <div className="bg-white shadow-md rounded-lg overflow-hidden">
//           {/* Header */}
//           <div className="bg-gray-100 px-6 py-4 border-b flex justify-between items-center">
//             <div>
//               <h1 className="text-2xl font-bold text-gray-800">
//                 Project Details
//               </h1>
//               <p className="text-sm text-gray-600 mt-1">
//                 Manage and update project information
//               </p>
//             </div>
//             <button
//               onClick={() => navigate(-1)}
//               className="text-gray-600 hover:text-gray-900 flex items-center"
//             >
//               <ArrowLeft className="w-5 h-5 mr-2" />
//               Back
//             </button>
//           </div>

//           {/* Error Message */}
//           {error && (
//             <div className="bg-red-50 border-l-4 border-red-500 p-4">
//               <p className="text-red-700">{error}</p>
//             </div>
//           )}

//           {/* Image Upload */}
//           <div className="relative h-64 bg-gray-100">
//             {imagePreview ? (
//               <img
//                 src={imagePreview}
//                 alt="Project"
//                 className="w-full h-full object-cover"
//               />
//             ) : (
//               <div className="flex items-center justify-center h-full">
//                 <p className="text-gray-400">No image uploaded</p>
//               </div>
//             )}
//             <div
//               className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-40
//               transition-all duration-300 flex items-center justify-center"
//             >
//               <button
//                 onClick={() => fileInputRef.current.click()}
//                 className="p-3 rounded-full bg-white transform scale-90 opacity-0
//                   hover:scale-100 transition-all duration-300
//                   group-hover:opacity-100 shadow-lg"
//               >
//                 <Camera className="w-6 h-6 text-gray-600" />
//               </button>
//             </div>
//             <input
//               type="file"
//               ref={fileInputRef}
//               onChange={handleImageChange}
//               accept="image/*"
//               className="hidden"
//             />
//           </div>

//           {/* Tabs */}
//           <div className="flex border-b">
//             {tabs.map((tab, index) => (
//               <TabButton
//                 key={tab.key}
//                 active={activeTab === tab.key}
//                 onClick={() => setActiveTab(tab.key)}
//                 step={index + 1}
//               >
//                 {tab.label}
//               </TabButton>
//             ))}
//           </div>

//           {/* Form */}
//           <form onSubmit={handleSubmit} className="p-6">
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//               {Object.entries(projectData[activeTab]).map(([field, value]) => (
//                 <FormInput
//                   key={field}
//                   label={field
//                     .replace(/([A-Z])/g, " $1")
//                     .replace(/^./, (str) => str.toUpperCase())}
//                   name={field}
//                   value={value}
//                   onChange={(e) => handleChange(e, activeTab)}
//                   disabled={isLoading}
//                   placeholder={`Enter ${field.toLowerCase()}`}
//                 />
//               ))}
//             </div>

//             {/* Action Buttons */}
//             <div className="mt-8 pt-6 border-t border-gray-200 flex justify-end space-x-4">
//               <button
//                 type="button"
//                 onClick={() => navigate(-1)}
//                 className="px-4 py-2 text-sm font-medium text-gray-700 bg-white
//                   border border-gray-300 rounded-lg hover:bg-gray-50
//                   focus:outline-none focus:ring-2 focus:ring-gray-200"
//                 disabled={isSaving}
//               >
//                 Cancel
//               </button>
//               <button
//                 type="submit"
//                 className="px-4 py-2 text-sm font-medium text-white
//                   bg-blue-600 rounded-lg hover:bg-blue-700
//                   focus:outline-none focus:ring-2 focus:ring-blue-500
//                   disabled:opacity-50 disabled:cursor-not-allowed"
//                 disabled={isLoading || isSaving}
//               >
//                 {isSaving ? "Saving..." : "Save Changes"}
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </MainLayout>
//   );
// };

// export default ProjectDetailPage;
import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Camera, ArrowLeft } from "lucide-react";
import { MainLayout } from "../components/layout/MainLayout";
import { TabButton } from "./ui/TabButton";
import { FormInput } from "./ui/FormInput";
import fetchUtil from "./utils/fetchUtil";
import { toast } from "react-toastify";
import { logProjectActivity } from "./utils/activityLogger";

const BUILDING_TYPES = {
  official: "Official Building",
  commercial: "Commercial Building",
  public: "Public Building",
  retail_high_street: "Retail High Street",
  retail_shopping_center: "Retail Shopping Center",
  retail_warehouse: "Retail Warehouse",
  industrial_distribution_warehouse_cooled:
    "Industrial Distribution Warehouse Cooled",
  industrial_distribution_warehouse: "Industrial Distribution Warehouse",
  hotel: "Hotel",
  healthcare: "Healthcare",
  lodging_leisure_recreation: "Lodging Leisure Recreation",
  residential: "Residential",
  mixed_use: "Mixed Use",
};

const ProjectDetailPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [activeTab, setActiveTab] = useState("basic");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [projectImage, setProjectImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [projectData, setProjectData] = useState({
    basic: {
      name: "",
      descriptiveName: "",
      type: "",
      portfolio: "",
      assetID: "",
      buildingType: "",
    },
    location: {
      latitude: "",
      longitude: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      street: "",
      address: "",
    },
    details: {
      constructionYear: "",
      totalArea: "",
      rentalUnits: "",
      heritageProtected: "",
      preFabricated: "",
      buildingTypeText: "",
      buildingTypeId: "",
    },
    technical: {
      energyLabel: "",
      energyConsumption: "",
      heatEnergyConsumption: "",
      totalHeatEnergyConsumption: "",
      districtHeatingAvailable: "",
      naturalGasAvailable: "",
      weatherApi: "",
    },
  });

  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      try {
        const response = await fetchUtil(
          `${apiRoot}/api/projects/${projectId}`,
          "GET",
          token
        );
        const mappedData = {
          basic: {
            name: response.name || "",
            descriptiveName: response.descriptiveName || "",
            type: response.type || "",
            portfolio: response.portfolio || "",
            assetID: response.assetID || "",
            buildingType: response.buildingType || "",
          },
          location: {
            latitude: response.latitude || "",
            longitude: response.longitude || "",
            country: response.country || "",
            state: response.state || "",
            city: response.city || "",
            postCode: response.postCode || "",
            street: response.street || "",
            address: response.address || "",
          },
          details: {
            constructionYear: response.constructionYear || "",
            totalArea: response.totalArea || "",
            rentalUnits: response.rentalUnits || "",
            heritageProtected: response.heritageProtected || "",
            preFabricated: response.preFabricated || "",
            buildingTypeText: response.buildingTypeText || "",
            buildingTypeId: response.buildingTypeId || "",
          },
          technical: {
            energyLabel: response.energyLabel || "",
            energyConsumption: response.energyConsumption || "",
            heatEnergyConsumption: response.heatEnergyConsumption || "",
            totalHeatEnergyConsumption:
              response.totalHeatEnergyConsumption || "",
            districtHeatingAvailable: response.districtHeatingAvailable || "",
            naturalGasAvailable: response.naturalGasAvailable || "",
            weatherApi: response.weatherApi || "",
          },
        };

        setProjectData(mappedData);
        setImagePreview(response.imageUrl);
        setError(null);
      } catch (err) {
        setError("Failed to load project. Please try again.");
        console.error("Error fetching project:", err);
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId && token) {
      fetchProject();
    }
  }, [projectId, token, apiRoot]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProjectImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading || isSaving) return;

    const currentTabData = projectData[activeTab];
    let submissionData = { ...currentTabData };

    const parseNumericValue = (value) =>
      !value && value !== 0
        ? null
        : isNaN(parseFloat(value))
        ? null
        : parseFloat(value);
    const parseIntegerValue = (value) =>
      !value && value !== 0
        ? null
        : isNaN(parseInt(value, 10))
        ? null
        : parseInt(value, 10);

    switch (activeTab) {
      case "location":
        submissionData = {
          ...submissionData,
          latitude: parseNumericValue(submissionData.latitude),
          longitude: parseNumericValue(submissionData.longitude),
          country: submissionData.country || null,
          state: submissionData.state || null,
          city: submissionData.city || null,
          postCode: submissionData.postCode || null,
          street: submissionData.street || null,
          address: submissionData.address || null,
        };
        break;

      case "details":
        submissionData = {
          ...submissionData,
          constructionYear: parseIntegerValue(submissionData.constructionYear),
          totalArea: parseIntegerValue(submissionData.totalArea),
          rentalUnits: parseIntegerValue(submissionData.rentalUnits),
          buildingTypeId: parseIntegerValue(submissionData.buildingTypeId),
          heritageProtected: submissionData.heritageProtected || null,
          preFabricated: submissionData.preFabricated || null,
          buildingTypeText: submissionData.buildingTypeText || null,
        };
        break;

      case "technical":
        submissionData = {
          ...submissionData,
          energyConsumption: parseNumericValue(
            submissionData.energyConsumption
          ),
          heatEnergyConsumption: parseNumericValue(
            submissionData.heatEnergyConsumption
          ),
          totalHeatEnergyConsumption: parseNumericValue(
            submissionData.totalHeatEnergyConsumption
          ),
          energyLabel: submissionData.energyLabel || null,
          districtHeatingAvailable:
            submissionData.districtHeatingAvailable || null,
          naturalGasAvailable: submissionData.naturalGasAvailable || null,
          weatherApi: submissionData.weatherApi || null,
        };
        break;

      case "basic":
        submissionData = {
          ...submissionData,
          name: submissionData.name || null,
          descriptiveName: submissionData.descriptiveName || null,
          type: submissionData.type || null,
          portfolio: submissionData.portfolio || null,
          assetID: submissionData.assetID || null,
          buildingType: submissionData.buildingType || null,
        };
        break;
    }

    submissionData.lastUpdatedTab = activeTab;
    setIsSaving(true);
    setError(null);

    try {
      await fetchUtil(
        `${apiRoot}/api/projects/${projectId}`,
        "PUT",
        token,
        submissionData
      );

      await logProjectActivity(
        "PROJECT_UPDATE",
        200,
        `Updated project ${
          projectData.basic.name || projectId
        } - ${activeTab} section`,
        "Project Management"
      );

      if (projectImage) {
        try {
          const formData = new FormData();
          formData.append("image_file", projectImage);
          formData.append("project", projectId);

          await fetchUtil(
            `${apiRootSecond}/api/images/`,
            "POST",
            token,
            formData,
            true
          );

          await logProjectActivity(
            "PROJECT_IMAGE_UPDATE",
            200,
            `Updated image for project ${projectData.basic.name || projectId}`,
            "Project Management"
          );
        } catch (imageError) {
          await logProjectActivity(
            "PROJECT_IMAGE_UPDATE",
            500,
            `Failed to update project image: ${imageError.message}`,
            "Project Management"
          );
          throw imageError;
        }
      }

      toast.success("Project details updated successfully");

      const tabs = ["basic", "location", "details", "technical"];
      const currentIndex = tabs.indexOf(activeTab);
      if (currentIndex < tabs.length - 1 && activeTab !== "technical") {
        setActiveTab(tabs[currentIndex + 1]);
      }
    } catch (error) {
      console.error("Project Update Error:", error);
      const errorMessage =
        error.response?.data?.details ||
        error.message ||
        "Failed to update project details";

      await logProjectActivity(
        "PROJECT_UPDATE",
        500,
        `Failed to update project ${
          projectData.basic.name || projectId
        } - ${activeTab} section: ${errorMessage}`,
        "Project Management"
      );

      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (e, section) => {
    const { name, value } = e.target;
    setProjectData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));
  };

  const renderField = (field, value, section) => {
    // Special handling for weather API dropdown
    if (field === "weatherApi") {
      return (
        <div key={field} className="flex flex-col space-y-2">
          <label htmlFor={field} className="text-sm font-medium text-gray-700">
            Weather API
          </label>
          <select
            id={field}
            name={field}
            value={value || ""}
            onChange={(e) => handleChange(e, section)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            disabled={isLoading}
          >
            <option value="">Select status</option>
            <option value="on">On</option>
            <option value="off">Off</option>
          </select>
        </div>
      );
    }

    if (field === "buildingType") {
      return (
        <div key={field} className="flex flex-col space-y-2">
          <label htmlFor={field} className="text-sm font-medium text-gray-700">
            Building Type
          </label>
          <select
            id={field}
            name={field}
            value={value || ""}
            onChange={(e) => handleChange(e, section)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            disabled={isLoading}
          >
            <option value="">Select a building type</option>
            {Object.entries(BUILDING_TYPES).map(([key, label]) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </div>
      );
    }

    // Default form input for other fields
    return (
      <FormInput
        key={field}
        label={field
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase())}
        name={field}
        value={value}
        onChange={(e) => handleChange(e, section)}
        disabled={isLoading}
        placeholder={`Enter ${field.toLowerCase()}`}
      />
    );
  };

  const tabs = [
    { key: "basic", label: "Basic Info" },
    { key: "location", label: "Location" },
    { key: "details", label: "Details" },
    { key: "technical", label: "Technical" },
  ];
  if (!token) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
          <div className="text-center">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Authentication Required
              </h3>
              <p className="text-gray-500 mb-4">
                Please log in to access this page
              </p>
              <button
                onClick={() => navigate("/login")}
                className="inline-flex items-center px-4 py-2 border border-transparent 
                  text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Go to Login
              </button>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          {/* Header */}
          <div className="bg-gray-100 px-6 py-4 border-b flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-800">
                Project Details
              </h1>
              <p className="text-sm text-gray-600 mt-1">
                Manage and update project information
              </p>
            </div>
            <button
              onClick={() => navigate(-1)}
              className="text-gray-600 hover:text-gray-900 flex items-center"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4">
              <p className="text-red-700">{error}</p>
            </div>
          )}

          {/* Image Upload */}
          <div className="relative h-64 bg-gray-100">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Project"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-gray-400">No image uploaded</p>
              </div>
            )}
            <div
              className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-40 
              transition-all duration-300 flex items-center justify-center"
            >
              <button
                onClick={() => fileInputRef.current.click()}
                className="p-3 rounded-full bg-white transform scale-90 opacity-0 
                  hover:scale-100 transition-all duration-300 
                  group-hover:opacity-100 shadow-lg"
              >
                <Camera className="w-6 h-6 text-gray-600" />
              </button>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              accept="image/*"
              className="hidden"
            />
          </div>

          {/* Tabs */}
          <div className="flex border-b">
            {tabs.map((tab, index) => (
              <TabButton
                key={tab.key}
                active={activeTab === tab.key}
                onClick={() => setActiveTab(tab.key)}
                step={index + 1}
              >
                {tab.label}
              </TabButton>
            ))}
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {Object.entries(projectData[activeTab]).map(([field, value]) =>
                renderField(field, value, activeTab)
              )}
            </div>

            {/* Action Buttons */}
            <div className="mt-8 pt-6 border-t border-gray-200 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white 
                  border border-gray-300 rounded-lg hover:bg-gray-50 
                  focus:outline-none focus:ring-2 focus:ring-gray-200"
                disabled={isSaving}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white 
                  bg-blue-600 rounded-lg hover:bg-blue-700 
                  focus:outline-none focus:ring-2 focus:ring-blue-500
                  disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isLoading || isSaving}
              >
                {isSaving ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};
export default ProjectDetailPage;
