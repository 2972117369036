import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainLayout } from "../components/layout/MainLayout";

const WindowSetup = () => {
  // State management
  const [windowsData, setWindowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [projectSearchTerm, setProjectSearchTerm] = useState("");
  const [projectSearchResults, setProjectSearchResults] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeSection, setActiveSection] = useState("");
  const [windowSearchTerm, setWindowSearchTerm] = useState("");
  const [windowSearchResults, setWindowSearchResults] = useState([]);
  const [selectedWindow, setSelectedWindow] = useState(null);
  const [floorList, setFloorList] = useState([]);
  const [maxItems, setMaxItems] = useState(null);

  // Constants
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch project info
        const projectResponse = await fetch(
          `${apiRootSecond}/api/project_info/?project_id=${projectID}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (projectResponse.ok) {
          const projectData = await projectResponse.json();
          if (projectData.length > 0) {
            setFloorList(JSON.parse(projectData[0].floor_list));
          }
        }

        // Fetch surface info
        const surfaceResponse = await fetch(
          `${apiRoot}/api/surfaces?projectId=${projectID}&currentPage=${currentPage}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (surfaceResponse.ok) {
          const surfaceData = await surfaceResponse.json();
          setWindowsData(surfaceData.list || []);
          setTotalPages(surfaceData.pages || 1);
          setMaxItems(surfaceData.maxItems);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiRoot, apiRootSecond, projectID, token, currentPage]);

  // Project search functionality
  useEffect(() => {
    const searchProjects = async () => {
      if (projectSearchTerm.length >= 2) {
        try {
          const response = await fetch(`${apiRoot}/api/projects_by_name`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const { data } = await response.json();
            // Filter projects based on search term
            const filteredProjects = data.filter((project) =>
              project.name
                .toLowerCase()
                .includes(projectSearchTerm.toLowerCase())
            );
            setProjectSearchResults(filteredProjects);
          }
        } catch (error) {
          console.error("Error searching projects:", error);
          toast.error("Failed to search projects");
        }
      } else {
        setProjectSearchResults([]);
      }
    };
    // const searchProjects = async () => {
    //   if (projectSearchTerm.length >= 3) {
    //     try {
    //       const response = await fetch(
    //         `${apiRootSecond}/api/projects/search?query=${projectSearchTerm}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       );
    //       if (response.ok) {
    //         const data = await response.json();
    //         setProjectSearchResults(data);
    //       }
    //     } catch (error) {
    //       console.error("Error searching projects:", error);
    //     }
    //   } else {
    //     setProjectSearchResults([]);
    //   }
    // };

    const debounceTimeout = setTimeout(searchProjects, 300);
    return () => clearTimeout(debounceTimeout);
  }, [projectSearchTerm, apiRootSecond, token]);

  // Window search functionality
  useEffect(() => {
    const searchWindows = async () => {
      if (windowSearchTerm.length >= 3) {
        try {
          const response = await fetch(
            `${apiRoot}/api/surfaces/search?projectId=${projectID}&name=${windowSearchTerm}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          const data = await response.json();
          setWindowSearchResults(data.list || []);
        } catch (error) {
          console.error("Error searching windows:", error);
        }
      } else {
        setWindowSearchResults([]);
      }
    };

    const debounceTimeout = setTimeout(searchWindows, 300);
    return () => clearTimeout(debounceTimeout);
  }, [windowSearchTerm, apiRoot, projectID, token]);

  // Handlers
  const handleDeleteWindow = async () => {
    if (!selectedWindow) return;

    try {
      const response = await fetch(
        `${apiRoot}/api/surfaces/${selectedWindow.id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        toast.success("Window deleted successfully!");
        setWindowsData(
          windowsData.filter((window) => window.id !== selectedWindow.id)
        );
        setSelectedWindow(null);
      } else {
        toast.error("Failed to delete window.");
      }
    } catch (error) {
      console.error("Error deleting window:", error);
      toast.error("Failed to delete window. Please try again.");
    }
  };

  const handleCopyWindows = async () => {
    if (!selectedProject) {
      toast.error("Please select a project first.");
      return;
    }

    toast.info("Copying windows, please wait...");

    try {
      const response = await fetch(
        `${apiRoot}/api/surfaces/copy?oldProjectId=${selectedProject.id}&newProjectId=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sourceProjectId: selectedProject.id,
            targetProjectId: projectID,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        toast.success("Windows copied successfully!");
        setProjectSearchTerm("");
        setSelectedProject(null);
        setActiveSection("");
      } else {
        toast.error(`Error: ${data.message || "Failed to copy windows."}`);
      }
    } catch (error) {
      console.error("Error copying windows:", error);
      toast.error("Failed to copy windows. Please try again.");
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-200">
            {/* Header */}
            <div className="px-6 py-8 text-center border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Digital Twin File Uploader
              </h1>
              <p className="text-gray-500">
                Manage and configure window settings for your digital twin
              </p>
              {floorList.length > 0 && (
                <p className="text-sm text-gray-600 mt-4">
                  Available floors: {floorList.join(", ")}
                </p>
              )}
            </div>

            <ToastContainer position="top-right" autoClose={5000} />

            {isLoading ? (
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                <span className="ml-2 text-gray-600">
                  Loading windows data...
                </span>
              </div>
            ) : (
              <div className="p-6">
                {/* Search Section */}
                <div className="bg-gray-50 rounded-lg p-4 mb-6">
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        placeholder="Search windows by name (min 3 chars)"
                        value={windowSearchTerm}
                        onChange={(e) => setWindowSearchTerm(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div className="md:w-64">
                      <select
                        value={selectedWindow ? selectedWindow.id : ""}
                        onChange={(e) => {
                          const window = windowSearchResults.find(
                            (w) => w.id === parseInt(e.target.value)
                          );
                          setSelectedWindow(window);
                        }}
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select a window</option>
                        {windowSearchResults.map((window) => (
                          <option key={window.id} value={window.id}>
                            {window.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedWindow && (
                      <button
                        onClick={handleDeleteWindow}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Delete Window
                      </button>
                    )}
                  </div>
                </div>

                {/* Windows Table */}
                <div className="bg-white rounded-lg shadow overflow-hidden mb-6">
                  <h2 className="px-6 py-4 bg-gray-50 border-b border-gray-200 text-lg font-medium text-gray-900">
                    Windows Overview {maxItems && `(Total: ${maxItems})`}
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Area (m²)
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Level
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {windowsData.map((window) => (
                          <tr key={window.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {window.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {window.area}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {window.level || "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex items-center justify-between">
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Previous
                    </button>
                    <span className="text-sm text-gray-700">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Next
                    </button>
                  </div>
                </div>

                {/* Copy Windows Section */}
                <div className="mt-6">
                  <button
                    onClick={() =>
                      setActiveSection(activeSection === "copy" ? "" : "copy")
                    }
                    className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Copy Windows from Another Project
                  </button>
                </div>

                {activeSection === "copy" && (
                  <div className="mt-6 bg-gray-50 rounded-lg p-6">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                      Copy Windows Configuration
                    </h3>
                    <div className="space-y-4">
                      {/* Project Search with Autocomplete */}
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search for a project (min 3 chars)"
                          value={projectSearchTerm}
                          onChange={(e) => setProjectSearchTerm(e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                        {projectSearchResults.length > 0 &&
                          !selectedProject && (
                            <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                              <ul className="max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                                {projectSearchResults.map((project) => (
                                  <li
                                    key={project.id}
                                    onClick={() => {
                                      setSelectedProject(project);
                                      setProjectSearchTerm(project.name);
                                      setProjectSearchResults([]);
                                    }}
                                    className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-50"
                                  >
                                    <div className="flex items-center">
                                      <span className="block truncate">
                                        {project.name}
                                      </span>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                      </div>

                      {/* Selected Project Display */}
                      {selectedProject && (
                        <div className="flex items-center justify-between bg-blue-50 p-4 rounded-md">
                          <div>
                            <p className="text-sm font-medium text-blue-900">
                              Selected Project:
                            </p>
                            <p className="text-lg text-blue-700">
                              {selectedProject.name}
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              setSelectedProject(null);
                              setProjectSearchTerm("");
                            }}
                            className="text-blue-500 hover:text-blue-700"
                          >
                            Change
                          </button>
                        </div>
                      )}

                      {/* Copy Action Button */}
                      <div className="flex justify-end">
                        <button
                          onClick={handleCopyWindows}
                          disabled={!selectedProject}
                          className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                            disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {isLoading ? (
                            <span className="flex items-center">
                              <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                              Copying...
                            </span>
                          ) : (
                            "Copy Windows"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default WindowSetup;
