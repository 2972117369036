import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle } from "lucide-react";

const LoginPage = ({ onAuthenticated = () => {} }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
    if (!apiRoot) {
      setError("Configuration error. Please contact support.");
      setIsLoading(false);
      return;
    }

    const tokenEndpoint = `${apiRoot}/api/login`;
    const client_id = "vue-frontend";
    const client_secret = "";

    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: `grant_type=password&username=${encodeURIComponent(
          username
        )}&password=${encodeURIComponent(
          password
        )}&client_id=${client_id}&client_secret=${client_secret}`,
      });

      const data = await response.json();

      if (response.ok && data.access_token) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("userID", data.user_id);
        localStorage.setItem("role", data.role);

        if (typeof onAuthenticated === "function") {
          onAuthenticated(data.access_token);
        }

        navigate("/features");
      } else {
        setError(
          data.error_description || "Authentication failed. Please try again."
        );
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex">
      {/* Login Form Section */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8 bg-gradient-to-br from-blue-600 to-blue-800">
        <div className="w-full max-w-md bg-white rounded-2xl shadow-xl p-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
            Welcome Back
          </h2>

          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-700">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your username"
                required
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 
                  focus:ring-blue-500 focus:border-blue-500 transition-colors"
                placeholder="Enter your password"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg
                font-medium transition-colors duration-200 flex items-center justify-center
                disabled:bg-blue-400 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    />
                  </svg>
                  Signing in...
                </>
              ) : (
                "Sign In"
              )}
            </button>
          </form>

          <p className="mt-8 text-center text-sm text-gray-500">
            © 2024 Digital Twin Technologies. All rights reserved.
          </p>
        </div>
      </div>

      {/* Image Section */}
      <div className="hidden lg:block w-1/2 bg-gray-900 relative">
        <div
          className="absolute inset-0 bg-black bg-opacity-50 flex items-center 
            justify-center text-white p-12"
        >
          <div className="max-w-xl text-center">
            <h1 className="text-4xl font-bold mb-4">Digital Twin Portal</h1>
            <p className="text-xl mb-4 text-gray-200">
              Unlock the power of digital transformation with our advanced twin
              technologies.
            </p>
            <p className="text-gray-400">
              Seamless integration, real-time insights, and intelligent
              decision-making at your fingertips.
            </p>
          </div>
        </div>
        <img
          src="/api/placeholder/1200/800?text=Digital+Twin+Technologies"
          alt="Digital Twin Technologies"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  );
};

export default LoginPage;
