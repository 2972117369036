import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadStep } from "./ExcelUploaderComponent/UploadStep";
import { PreviewStep } from "./ExcelUploaderComponent/PreviewStep";
import { Toast } from "./ui/Toast";
import {
  processExcelFile,
  mapExcelRowToProjectInput,
  mapExcelRowToESGInput,
  mapExcelRowToEPCInput,
} from "./utils/excelProcessing";

const ExcelUploader = () => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [previewData, setPreviewData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [step, setStep] = useState(1);
  const [projectImage, setProjectImage] = useState(null); // Add state for project image

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");

  const showCustomToast = (message, type, duration = 3000) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);

    if (type !== "loading") {
      setTimeout(() => {
        setShowToast(false);
      }, duration);
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleFileSelect = (file) => {
    if (file) {
      setFileName(file.name);
      setFileData(file);
      processFile(file);
    }
  };

  const handleImageChange = (e) => {
    if (e.target && e.target.files) {
      const file = e.target.files[0];
      setProjectImage(file);
    } else if (e && e.file) {
      setProjectImage(e.file);
    }
  };

  const processFile = async (file) => {
    try {
      const data = await processExcelFile(file);
      setPreviewData(data);
      setStep(2);
    } catch (error) {
      console.error("Error processing Excel file:", error);
      showCustomToast(
        "Failed to process Excel file. Please make sure it's a valid Excel file.",
        "error"
      );
    }
  };

  const handleSubmit = async () => {
    if (!fileData || !previewData) {
      showCustomToast("Please upload a valid Excel file first", "error");
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);
    showCustomToast("Processing Excel file. Please wait...", "loading");

    try {
      showCustomToast("Creating project...", "loading");

      const projectInput = mapExcelRowToProjectInput(
        previewData.jsonData[0],
        userID
      );
      if (!projectInput.descriptiveName && projectInput.name) {
        projectInput.descriptiveName = projectInput.name;
      }

      const projectResponse = await fetch(
        `${apiRootOperational}/api/projects`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(projectInput),
        }
      );

      if (!projectResponse.ok) {
        throw new Error(
          `Failed to create project: ${await projectResponse.text()}`
        );
      }

      const projectData = await projectResponse.json();
      const projectId = projectData.id;

      showCustomToast(
        `Project created successfully with ID: ${projectId}`,
        "loading"
      );

      let imageUploadSuccess = false;
      if (projectImage && projectId) {
        try {
          showCustomToast("Uploading project image...", "loading");

          const formData = new FormData();
          formData.append("image_file", projectImage);
          formData.append("project", projectId);

          const imageResponse = await fetch(`${apiRoot}/api/images/`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          if (imageResponse.ok) {
            imageUploadSuccess = true;
            showCustomToast("Project image uploaded successfully", "loading");
          } else {
            console.error(`Image upload failed: ${await imageResponse.text()}`);
          }
        } catch (err) {
          console.error("Image upload error:", err);
        }
      }

      let esgSuccess = false;
      try {
        showCustomToast("Creating ESG data...", "loading");
        const esgInput = mapExcelRowToESGInput(
          previewData.jsonData[0],
          projectId
        );

        const esgResponse = await fetch(`${apiRootOperational}/api/esg-data`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(esgInput),
        });

        if (esgResponse.ok) {
          esgSuccess = true;
          showCustomToast("ESG data created successfully", "loading");
        } else {
          console.error(`ESG creation failed: ${await esgResponse.text()}`);
        }
      } catch (err) {
        console.error("ESG data error:", err);
      }

      let epcSuccess = false;
      try {
        showCustomToast("Creating EPC data...", "loading");
        const epcInput = mapExcelRowToEPCInput(
          previewData.jsonData[0],
          projectId
        );

        const epcResponse = await fetch(`${apiRootOperational}/api/epc`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(epcInput),
        });

        if (epcResponse.ok) {
          epcSuccess = true;
          showCustomToast("EPC data created successfully", "loading");
        } else {
          console.error(`EPC creation failed: ${await epcResponse.text()}`);
        }
      } catch (err) {
        console.error("EPC data error:", err);
      }

      let assignmentSuccess = false;
      try {
        showCustomToast("Assigning project to user...", "loading");

        const assignmentResponse = await fetch(
          `${apiRoot}/api/project_assignment/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify([
              {
                user: parseInt(userID),
                project: parseInt(projectId),
              },
            ]),
          }
        );

        if (assignmentResponse.ok) {
          assignmentSuccess = true;
          showCustomToast("Project assigned successfully", "loading");
        } else {
          console.error(
            `Project assignment failed: ${await assignmentResponse.text()}`
          );
        }
      } catch (err) {
        console.error("Project assignment error:", err);
      }

      let successMessage = `Project created successfully with ID: ${projectId}.`;
      if (imageUploadSuccess) {
        successMessage += " Image uploaded.";
      }
      if (esgSuccess) {
        successMessage += " ESG data added.";
      }
      if (epcSuccess) {
        successMessage += " EPC data added.";
      }
      if (assignmentSuccess) {
        successMessage += " Project assigned to you.";
      }

      showCustomToast(successMessage, "success");
      setSubmitStatus("success");

      setFileData(null);
      setFileName("No file chosen");
      setPreviewData(null);
      setProjectImage(null);
      setStep(1);

      setTimeout(() => {
        navigate("/user-projects");
      }, 3000);
    } catch (err) {
      console.error("Processing Error:", err);
      showCustomToast(`Error: ${err.message}`, "error");
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <UploadStep
            fileName={fileName}
            onFileSelect={handleFileSelect}
            projectImage={projectImage}
            onImageChange={handleImageChange}
          />
        );

      case 2:
        return (
          <PreviewStep
            previewData={previewData}
            onSubmit={handleSubmit}
            onBack={handleBack}
            isSubmitting={isSubmitting}
            projectImage={projectImage}
            onImageChange={handleImageChange}
          />
        );

      default:
        return (
          <UploadStep
            fileName={fileName}
            onFileSelect={handleFileSelect}
            projectImage={projectImage}
            onImageChange={handleImageChange}
          />
        );
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        {showToast && (
          <Toast message={toastMessage} type={toastType} onClose={hideToast} />
        )}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 text-center border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Bulk Project Import
              </h1>
              <p className="text-gray-500">
                Import a project from Excel with ESG and EPC data
              </p>
            </div>

            <div className="p-6">{renderStepContent()}</div>

            <div className="mt-4 bg-gray-50 rounded-lg p-6 mx-6 mb-6">
              <h2 className="text-sm font-medium text-gray-900 mb-4">
                File Requirements
              </h2>
              <ul className="space-y-2">
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Excel file (.xlsx or .xls) with project data
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Required project fields: Name, Latitude, Longitude
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Optional ESG fields: Spatial Information, End Energy Demand,
                  etc.
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Optional EPC fields: EPC Number, Primary Energy kWh/m², etc.
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  You can optionally add a project image that will be uploaded
                  with your data
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ExcelUploader;
