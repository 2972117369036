import { TopNav } from "./TopNav";
import { SideNav } from "./SideNav";
import React, { useState } from "react";

export const MainLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50 overflow-x-hidden">
      <TopNav />
      <div className="flex">
        <div
          className={`
          fixed inset-y-0 left-0 w-64 bg-gray-800 text-white z-40 transform transition-transform duration-300 ease-in-out
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
          lg:translate-x-0
        `}
        >
          <SideNav />
          <button
            className="lg:hidden absolute top-4 right-4 text-white"
            onClick={() => setIsSidebarOpen(false)}
          >
            ✕
          </button>
        </div>

        {/* Overlay for mobile sidebar */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}

        {/* Main content area */}
        <main className="flex-1 p-4 w-full lg:pl-64 pt-16 max-w-full">
          {/* Mobile menu toggle */}
          <button
            className="lg:hidden mb-4 bg-gray-200 p-2 rounded"
            onClick={() => setIsSidebarOpen(true)}
          >
            ☰ Menu
          </button>

          <div className="w-full max-w-full overflow-hidden">{children}</div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
