import React, { useEffect } from "react";
import { X } from "lucide-react";

const Toast = ({ message, type = "success", onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const bgColor = type === "error" ? "bg-red-100" : "bg-green-100";
  const textColor = type === "error" ? "text-red-800" : "text-green-800";
  const borderColor = type === "error" ? "border-red-200" : "border-green-200";

  return (
    <div
      className={`fixed top-4 right-4 z-50 p-4 rounded-md shadow-lg border ${bgColor} ${borderColor}`}
    >
      <div className="flex items-center justify-between">
        <span className={`${textColor} font-medium`}>{message}</span>
        <button
          onClick={onClose}
          className={`ml-4 ${textColor} hover:opacity-75`}
        >
          <X size={16} />
        </button>
      </div>
    </div>
  );
};

export default Toast;
