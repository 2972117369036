import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";

const CACHE_DURATION = 5 * 60 * 1000;
const CACHE_KEY = "userProjectsCache";

const Project = ({ name, description, imageUrl, id }) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleProjectClick = () => {
    localStorage.setItem("projectID", id);
    navigate(`/project-edit/${id}`);
  };

  const handleImageError = () => {
    setImageError(true);
    setImageLoading(false);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  return (
    <div
      onClick={handleProjectClick}
      className="bg-white rounded-xl p-5 transition-all duration-200 flex flex-col gap-4 border border-gray-200 
        hover:transform hover:-translate-y-1 hover:shadow-lg cursor-pointer h-full"
    >
      <div className="relative w-full h-48">
        {imageLoading && (
          <div className="absolute inset-0 bg-gray-100 animate-pulse rounded-lg" />
        )}

        {imageError ? (
          <div className="w-full h-48 bg-gray-100 rounded-lg flex items-center justify-center">
            <span className="text-gray-400">Image unavailable</span>
          </div>
        ) : (
          <img
            src={imageUrl}
            alt={name}
            className={`w-full h-48 object-cover rounded-lg ${
              imageLoading ? "opacity-0" : "opacity-100"
            }`}
            onError={handleImageError}
            onLoad={handleImageLoad}
          />
        )}
      </div>
      <h3 className="text-lg font-semibold text-gray-800 m-0">{name}</h3>
      <p className="text-sm text-gray-600 leading-relaxed m-0">{description}</p>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleProjectClick();
        }}
        className="mt-auto bg-blue-500 text-white py-2 px-4 rounded-md font-medium 
          transition-colors duration-200 hover:bg-blue-600 focus:outline-none focus:ring-2 
          focus:ring-blue-500 focus:ring-offset-2"
      >
        Edit
      </button>
    </div>
  );
};

const UserProjectsGrid = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(false);

  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");

  const getCachedData = () => {
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (!cachedData) return null;

      const { data, timestamp } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;

      if (isExpired) {
        localStorage.removeItem(CACHE_KEY);
        return null;
      }

      return data;
    } catch (error) {
      console.error("Cache reading error:", error);
      localStorage.removeItem(CACHE_KEY);
      return null;
    }
  };

  const setCachedData = (data) => {
    try {
      const cacheData = {
        data,
        timestamp: Date.now(),
      };
      localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
    } catch (error) {
      console.error("Cache writing error:", error);
    }
  };

  const clearCache = () => {
    try {
      localStorage.removeItem(CACHE_KEY);
    } catch (error) {
      console.error("Cache clearing error:", error);
    }
  };

  // Handlers
  const handleRefresh = () => {
    clearCache();
    setForceRefresh((prev) => !prev);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLogout = () => {
    clearCache();
    localStorage.removeItem("userID");
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);

      if (!userId || !token) {
        setError("Please log in to view projects");
        setIsLoading(false);
        return;
      }

      const cachedProjects = forceRefresh ? null : getCachedData();
      if (cachedProjects) {
        setProjects(cachedProjects);
        setFilteredProjects(cachedProjects);
        setError(null);
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${apiRoot}/api/assets/${userId}/get_asset_overview/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            cache: "no-cache",
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            handleLogout();
            throw new Error("Session expired. Please log in again.");
          }
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        const projectsArray = Array.isArray(data) ? data : [];

        if (!forceRefresh) {
          setCachedData(projectsArray);
        }

        setProjects(projectsArray);
        setFilteredProjects(projectsArray);
        setError(null);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError(
          error.message || "Failed to load projects. Please try again later."
        );
        setProjects([]);
        setFilteredProjects([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [apiRoot, userId, token, forceRefresh, navigate]);

  // Filter projects based on search
  useEffect(() => {
    if (!Array.isArray(projects)) {
      setFilteredProjects([]);
      return;
    }

    const filtered = projects.filter((project) =>
      project?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchQuery, projects]);

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50">
        <div className="px-6 py-4 border-b border-gray-200 bg-white shadow-sm">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="flex items-center gap-4 w-full md:w-auto">
              <input
                type="text"
                placeholder="Search projects..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-full md:w-72 px-4 py-2 text-sm border border-gray-200 rounded-lg 
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                  transition-all duration-200"
              />
              <button
                onClick={handleRefresh}
                className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700
                  transition-colors duration-200 focus:outline-none focus:ring-2 
                  focus:ring-blue-500 focus:ring-offset-2 rounded-md"
              >
                Refresh
              </button>
            </div>
          </div>
        </div>

        {error && (
          <div className="px-6 py-4">
            <div className="max-w-7xl mx-auto">
              <div className="bg-red-50 text-red-600 px-4 py-3 rounded-lg">
                {error}
              </div>
            </div>
          </div>
        )}

        {isLoading && (
          <div className="px-6 py-12 text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-gray-200 border-t-blue-500"></div>
            <p className="mt-2 text-gray-600">Loading projects...</p>
          </div>
        )}

        {!isLoading && !error && (
          <div className="px-6 py-6">
            <div className="max-w-7xl mx-auto">
              {filteredProjects.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  {filteredProjects.map((project, index) => (
                    <Project
                      key={project.id || index}
                      name={project.name}
                      description={project.description}
                      imageUrl={project.image_url_value}
                      id={project.id}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <p className="text-gray-600">
                    {searchQuery
                      ? "No projects found matching your search."
                      : "No projects found."}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default UserProjectsGrid;
