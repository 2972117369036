import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadStep } from "./ExcelUploaderComponent/UploadStep";
import { PreviewStep } from "./ExcelUploaderComponent/PreviewStep";
import { Toast } from "./ui/Toast";
import {
  processExcelFile,
  mapExcelRowToProjectInput,
  mapExcelRowToESGInput,
  mapExcelRowToEPCInput,
} from "./utils/excelProcessing";

const ProjectUpdater = () => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [previewData, setPreviewData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [step, setStep] = useState(1);
  const [projectImage, setProjectImage] = useState(null);
  const [projectId, setProjectId] = useState("");

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");

  const showCustomToast = (message, type, duration = 3000) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);

    if (type !== "loading") {
      setTimeout(() => {
        setShowToast(false);
      }, duration);
    }
  };

  const hideToast = () => {
    setShowToast(false);
  };

  const handleFileSelect = (file) => {
    if (file) {
      setFileName(file.name);
      setFileData(file);
      processFile(file);
    }
  };

  const handleImageChange = (e) => {
    if (e.target && e.target.files) {
      const file = e.target.files[0];
      setProjectImage(file);
    } else if (e && e.file) {
      setProjectImage(e.file);
    }
  };

  const handleProjectIdChange = (e) => {
    setProjectId(e.target.value);
  };

  const processFile = async (file) => {
    try {
      const data = await processExcelFile(file);
      setPreviewData(data);

      const firstRow = data.jsonData[0] || {};
      if (
        firstRow.id ||
        firstRow.ID ||
        firstRow.projectId ||
        firstRow.ProjectID
      ) {
        const excelProjectId =
          firstRow.id ||
          firstRow.ID ||
          firstRow.projectId ||
          firstRow.ProjectID;
        setProjectId(String(excelProjectId));
      }

      setStep(2);
    } catch (error) {
      console.error("Error processing Excel file:", error);
      showCustomToast(
        "Failed to process Excel file. Please make sure it's a valid Excel file.",
        "error"
      );
    }
  };

  const handleSubmit = async () => {
    if (!fileData || !previewData) {
      showCustomToast("Please upload a valid Excel file first", "error");
      return;
    }

    if (!projectId) {
      showCustomToast("Please provide a Project ID to update", "error");
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);
    showCustomToast(
      "Processing Excel file for update. Please wait...",
      "loading"
    );

    try {
      showCustomToast(`Updating project with ID: ${projectId}...`, "loading");

      const projectInput = mapExcelRowToProjectInput(
        previewData.jsonData[0],
        userID
      );

      const projectUpdate = {
        id: parseInt(projectId, 10),
        ...projectInput,
      };

      const projectResponse = await fetch(
        `${apiRootOperational}/api/projects/${projectId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(projectUpdate),
        }
      );

      if (!projectResponse.ok) {
        throw new Error(
          `Failed to update project: ${await projectResponse.text()}`
        );
      }

      showCustomToast(
        `Project updated successfully with ID: ${projectId}`,
        "loading"
      );

      let imageUploadSuccess = false;
      if (projectImage) {
        try {
          showCustomToast("Updating project image...", "loading");

          const checkImageResponse = await fetch(
            `${apiRoot}/api/images/?project=${projectId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const existingImages = await checkImageResponse.json();

          if (existingImages && existingImages.length > 0) {
            const imageId = existingImages[0].id;
            const formData = new FormData();
            formData.append("image_file", projectImage);

            const updateImageResponse = await fetch(
              `${apiRoot}/api/images/${imageId}/`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                body: formData,
              }
            );

            if (updateImageResponse.ok) {
              imageUploadSuccess = true;
              showCustomToast("Project image updated successfully", "loading");
            } else {
              console.error(
                `Image update failed: ${await updateImageResponse.text()}`
              );
            }
          } else {
            const formData = new FormData();
            formData.append("image_file", projectImage);
            formData.append("project", projectId);

            const imageResponse = await fetch(`${apiRoot}/api/images/`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            });

            if (imageResponse.ok) {
              imageUploadSuccess = true;
              showCustomToast(
                "New project image uploaded successfully",
                "loading"
              );
            } else {
              console.error(
                `Image upload failed: ${await imageResponse.text()}`
              );
            }
          }
        } catch (err) {
          console.error("Image update error:", err);
        }
      }

      // Step 3: Update ESG Data if available
      let esgSuccess = false;
      try {
        showCustomToast("Updating ESG data...", "loading");
        const esgInput = mapExcelRowToESGInput(
          previewData.jsonData[0],
          parseInt(projectId, 10)
        );

        const checkEsgResponse = await fetch(
          `${apiRootOperational}/api/esg-data?project_id=${projectId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const existingEsgData = await checkEsgResponse.json();

        if (existingEsgData && existingEsgData.length > 0) {
          const esgId = existingEsgData[0].id;

          const esgResponse = await fetch(
            `${apiRootOperational}/api/esg-data/${esgId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(esgInput),
            }
          );

          if (esgResponse.ok) {
            esgSuccess = true;
            showCustomToast("ESG data updated successfully", "loading");
          } else {
            console.error(`ESG update failed: ${await esgResponse.text()}`);
          }
        } else {
          const esgResponse = await fetch(
            `${apiRootOperational}/api/esg-data`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(esgInput),
            }
          );

          if (esgResponse.ok) {
            esgSuccess = true;
            showCustomToast("New ESG data created successfully", "loading");
          } else {
            console.error(`ESG creation failed: ${await esgResponse.text()}`);
          }
        }
      } catch (err) {
        console.error("ESG data error:", err);
      }

      let epcSuccess = false;
      try {
        showCustomToast("Updating EPC data...", "loading");
        const epcInput = mapExcelRowToEPCInput(
          previewData.jsonData[0],
          parseInt(projectId, 10)
        );

        const checkEpcResponse = await fetch(
          `${apiRootOperational}/api/epc?projectID=${projectId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const existingEpcData = await checkEpcResponse.json();

        if (existingEpcData && existingEpcData.length > 0) {
          const epcId = existingEpcData[0].id;

          const epcResponse = await fetch(
            `${apiRootOperational}/api/epc/${epcId}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(epcInput),
            }
          );

          if (epcResponse.ok) {
            epcSuccess = true;
            showCustomToast("EPC data updated successfully", "loading");
          } else {
            console.error(`EPC update failed: ${await epcResponse.text()}`);
          }
        } else {
          const epcResponse = await fetch(`${apiRootOperational}/api/epc`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(epcInput),
          });

          if (epcResponse.ok) {
            epcSuccess = true;
            showCustomToast("New EPC data created successfully", "loading");
          } else {
            console.error(`EPC creation failed: ${await epcResponse.text()}`);
          }
        }
      } catch (err) {
        console.error("EPC data error:", err);
      }

      let successMessage = `Project with ID ${projectId} updated successfully.`;
      if (imageUploadSuccess) {
        successMessage += " Image updated.";
      }
      if (esgSuccess) {
        successMessage += " ESG data updated.";
      }
      if (epcSuccess) {
        successMessage += " EPC data updated.";
      }

      showCustomToast(successMessage, "success");
      setSubmitStatus("success");

      setFileData(null);
      setFileName("No file chosen");
      setPreviewData(null);
      setProjectImage(null);
      setProjectId("");
      setStep(1);

      setTimeout(() => {
        navigate("/user-projects");
      }, 3000);
    } catch (err) {
      console.error("Processing Error:", err);
      showCustomToast(`Error: ${err.message}`, "error");
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="mb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Project to Update
              </h3>
              <p className="text-sm text-gray-600 mb-4">
                Enter the ID of the project you want to update.
              </p>

              <label className="block text-sm font-medium text-gray-700 mb-2">
                Project ID <span className="text-red-500">*</span>
              </label>
              <div className="mb-4">
                <input
                  type="text"
                  value={projectId}
                  onChange={handleProjectIdChange}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md p-2 border"
                  placeholder="Enter project ID"
                  required
                />
                <p className="mt-1 text-xs text-gray-500">
                  The ID of the existing project you want to update
                </p>
              </div>
            </div>

            <UploadStep
              fileName={fileName}
              onFileSelect={handleFileSelect}
              projectImage={projectImage}
              onImageChange={handleImageChange}
            />
          </div>
        );

      case 2:
        return (
          <div>
            <div className="mb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Project to Update
              </h3>
              <div className="flex items-center">
                <label className="text-sm font-medium text-gray-700 mr-2">
                  Project ID:
                </label>
                <input
                  type="text"
                  value={projectId}
                  onChange={handleProjectIdChange}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md p-2 border"
                  placeholder="Enter project ID"
                  required
                />
                {!projectId && (
                  <div className="ml-2 text-sm text-red-500">
                    Required for update
                  </div>
                )}
              </div>
            </div>

            <PreviewStep
              previewData={previewData}
              onSubmit={handleSubmit}
              onBack={handleBack}
              isSubmitting={isSubmitting}
              projectImage={projectImage}
              onImageChange={handleImageChange}
            />
          </div>
        );

      default:
        return (
          <UploadStep
            fileName={fileName}
            onFileSelect={handleFileSelect}
            projectImage={projectImage}
            onImageChange={handleImageChange}
          />
        );
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        {showToast && (
          <Toast message={toastMessage} type={toastType} onClose={hideToast} />
        )}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 text-center border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Update Existing Project
              </h1>
              <p className="text-gray-500">
                Update an existing project from Excel with ESG and EPC data
              </p>
            </div>

            <div className="p-6">{renderStepContent()}</div>

            <div className="mt-4 bg-gray-50 rounded-lg p-6 mx-6 mb-6">
              <h2 className="text-sm font-medium text-gray-900 mb-4">
                Update Requirements
              </h2>
              <ul className="space-y-2">
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Excel file (.xlsx or .xls) with project data to update
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Project ID of the project you want to update
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  You can optionally update the project image
                </li>
                <li className="flex items-start text-sm text-gray-600">
                  <span className="mr-2">•</span>
                  Any ESG and EPC data will be updated if it exists, or created
                  if it doesn't
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ProjectUpdater;
