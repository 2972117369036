import React from "react";

export const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-lg shadow-lg p-6 ${className}`}>
    {children}
  </div>
);

export const FormInput = ({ label, error, ...props }) => (
  <div className="space-y-2">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 
        ${error ? "border-red-300" : "border-gray-300"}`}
      {...props}
    />
    {error && <p className="text-sm text-red-600">{error}</p>}
  </div>
);

export const FormSelect = ({ label, options, error, ...props }) => (
  <div className="space-y-2">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <select
      className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500
        ${error ? "border-red-300" : "border-gray-300"}`}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <p className="text-sm text-red-600">{error}</p>}
  </div>
);

export const Button = ({
  children,
  variant = "primary",
  className = "",
  ...props
}) => {
  const variants = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white",
    secondary: "bg-gray-200 hover:bg-gray-300 text-gray-800",
    danger: "bg-red-600 hover:bg-red-700 text-white",
  };

  return (
    <button
      className={`px-4 py-2 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 
        ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export const Alert = ({ type = "info", message }) => {
  if (!message) return null;

  const types = {
    error: "bg-red-50 text-red-700 border-red-200",
    success: "bg-green-50 text-green-700 border-green-200",
    info: "bg-blue-50 text-blue-700 border-blue-200",
  };

  return (
    <div className={`p-4 rounded-md border ${types[type]}`}>{message}</div>
  );
};

export const FormGroup = ({ children, className = "" }) => (
  <div className={`space-y-4 ${className}`}>{children}</div>
);

export const PageContainer = ({ children, className = "" }) => (
  <div className={`min-h-screen bg-gray-50 p-8 ${className}`}>
    <div className="max-w-2xl mx-auto">{children}</div>
  </div>
);
