import React, { useState, useRef } from "react";
import "./FileUpload.css";
import FileUploadWithoutFeatureSingleComponent from "./FileUploadWithoutFeatureComponents";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";

const FileSingleComponent = ({
  heading1,
  heading2,
  fileUploadComponents,
  showEdit,
  editText,
  showEditHover,
  apiContextArg,
  apiText,
  heading3,
  heading4,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const fileInputRefs = useRef(
    fileUploadComponents.map(() => React.createRef())
  );
  // const [uploadProgress, setUploadProgress] = useState({});
  const [windowCount, setWindowCount] = useState(0);

  console.log("ooops");
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const navigate = useNavigate();
  const [fileData, setFileData] = useState({});
  const handleFileSelect = (id, file) => {
    setFileData((prevData) => ({ ...prevData, [id]: file }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let allUploadsSuccessful = true;
    for (const component of fileUploadComponents) {
      var url = component.uploadUrl;
      const file = fileData[component.id];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          for (let [key, value] of formData.entries()) {
            console.log(key, value);
          }

          console.log(formData);
          if (isEditMode) {
            const queryParams = new URLSearchParams({
              edit_direction: "yes",
            }).toString();
            url += "&" + queryParams;
          }
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              // "Content-Type": "application/json",
            },
            body: formData,
          });

          const data = await response.json();
          console.log(data);
          if (data.status !== "processing") {
            allUploadsSuccessful = false;
            throw new Error("Error uploading file");
          }
        } catch (error) {
          console.error(`Error uploading file for ${component.id}:`, error);
          window.alert(`Error uploading file: ${error.message}`);
          allUploadsSuccessful = false;
        }
      }
    }
    if (allUploadsSuccessful) {
      window.alert("File(s) uploaded successfully");
      setFileData({});
      fileInputRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });
    }
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="upload-container">
        <h2>{heading2}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <h1>{heading1}</h1>
          </div>
          <div className="file-upload-class">
            {fileUploadComponents.map((component, index) => (
              <FileUploadWithoutFeatureSingleComponent
                key={index}
                label={component.label}
                id={component.id}
                onFileSelect={handleFileSelect}
                ref={fileInputRefs.current[index]}
              />
            ))}
            <div>
              <div>
                {apiContextArg
                  ? `${apiText}: ${apiContextArg}`
                  : "No context data"}
              </div>
              {/* Other component content */}
            </div>
          </div>
          {showEdit ? (
            <div>
              <input
                type="checkbox"
                id="editSceneOrder"
                checked={isEditMode}
                onChange={(e) => setIsEditMode(e.target.checked)}
                title={showEditHover}
              />
              <label htmlFor="editSceneOrder">{editText}</label>
            </div>
          ) : (
            ""
          )}

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FileSingleComponent;
