import fetchUtil from "../utils/fetchUtil";

export const ProfileFields = {
  BUILDING_PROFILE: "building_profile",
  TECHNICAL_DATA: "technical_data",
  ENERGY_CO2: "energy_co2",
  CERTIFICATES: "certificates",
  MODERNIZATION: "modernization",
};

export class ProfileService {
  constructor(apiRoot, token) {
    this.apiRoot = apiRoot;
    this.token = token;
  }

  async getProfileData(projectId) {
    try {
      const url = `${this.apiRoot}/api/projects-pages/?project_id=${projectId}`;
      const response = await fetchUtil(url, "GET", this.token);
      return response[0] || null;
    } catch (error) {
      console.error("Error fetching profile data:", error);
      throw error;
    }
  }

  async updateProfile(projectId, field, value) {
    try {
      const currentProfile = await this.getProfileData(projectId);

      if (currentProfile) {
        // Update existing profile
        const url = `${this.apiRoot}/api/projects-pages/${currentProfile.id}/`;
        const updatedData = {
          ...currentProfile,
          [field]: value,
        };
        return await fetchUtil(url, "PUT", this.token, updatedData);
      } else {
        // Create new profile if none exists
        const url = `${this.apiRoot}/api/projects-pages/`;
        const newProfile = {
          project_id: projectId,
          building_profile: 1,
          technical_data: 0,
          energy_co2: 0,
          certificates: 0,
          modernization: 0,
          [field]: value,
        };
        return await fetchUtil(url, "POST", this.token, newProfile);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  }

  // Convenience methods for specific fields
  async updateBuildingProfile(projectId, value) {
    return this.updateProfile(projectId, ProfileFields.BUILDING_PROFILE, value);
  }

  async updateTechnicalData(projectId, value) {
    return this.updateProfile(projectId, ProfileFields.TECHNICAL_DATA, value);
  }

  async updateEnergyCO2(projectId, value) {
    return this.updateProfile(projectId, ProfileFields.ENERGY_CO2, value);
  }

  async updateCertificates(projectId, value) {
    return this.updateProfile(projectId, ProfileFields.CERTIFICATES, value);
  }

  async updateModernization(projectId, value) {
    return this.updateProfile(projectId, ProfileFields.MODERNIZATION, value);
  }
}

export default ProfileService;
