import fetchUtil from "../utils/fetchUtil";

export const ProjectInfoFields = {
  METRICS: "metrics",
  ROOM_LEVEL_SCENARIOS: "room_level_scenarios",
  ALL_SCENARIO_LIST: "all_scenario_list",
  FLOOR_LIST: "floor_list",
  CEILING_LIST: "ceiling_list",
  EXTERNAL_LIST: "external_list",
  INTERNAL_LIST: "internal_list",
  ENERGY_VALUE_LIST: "energy_value_list",
  SCENARIO_DESCRIPTION: "scenario_description",
  PV_PANEL_SCENARIO: "pv_panel_data",
  SHADING_PROGRESSION: "shading_progression",
  SHADING_PROGRESSION_DATE: "shading_progression_date",
  SHADING_PROGRESSION_STEP: "shading_progression_step",
  SENSOR_PLACEMENT_ZONE_CONFIG: "sensor_placement_zone_config",
  SENSOR_PLACEMENT: "sensor_placement",
};

export class ProjectInfoService {
  constructor(apiRoot, token) {
    this.apiRoot = apiRoot;
    this.token = token;
  }

  async getProjectInfo(projectId) {
    try {
      const url = `${this.apiRoot}/api/project_info/?project_id=${projectId}`;
      const response = await fetchUtil(url, "GET", this.token);
      return response[0] || null;
    } catch (error) {
      console.error("Error fetching project info:", error);
      throw error;
    }
  }

  parseJsonField(field) {
    if (!field) return [];
    try {
      if (typeof field === "string") {
        const unescaped = field.replace(/\\"/g, '"');
        return JSON.parse(unescaped);
      }
      return field;
    } catch (error) {
      console.error("Error parsing JSON field:", error);
      return [];
    }
  }
  stringifyJsonField(field) {
    try {
      if (typeof field === "string") {
        try {
          JSON.parse(field);
          return field;
        } catch {
          return JSON.stringify([field]);
        }
      }

      return JSON.stringify(field);
    } catch (error) {
      console.error("Error stringifying JSON field:", error);
      return "[]";
    }
  }

  async updateProjectInfo(projectId, updates) {
    try {
      const currentInfo = await this.getProjectInfo(projectId);

      if (currentInfo) {
        const url = `${this.apiRoot}/api/project_info/${currentInfo.id}/`;
        const updatedData = {
          ...currentInfo,
          ...updates,
        };
        return await fetchUtil(url, "PUT", this.token, updatedData);
      } else {
        const url = `${this.apiRoot}/api/project_info/`;
        const defaultMetrics = [
          {
            level: "building",
            dataKey: "consumption",
          },
        ];

        const newProjectInfo = {
          project_id: projectId,
          metrics: JSON.stringify(defaultMetrics),
          room_level_scenarios: JSON.stringify([]),
          all_scenario_list: JSON.stringify([]),
          pv_panel_data: JSON.stringify([]),
          sensor_placement_zone_config: JSON.stringify([]),
          ...updates,
        };
        return await fetchUtil(url, "POST", this.token, newProjectInfo);
      }
    } catch (error) {
      console.error("Error updating project info:", error);
      throw error;
    }
  }

  async updateListField(projectId, field, newItems, options = {}) {
    const { overwrite = false, unique = true } = options;

    try {
      const currentInfo = await this.getProjectInfo(projectId);
      let updatedItems;

      if (!currentInfo) {
        updatedItems = Array.isArray(newItems) ? newItems : [newItems];
        const formattedValue = this.stringifyJsonField(updatedItems);

        return await this.updateProjectInfo(projectId, {
          [field]: formattedValue,
        });
      }

      const currentItems = this.parseJsonField(currentInfo[field]) || [];

      if (overwrite) {
        updatedItems = Array.isArray(newItems) ? newItems : [newItems];
      } else {
        const itemsToAdd = Array.isArray(newItems) ? newItems : [newItems];

        if (unique) {
          updatedItems = [...new Set([...currentItems, ...itemsToAdd])];
        } else {
          updatedItems = [...currentItems, ...itemsToAdd];
        }
      }

      const formattedValue = this.stringifyJsonField(updatedItems);

      return await this.updateProjectInfo(projectId, {
        [field]: formattedValue,
      });
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      throw error;
    }
  }
  async updateMetrics(projectId, metrics = null) {
    const defaultMetrics = [
      {
        level: "building",
        dataKey: "consumption",
      },
    ];

    const metricsToUpdate = metrics || defaultMetrics;
    return this.updateListField(
      projectId,
      ProjectInfoFields.METRICS,
      metricsToUpdate,
      { overwrite: true }
    );
  }

  async updatePVPanelScenarios(projectId, scenario) {
    if (!scenario) {
      throw new Error("Scenario name is required");
    }

    return this.updateListField(
      projectId,
      ProjectInfoFields.PV_PANEL_SCENARIO,
      scenario,
      { overwrite: false, unique: true }
    );
  }

  async updateRoomLevelScenarios(projectId, scenario) {
    if (!scenario) {
      throw new Error("Scenario name is required");
    }

    return this.updateListField(
      projectId,
      ProjectInfoFields.ROOM_LEVEL_SCENARIOS,
      scenario,
      { overwrite: false, unique: true }
    );
  }
  async updateSensorPlacementZoneConfigList(projectId, scenario) {
    if (!scenario) {
      throw new Error("Scenario name is required");
    }

    return this.updateListField(
      projectId,
      ProjectInfoFields.SENSOR_PLACEMENT_ZONE_CONFIG,
      scenario,
      { overwrite: false, unique: true }
    );
  }

  async updateAllScenarioList(projectId, scenarios, options = {}) {
    return this.updateListField(
      projectId,
      ProjectInfoFields.ALL_SCENARIO_LIST,
      scenarios,
      options
    );
  }

  async updateFloorList(projectId, floors, options = {}) {
    return this.updateListField(
      projectId,
      ProjectInfoFields.FLOOR_LIST,
      floors,
      options
    );
  }
  async updateShadingProgression(projectId, { date, step }) {
    try {
      const updates = {
        [ProjectInfoFields.SHADING_PROGRESSION]: "1",
        [ProjectInfoFields.SHADING_PROGRESSION_DATE]: date,
        [ProjectInfoFields.SHADING_PROGRESSION_STEP]: step,
      };

      return await this.updateProjectInfo(projectId, updates);
    } catch (error) {
      console.error("Error updating shading progression:", error);
      throw error;
    }
  }
  async updateEnergyValueList(projectId, values, options = {}) {
    return this.updateListField(
      projectId,
      ProjectInfoFields.ENERGY_VALUE_LIST,
      values,
      options
    );
  }
}

export default ProjectInfoService;
