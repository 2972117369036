import React, { useState, useCallback } from "react";
import { UploadCloud, AlertCircle, CheckCircle, X } from "lucide-react";
import JSZip from "jszip";
import { read, utils } from "xlsx";

const EXCLUDED_COLUMNS = [
  "Name",
  "Area [m2]",
  "Level",
  "Function",
  "Middle room",
  "Heatmaps X coordinates",
  "Heatmaps Y coordinates",
  "Room elevation",
];

const FileUploadSingleComponentEnergyData = ({
  label,
  id,
  isZip = false,
  onZipContentDetected,
  energyData = false,
  onFileSelect,
}) => {
  const [fileState, setFileState] = useState({
    name: "No file chosen",
    error: null,
    success: false,
    processing: false,
  });
  const [isDragging, setIsDragging] = useState(false);

  const resetFileState = () => {
    setFileState({
      name: "No file chosen",
      error: null,
      success: false,
      processing: false,
    });
  };

  const processExcelFile = async (excelFile) => {
    try {
      const workbook = read(excelFile, { type: "array" });
      const columnNames = [];

      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const firstRow = utils.sheet_to_json(sheet, {
          header: 1,
          range: 0,
        })[0];

        if (firstRow) {
          const filteredColumns = firstRow.filter(
            (columnName) => !EXCLUDED_COLUMNS.includes(columnName)
          );
          columnNames.push(...filteredColumns);
        }
      });

      return columnNames;
    } catch (error) {
      console.error("Error processing Excel file:", error);
      throw new Error("Failed to process Excel file");
    }
  };

  const processZipFile = async (file) => {
    try {
      setFileState((prev) => ({ ...prev, processing: true }));
      const zip = new JSZip();
      const contents = await zip.loadAsync(file);
      const xlsxFileNames = [];
      const columnNamesArray = [];

      for (const fileName of Object.keys(contents.files)) {
        if (fileName.includes("__MACOSX") || fileName.includes(".DS_Store")) {
          continue;
        }

        if (fileName.endsWith(".xlsx")) {
          xlsxFileNames.push(fileName);
          const excelFile = await contents.files[fileName].async("arraybuffer");
          const columnNames = await processExcelFile(excelFile);
          columnNamesArray.push(...columnNames);
        }
      }

      const uniqueColumnNames = [...new Set(columnNamesArray)];
      onZipContentDetected?.(uniqueColumnNames, xlsxFileNames);

      setFileState((prev) => ({
        ...prev,
        processing: false,
        success: true,
      }));
    } catch (error) {
      console.error("Error processing ZIP file:", error);
      setFileState((prev) => ({
        ...prev,
        processing: false,
        error: "Failed to process ZIP file",
      }));
    }
  };

  const handleFile = async (file) => {
    if (!file) return;

    setFileState({
      name: file.name,
      error: null,
      success: false,
      processing: true,
    });

    try {
      if (isZip) {
        await processZipFile(file);
      }
      onFileSelect?.(id, file);
    } catch (error) {
      setFileState((prev) => ({
        ...prev,
        processing: false,
        error: error.message,
      }));
    }
  };

  const handleDragEvent = (e, isDragging) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(isDragging);
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  }, []);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center mb-2">
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {fileState.error && (
          <button
            onClick={resetFileState}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-4 h-4" />
          </button>
        )}
      </div>

      <div
        className={`
          relative border-2 border-dashed rounded-lg p-6 transition-all duration-200
          ${isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"}
          ${fileState.error ? "border-red-300 bg-red-50" : ""}
          ${fileState.success ? "border-green-300 bg-green-50" : ""}
          hover:border-gray-400
        `}
        onDragOver={(e) => handleDragEvent(e, true)}
        onDragLeave={(e) => handleDragEvent(e, false)}
        onDrop={handleDrop}
      >
        <input
          type="file"
          onChange={(e) => handleFile(e.target.files?.[0])}
          accept={isZip ? ".zip" : undefined}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />

        <div className="text-center">
          {fileState.error ? (
            <div className="text-red-600">
              <AlertCircle className="mx-auto h-12 w-12 mb-4" />
              <p className="text-sm">{fileState.error}</p>
              <p className="text-xs mt-2">Drop a new file or click to retry</p>
            </div>
          ) : fileState.success ? (
            <div className="text-green-600">
              <CheckCircle className="mx-auto h-12 w-12 mb-4" />
              <p className="text-sm">File processed successfully</p>
              <p className="text-xs mt-2">{fileState.name}</p>
            </div>
          ) : (
            <>
              <UploadCloud
                className={`mx-auto h-12 w-12 mb-4 ${
                  fileState.processing
                    ? "animate-bounce text-blue-500"
                    : "text-gray-400"
                }`}
              />
              <p className="text-sm text-gray-600">
                {fileState.processing
                  ? "Processing file..."
                  : "Drag and drop your file here or click to browse"}
              </p>
              <p className="mt-1 text-xs text-gray-500">{fileState.name}</p>
            </>
          )}
        </div>
      </div>

      {isZip && (
        <p className="mt-2 text-xs text-gray-500">
          Supported format: ZIP file containing Excel spreadsheets
        </p>
      )}
    </div>
  );
};

export default FileUploadSingleComponentEnergyData;
