import React, { useState, useEffect } from "react";
import "./UserProject.css";
import CardGrid from "./CardGrid";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import { PlusCircle, Edit2, ChevronRight } from "lucide-react";
import { Loader, AlertCircle } from "lucide-react";
const Project = ({ name, description, imageUrl, id }) => {
  const navigate = useNavigate();

  const handleProjectClick = () => {
    localStorage.setItem("projectID", id);
    navigate(`/project-edit/${id}`);
  };

  return (
    <div
      onClick={handleProjectClick}
      className="bg-white rounded-xl shadow-sm overflow-hidden cursor-pointer
          transition-all duration-200 hover:shadow-lg hover:-translate-y-1"
    >
      <img src={imageUrl} alt={name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{name}</h3>
        <p className="text-gray-600 text-sm mb-4">{description}</p>
        <button
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg
              hover:bg-blue-700 transition-colors text-sm font-medium"
        >
          Edit
        </button>
      </div>
    </div>
  );
};

const ProjectProcessing = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");

  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${apiRoot}/api/assets/${userId}/get_asset_overview/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        setProjects(data);
        setFilteredProjects(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [apiRoot, token, userId]);

  useEffect(() => {
    const filtered = projects.filter((project) =>
      project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProjects(filtered);
  }, [searchQuery, projects]);

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 text-center">
                Project Processing
              </h1>
              <p className="mt-2 text-center text-gray-600">
                Manage and process your digital twin projects
              </p>
            </div>

            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">
                Processing Steps
              </h2>
              <CardGrid />
            </div>

            <div className="border-t border-gray-200 mt-8">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-lg font-semibold text-gray-900">
                    Your Projects
                  </h2>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search projects..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="pl-4 pr-10 py-2 border border-gray-300 rounded-lg
                          focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                {loading ? (
                  <div className="flex items-center justify-center py-12">
                    <Loader className="w-8 h-8 text-blue-500 animate-spin" />
                  </div>
                ) : error ? (
                  <div className="flex items-center gap-2 p-4 bg-red-50 rounded-lg">
                    <AlertCircle className="w-5 h-5 text-red-500" />
                    <p className="text-red-700">{error}</p>
                  </div>
                ) : filteredProjects.length === 0 ? (
                  <div className="text-center py-12 text-gray-500">
                    No projects found matching your search.
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProjects.map((project) => (
                      <Project
                        key={project.id}
                        {...project}
                        imageUrl="/api/placeholder/400/300"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ProjectProcessing;
