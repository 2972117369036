// import React, { useState } from "react";
// import { UploadCloud } from "lucide-react";

// export const UploadStep = ({ fileName, onFileSelect }) => {
//   const [isDragging, setIsDragging] = useState(false);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       onFileSelect(file);
//     }
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//     setIsDragging(true);
//   };

//   const handleDragLeave = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDragging(false);
//     const file = e.dataTransfer.files[0];
//     if (file) {
//       onFileSelect(file);
//     }
//   };

//   return (
//     <div className="mb-6">
//       <h3 className="text-lg font-medium text-gray-900 mb-4">
//         Upload Excel File
//       </h3>
//       <p className="text-sm text-gray-600 mb-4">
//         Please upload an Excel file containing your project data.
//       </p>

//       <label className="block text-sm font-medium text-gray-700 mb-2">
//         Project Excel File
//       </label>
//       <div
//         className={`relative border-2 border-dashed rounded-lg p-6
//           ${
//             isDragging
//               ? "border-blue-500 bg-blue-50"
//               : "border-gray-300 hover:border-gray-400"
//           }
//           transition-all duration-200 cursor-pointer
//         `}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//         onDrop={handleDrop}
//       >
//         <input
//           type="file"
//           accept=".xlsx,.xls"
//           onChange={handleFileChange}
//           className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
//         />
//         <div className="text-center">
//           <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
//           <p className="mt-2 text-sm text-gray-600">
//             Drag and drop your Excel file here or click to browse
//           </p>
//           <p className="mt-1 text-xs text-gray-500">{fileName}</p>
//         </div>
//       </div>
//     </div>
//   );
// };
// 1. First, modify the UploadStep.jsx to maintain the image upload section

import React, { useState } from "react";
import { UploadCloud } from "lucide-react";
import { ImageUpload } from "../ui/ImageUpload"; // Import ImageUpload component

export const UploadStep = ({
  fileName,
  onFileSelect,
  projectImage,
  onImageChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <div>
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Upload Excel File
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          Please upload an Excel file containing your project data.
        </p>

        <label className="block text-sm font-medium text-gray-700 mb-2">
          Project Excel File
        </label>
        <div
          className={`relative border-2 border-dashed rounded-lg p-6 
            ${
              isDragging
                ? "border-blue-500 bg-blue-50"
                : "border-gray-300 hover:border-gray-400"
            }
            transition-all duration-200 cursor-pointer
          `}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".xlsx,.xls"
            onChange={handleFileChange}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="text-center">
            <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-600">
              Drag and drop your Excel file here or click to browse
            </p>
            <p className="mt-1 text-xs text-gray-500">{fileName}</p>
          </div>
        </div>
      </div>

      {/* Add image upload section */}
      <div className="mt-8">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Project Image (Optional)
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          Add an image for your project. This will be displayed on the project
          details page.
        </p>
        <div className="p-6 bg-gray-50 rounded-lg">
          <ImageUpload
            imagePreview={projectImage}
            onImageChange={onImageChange}
          />
        </div>
      </div>
    </div>
  );
};
