import useActivityStore from "../hooks/useActivity";

export const logProjectActivity = async (
  action,
  status,
  description,
  feature
) => {
  const { createActivity } = useActivityStore.getState();
  const userId = localStorage.getItem("userID");
  const projectID = localStorage.getItem("projectID");

  await createActivity({
    action,
    status,
    description,
    project_name: projectID,
    feature,
    datetime: new Date().toISOString(),
    user_id: userId,
  });
};
