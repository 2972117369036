import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";

const FeatureCard = ({
  title,
  description,
  onClick,
  status,
  data,
  isLoading,
}) => {
  return (
    <div
      onClick={onClick}
      className={`bg-white rounded-lg p-5 shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer
        transform hover:-translate-y-1 border-l-4
        ${
          status === "active"
            ? "border-green-500"
            : status === "inactive"
            ? "border-red-500"
            : "border-transparent"
        }`}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        <span className="text-gray-500 text-xl">→</span>
      </div>
      <p className="text-sm text-gray-600 mb-4 leading-relaxed">
        {description}
      </p>
      <div className="text-sm">
        {isLoading ? (
          <div className="flex items-center gap-2 text-gray-600">
            <div className="w-4 h-4 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin"></div>
            Loading status...
          </div>
        ) : status === "active" ? (
          <div className="flex items-center gap-2 text-green-600">
            <span className="font-bold">✓</span>
            {data ? `Configured - ${data}` : "Configured"}
          </div>
        ) : (
          <div className="flex items-center gap-2 text-red-600">
            <span className="font-bold">!</span>
            Not configured
          </div>
        )}
      </div>
    </div>
  );
};

const ProjectEdit = () => {
  const navigate = useNavigate();
  const [featureData, setFeatureData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const projectID = localStorage.getItem("projectID");
  const token = localStorage.getItem("token");

  const features = [
    {
      title: "Project Information",
      description: "Configure basic project settings and general information",
      path: `project/${projectID}`,
      category: "Setup",
    },
    // {
    //   title: "Project Information Update By File",
    //   description: "Configure basic project settings and general information",
    //   path: `project-file/${projectID}`,
    //   category: "Setup",
    // },
    {
      title: "3D Scene Setup",
      description: "Upload and configure 3D models and scene parameters",
      path: `scene-setup/${projectID}`,
      category: "Setup",
    },
    {
      title: "Window Configuration",
      description: "Define and manage window properties and positions",
      path: `window-setup/${projectID}`,
      category: "Setup",
    },
    {
      title: "Virtual Sensors",
      description: "Configure virtual sensors for environmental monitoring",
      path: `virtual-sensor-setup/${projectID}`,
      category: "Simulation",
    },
    {
      title: "Sensor Placement",
      description: "Define optimal sensor locations and coverage areas",
      path: `sensor-placement/${projectID}`,
      category: "Simulation",
    },
    {
      title: "Wind Analysis",
      description: "Set up and manage wind simulation parameters",
      path: `virtual-wind/${projectID}`,
      category: "Simulation",
    },
    {
      title: "Solar Panel Setup",
      description: "Configure PV panels and solar analysis settings",
      path: `pv-panel-setup/${projectID}`,
      category: "Energy",
    },
    {
      title: "Room Heat Mapping",
      description: "Set up thermal analysis and heat distribution",
      path: `room-new/${projectID}`,
      category: "Analysis",
    },
    {
      title: "File Setup",
      description: "Manage and upload project files",
      path: `pure-file-setup/${projectID}`,
      category: "Data",
    },
    {
      title: "Technical Data",
      description: "Configure technical specifications and parameters",
      path: `technical-data/${projectID}`,
      category: "Data",
    },
    {
      title: "CRREM Data",
      description: "Set up Carbon Risk Real Estate Monitor data",
      path: `crrem-data-setup/${projectID}`,
      category: "Sustainability",
    },
    {
      title: "Wind Risk Analysis",
      description: "Configure wind risk assessment parameters",
      path: `wind-risk/${projectID}`,
      category: "Risk Assessment",
    },
    {
      title: "Shading Analysis",
      description: "Set up shading progression analysis",
      path: `shading-progression/${projectID}`,
      category: "Analysis",
    },
    {
      title: "Energy Dashboard",
      description: "Configure energy monitoring dashboard",
      path: `energy-dashboard-setup/${projectID}`,
      category: "Energy",
    },
    {
      title: "Building Profile",
      description: "Update building profile information and files",
      path: `file-manager/${projectID}`,
      category: "Setup",
    },
  ];

  useEffect(() => {
    const fetchFeatureStatuses = async () => {
      setIsLoading(true);
      try {
        const apiRoot = process.env.REACT_APP_API_ROOT;
        const statuses = {};

        for (const feature of features) {
          try {
            const response = await fetch(
              `${apiRoot}/api/feature-status/${feature.path}`
            );
            const data = await response.json();
            statuses[feature.title] = {
              status: data.isConfigured ? "active" : "inactive",
              data: data.summary,
            };
          } catch {
            statuses[feature.title] = {
              status: "inactive",
              data: null,
            };
          }
        }
        setFeatureData(statuses);
      } catch (err) {
        setError("Failed to load feature statuses");
      } finally {
        setIsLoading(false);
      }
    };

    if (token && projectID) {
      fetchFeatureStatuses();
    }
  }, [token, projectID]);

  if (!token) {
    return (
      <MainLayout>
        <div className="flex justify-center items-center p-8">
          <div className="bg-red-50 text-red-700 px-4 py-3 rounded-md inline-block">
            Please log in to access this page
          </div>
        </div>
      </MainLayout>
    );
  }

  const categorizedFeatures = features.reduce((acc, feature) => {
    if (!acc[feature.category]) {
      acc[feature.category] = [];
    }
    acc[feature.category].push(feature);
    return acc;
  }, {});

  return (
    <MainLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Project Configuration
          </h1>
          <p className="text-gray-600">
            Configure and manage your project settings and features
          </p>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 px-4 py-3 rounded-md mb-6">
            {error}
          </div>
        )}

        {Object.entries(categorizedFeatures).map(
          ([category, categoryFeatures]) => (
            <div key={category} className="mb-8">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                {category}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {categoryFeatures.map((feature) => (
                  <FeatureCard
                    key={feature.path}
                    title={feature.title}
                    description={feature.description}
                    onClick={() => navigate(`/${feature.path}`)}
                    status={featureData[feature.title]?.status}
                    data={featureData[feature.title]?.data}
                    isLoading={isLoading}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </MainLayout>
  );
};

export default ProjectEdit;
