import React, { useState, useEffect, useCallback } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import {
  Card,
  Button,
  Alert,
  FormGroup,
  FormInput,
} from "./ui/base-components";

const AssignToProject = () => {
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchType, setSearchType] = useState("name");
  const [message, setMessage] = useState({ type: "", text: "" });

  const [projectSearchQuery, setProjectSearchQuery] = useState("");
  const [userSearchQuery, setUserSearchQuery] = useState("");

  const [selectedProjectsText, setSelectedProjectsText] = useState("");
  const [selectedUsersText, setSelectedUsersText] = useState("");

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const selectedProjectLabels = projects
      .filter((project) => selectedProjects.includes(project.value))
      .map((project) => project.label)
      .join(", ");
    setSelectedProjectsText(selectedProjectLabels);
  }, [selectedProjects, projects]);

  useEffect(() => {
    const selectedUserLabels = users
      .filter((user) => selectedUsers.includes(user.value))
      .map((user) => user.label)
      .join(", ");
    setSelectedUsersText(selectedUserLabels);
  }, [selectedUsers, users]);

  // Fetch Users
  const fetchUsers = useCallback(
    async (query = "") => {
      try {
        const response = await fetch(`${apiRoot}/api/user?search=${query}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        const formattedUsers = data.map((user) => ({
          value: user.id.toString(),
          label: user.email,
        }));
        setUsers(formattedUsers);
        setFilteredUsers(formattedUsers);
      } catch (error) {
        setMessage({ type: "error", text: "Error fetching users" });
      }
    },
    [apiRoot]
  );

  const fetchProjects = useCallback(
    async (query = "") => {
      try {
        const response = await fetch(
          `${apiRootSecond}/api/projects/?search=${query}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        const formattedProjects = data.map((project) => ({
          value: project.id.toString(),
          label: searchType === "id" ? project.id.toString() : project.name,
        }));
        setProjects(formattedProjects);
        setFilteredProjects(formattedProjects);
      } catch (error) {
        setMessage({ type: "error", text: "Error fetching projects" });
      }
    },
    [apiRootSecond, searchType]
  );

  const handleProjectSelection = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedProjects((prevSelected) => {
      const newSelection = selectedValues.filter(
        (value) => !prevSelected.includes(value)
      );
      return [...new Set([...prevSelected, ...newSelection])];
    });
  };

  const handleUserSelection = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedUsers((prevSelected) => {
      const newSelection = selectedValues.filter(
        (value) => !prevSelected.includes(value)
      );
      return [...new Set([...prevSelected, ...newSelection])];
    });
  };

  const handleProjectSearch = (e) => {
    const query = e.target.value;
    setProjectSearchQuery(query);

    const filtered = projects.filter((project) =>
      project.label.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  const handleUserSearch = (e) => {
    const query = e.target.value;
    setUserSearchQuery(query);

    const filtered = users.filter((user) =>
      user.label.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    fetchProjects();
  };

  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, [fetchUsers, fetchProjects]);

  const handleAssign = async () => {
    if (!selectedUsers.length || !selectedProjects.length) {
      setMessage({
        type: "error",
        text: "Please select both users and projects",
      });
      return;
    }

    try {
      const response = await fetch(`${apiRootSecond}/api/project_assignment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(
          selectedUsers.flatMap((userId) =>
            selectedProjects.map((projectId) => ({
              user: parseInt(userId),
              project: parseInt(projectId),
            }))
          )
        ),
      });

      if (!response.ok) throw new Error("Failed to assign projects");

      setMessage({
        type: "success",
        text: "Users successfully assigned to projects",
      });
      setSelectedUsers([]);
      setSelectedProjects([]);
      setProjectSearchQuery("");
      setUserSearchQuery("");
      setSelectedProjectsText("");
      setSelectedUsersText("");
    } catch (error) {
      setMessage({ type: "error", text: "Failed to assign users to projects" });
    }
  };

  return (
    <MainLayout>
      <div className="p-6 mt-4">
        <div className="max-w-lg mx-auto">
          <Card>
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  Assign Users to Projects
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Select users and projects to create assignments.
                </p>
              </div>

              {message.text && (
                <Alert type={message.type} message={message.text} />
              )}

              <FormGroup>
                {/* Search Type Selection */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Search Projects By
                  </label>
                  <div className="flex space-x-4">
                    {["id", "name"].map((type) => (
                      <label key={type} className="inline-flex items-center">
                        <input
                          type="radio"
                          className="form-radio h-4 w-4 text-blue-600"
                          checked={searchType === type}
                          onChange={() => handleSearchTypeChange(type)}
                        />
                        <span className="ml-2 text-gray-700 capitalize">
                          {type === "id" ? "Project ID" : "Project Name"}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="space-y-2">
                    <FormInput
                      label="Search Projects"
                      type="text"
                      placeholder="Type to search projects"
                      value={projectSearchQuery}
                      onChange={handleProjectSearch}
                    />
                    {selectedProjectsText && (
                      <div className="text-sm text-gray-600 border rounded p-2 bg-gray-50">
                        Selected: {selectedProjectsText}
                      </div>
                    )}
                  </div>
                  <select
                    multiple
                    value={selectedProjects}
                    onChange={handleProjectSelection}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      min-h-[120px] bg-white"
                  >
                    {filteredProjects.map((project) => (
                      <option
                        key={project.value}
                        value={project.value}
                        className={`py-1 px-2 ${
                          selectedProjects.includes(project.value)
                            ? "bg-blue-100"
                            : ""
                        }`}
                      >
                        {project.label}
                      </option>
                    ))}
                  </select>
                  <p className="text-sm text-gray-500">
                    Hold Ctrl (Cmd on Mac) to select multiple projects
                  </p>
                </div>

                <div className="space-y-2">
                  <div className="space-y-2">
                    <FormInput
                      label="Search Users"
                      type="text"
                      placeholder="Type to search users"
                      value={userSearchQuery}
                      onChange={handleUserSearch}
                    />
                    {selectedUsersText && (
                      <div className="text-sm text-gray-600 border rounded p-2 bg-gray-50">
                        Selected: {selectedUsersText}
                      </div>
                    )}
                  </div>
                  <select
                    multiple
                    value={selectedUsers}
                    onChange={handleUserSelection}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm 
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      min-h-[120px] bg-white"
                  >
                    {filteredUsers.map((user) => (
                      <option
                        key={user.value}
                        value={user.value}
                        className={`py-1 px-2 ${
                          selectedUsers.includes(user.value)
                            ? "bg-blue-100"
                            : ""
                        }`}
                      >
                        {user.label}
                      </option>
                    ))}
                  </select>
                  <p className="text-sm text-gray-500">
                    Hold Ctrl (Cmd on Mac) to select multiple users
                  </p>
                </div>
              </FormGroup>

              <Button onClick={handleAssign} className="w-full">
                Assign Users to Projects
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
};

export default AssignToProject;
