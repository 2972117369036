import React from "react";
import { useNavigate } from "react-router-dom";

export const SideNav = () => {
  const navigate = useNavigate();
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        navigate("/login");
        return;
      }

      const response = await fetch(`${apiRoot}/api/oauth/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      // Clear auth data
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userId");

      // Navigate to login
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const navItems = [
    { href: "/notification", label: "Notification" },
    { href: "/user-management", label: "User Management" },
    { href: "/project-management", label: "Project Management" },
    { href: "/features", label: "Home Page" },
    { href: "/activity-monitor", label: "Monitor Activity" },

    {
      href: "#",
      label: isLoggingOut ? "Signing out..." : "Sign out",
      onClick: handleLogout,
    },
  ];

  return (
    <nav className="fixed left-0 top-16 bottom-0 w-64 bg-gray-800 text-white p-4">
      <div className="space-y-2">
        {navItems.map((item) => (
          <a
            key={item.href}
            href={item.href}
            onClick={(e) => {
              if (item.onClick) {
                e.preventDefault();
                item.onClick();
              }
            }}
            className={`block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded transition-colors ${
              isLoggingOut && item.label.includes("Sign")
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            {item.label}
          </a>
        ))}
      </div>
    </nav>
  );
};

export default SideNav;
