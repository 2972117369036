import React from "react";
import { Loader } from "lucide-react";

export const Toast = ({ message, type, onClose }) => {
  return (
    <div className="fixed top-4 right-4 z-50">
      <div
        className={`rounded-lg px-4 py-3 shadow-lg max-w-md ${
          type === "success"
            ? "bg-green-50 border border-green-200"
            : type === "error"
            ? "bg-red-50 border border-red-200"
            : "bg-blue-50 border border-blue-200"
        }`}
      >
        <div className="flex items-center space-x-3">
          {type === "loading" && (
            <Loader className="h-5 w-5 animate-spin text-blue-500" />
          )}
          <p
            className={`text-sm font-medium ${
              type === "success"
                ? "text-green-800"
                : type === "error"
                ? "text-red-800"
                : "text-blue-800"
            }`}
          >
            {message}
          </p>
          {type !== "loading" && (
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              ×
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
