import React from "react";
import { Camera } from "lucide-react";

export const ImageUpload = ({
  imagePreview,
  onImageChange,
  className = "",
}) => (
  <div
    className={`relative h-48 bg-gray-100 rounded-lg border-2 border-dashed 
    border-gray-300 hover:border-gray-400 transition-colors ${className}`}
  >
    {imagePreview ? (
      <img
        src={
          typeof imagePreview === "string"
            ? imagePreview
            : URL.createObjectURL(imagePreview)
        }
        alt="Preview"
        className="w-full h-full object-cover rounded-lg"
      />
    ) : (
      <div className="flex flex-col items-center justify-center h-full">
        <Camera className="w-8 h-8 text-gray-400 mb-2" />
        <p className="text-sm text-gray-500">Click to upload image</p>
      </div>
    )}
    <input
      type="file"
      accept="image/*"
      onChange={onImageChange}
      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
    />
  </div>
);
