// import { create } from "zustand";

// const useActivityStore = create((set) => ({
//   activities: [],
//   loading: false,
//   error: null,

//   addActivity: (activity) =>
//     set((state) => ({
//       activities: [activity, ...state.activities].slice(0, 30),
//     })),

//   setActivities: (activities) => set({ activities }),
//   setLoading: (loading) => set({ loading }),
//   setError: (error) => set({ error }),

//   fetchActivities: async () => {
//     const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//     const token = localStorage.getItem("token");
//     const userId = localStorage.getItem("userID");
//     const projectID = localStorage.getItem("projectID");

//     set({ loading: true, error: null });

//     try {
//       const response = await fetch(
//         `${apiRoot}/api/activities?project_id=${projectID}&user_id=${userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!response.ok) throw new Error("Failed to fetch activities");

//       const data = await response.json();
//       const sortedData = data.sort(
//         (a, b) => new Date(b.datetime) - new Date(a.datetime)
//       );
//       set({ activities: sortedData, loading: false });
//     } catch (error) {
//       set({ error: error.message, loading: false });
//     }
//   },

//   createActivity: async (activityData) => {
//     const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//     const token = localStorage.getItem("token");

//     try {
//       const response = await fetch(`${apiRoot}/api/activities`, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(activityData),
//       });

//       if (!response.ok) throw new Error("Failed to create activity");

//       const newActivity = await response.json();
//       set((state) => ({
//         activities: [newActivity, ...state.activities].slice(0, 30),
//       }));

//       return newActivity;
//     } catch (error) {
//       set({ error: error.message });
//       throw error;
//     }
//   },
// }));

// export default useActivityStore;
import { create } from "zustand";

const useActivityStore = create((set) => ({
  activities: [],
  loading: false,
  error: null,

  addActivity: (activity) =>
    set((state) => ({
      activities: [activity, ...state.activities].slice(0, 30),
    })),

  setActivities: (activities) => set({ activities }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  fetchActivities: async () => {
    const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userID");
    const projectID = localStorage.getItem("projectID");

    set({ loading: true, error: null });

    try {
      const response = await fetch(
        `${apiRoot}/api/activities?project_id=${projectID}&user_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch activities");

      const data = await response.json();
      const sortedData = data
        ? data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        : [];
      set({ activities: sortedData, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  fetchActivitiesByRole: async () => {
    const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userID");

    set({ loading: true, error: null });

    try {
      const response = await fetch(
        `${apiRoot}/api/activities/by-role/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch activities");

      const data = await response.json();
      const sortedData = data
        ? data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        : [];
      set({ activities: sortedData, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  createActivity: async (activityData) => {
    const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
    const token = localStorage.getItem("token");
    const userId = parseInt(localStorage.getItem("userID"), 10);

    try {
      const response = await fetch(`${apiRoot}/api/activities`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...activityData,
          user_id: userId, // Ensure user_id is sent as a number
        }),
      });

      if (!response.ok) throw new Error("Failed to create activity");

      const newActivity = await response.json();
      set((state) => ({
        activities: [newActivity, ...state.activities].slice(0, 30),
      }));

      return newActivity;
    } catch (error) {
      set({ error: error.message });
      throw error;
    }
  },
}));

export default useActivityStore;
