// import React from "react";
// import { Link } from "react-router-dom";
// import "./UserProject.css";

// const LeftNavbar = () => {
//   return (
//     <div className="left-navbar">
//       <h3>Site Menu</h3>
//       <ul>
//         <li>
//           <Link to="/">Home</Link>
//         </li>
//         <li>
//           <Link to="/features">Notification</Link>
//         </li>
//         <li>
//           <Link to="/user-management">User Management</Link>
//         </li>
//         <li>
//           <Link to="/project-management">Project Management</Link>
//         </li>
//       </ul>
//     </div>
//   );
// };
// const WithNavbar = ({ children }) => {
//   return (
//     <div>
//       <LeftNavbar />
//       <div
//         className="main-content"
//         style={{
//           marginLeft: "10vw",
//           marginTop: "10vh",
//           padding: "20px",
//         }}
//       >
//         {children}
//       </div>
//     </div>
//   );
// };

// export default WithNavbar;
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./LeftNavbar.css";

const LeftNavbar = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "nav-item active" : "nav-item";
  };

  return (
    <nav className="left-navbar">
      <div className="nav-content">
        <ul className="nav-list">
          <li className={isActive("/notification")}>
            {/* <Link to="/notification">
              <span className="nav-text">Notification</span>
            </Link> */}
          </li>
          <li className={isActive("/user-management")}>
            <Link to="/user-management">
              <span className="nav-text">User Management</span>
            </Link>
          </li>
          <li className={isActive("/project-management")}>
            <Link to="/project-management">
              <span className="nav-text">Project Management</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const WithNavbar = ({ children }) => {
  return (
    <div className="layout-container">
      <LeftNavbar />
      <main className="main-content">{children}</main>
    </div>
  );
};

export default WithNavbar;
