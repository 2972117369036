import { MainLayout } from "../components/layout/MainLayout";
import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  FormInput,
  Button,
  Alert,
  FormGroup,
} from "./ui/base-components";

const ResetPassword = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    selectedUserId: "",
    newPassword: "",
    confirmPassword: "",
    userSearchQuery: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch Users
  const fetchUsers = useCallback(
    async (query = "") => {
      try {
        if (query.length < 3) {
          setUsers([]);
          return;
        }

        const response = await fetch(`${apiRoot}/api/user?search=${query}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        const formattedUsers = data.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setUsers(formattedUsers);
      } catch (error) {
        setMessage({ type: "error", text: "Error searching users" });
      }
    },
    [apiRoot]
  );

  // Debounced search function
  const debouncedUserSearch = useCallback(
    debounce((query) => {
      if (query.length >= 3) {
        fetchUsers(query);
      } else {
        setUsers([]);
      }
    }, 300),
    [fetchUsers]
  );

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => {
      // If it's the user search query, trigger search
      if (id === "userSearchQuery") {
        debouncedUserSearch(value);
      }

      return {
        ...prev,
        [id]: value,
        // Reset selected user if search query changes
        ...(id === "userSearchQuery" && { selectedUserId: "" }),
      };
    });
  };

  // Handle user selection
  const handleUserSelect = (userId) => {
    setFormData((prev) => ({
      ...prev,
      selectedUserId: userId,
      userSearchQuery: users.find((user) => user.value === userId)?.label || "",
    }));
    setUsers([]); // Clear search results after selection
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: "", text: "" });

    if (!formData.selectedUserId) {
      setMessage({ type: "error", text: "Please select a user" });
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setMessage({ type: "error", text: "Passwords do not match" });
      return;
    }

    try {
      const formPayload = new FormData();
      formPayload.append("newPassword", formData.newPassword);
      formPayload.append("confirmPassword", formData.confirmPassword);

      const response = await fetch(
        `${apiRoot}/api/user/${formData.selectedUserId}/reset-password`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formPayload,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to reset password");
      }

      setMessage({ type: "success", text: "Password reset successfully!" });
      setFormData({
        selectedUserId: "",
        newPassword: "",
        confirmPassword: "",
        userSearchQuery: "",
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: error.message || "Failed to reset password",
      });
    }
  };

  return (
    <MainLayout>
      <div className="p-6 mt-4">
        <div className="max-w-md mx-auto">
          <Card>
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  Reset Password
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Change the password for a selected user.
                </p>
              </div>

              {message.text && (
                <Alert type={message.type} message={message.text} />
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <FormGroup>
                  <div className="space-y-2">
                    <FormInput
                      id="userSearchQuery"
                      label="Search User"
                      type="text"
                      placeholder="Type at least 3 characters to search"
                      value={formData.userSearchQuery}
                      onChange={handleChange}
                    />

                    {users.length > 0 && (
                      <div className="border border-gray-300 rounded-md shadow-sm max-h-48 overflow-y-auto">
                        {users.map((user) => (
                          <div
                            key={user.value}
                            onClick={() => handleUserSelect(user.value)}
                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer 
                              transition-colors duration-200 
                              border-b border-gray-200 last:border-b-0"
                          >
                            {user.label}
                          </div>
                        ))}
                      </div>
                    )}

                    {formData.selectedUserId && (
                      <div className="mt-2 text-sm text-gray-700">
                        Selected User: {formData.userSearchQuery}
                      </div>
                    )}
                  </div>

                  <FormInput
                    id="newPassword"
                    label="New Password"
                    type="password"
                    value={formData.newPassword}
                    onChange={handleChange}
                    required
                  />

                  <FormInput
                    id="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <Button
                  type="submit"
                  className="w-full"
                  disabled={!formData.selectedUserId}
                >
                  Reset Password
                </Button>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
};

export default ResetPassword;
