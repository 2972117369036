import React, { useState } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { UploadCloud, Loader, AlertCircle, CheckCircle } from "lucide-react";

const WindRiskComponent = () => {
  const [fileData, setFileData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState("No file chosen");

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileName(file.name);
      setFileData(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fileData) {
      setSubmitStatus("error");
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const formData = new FormData();
      formData.append("file", fileData);

      const response = await fetch(
        `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=windrisk`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();

      if (data.status === "processing") {
        setSubmitStatus("success");
        setFileData(null);
        setFileName("No file chosen");
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Header */}
            <div className="px-6 py-8 border-b border-gray-200">
              <h1 className="text-2xl font-bold text-gray-900 text-center">
                Wind Risk Analysis Setup
              </h1>
              <p className="mt-2 text-center text-gray-600">
                Configure wind risk analysis settings by uploading your data
                file
              </p>
            </div>

            {/* Form Content */}
            <div className="p-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* File Upload Area */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Wind Risk Data File
                  </label>
                  <div
                    className={`relative border-2 border-dashed rounded-lg p-6 transition-all duration-200
                      ${
                        isDragging
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-300 hover:border-gray-400"
                      }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      accept=".zip"
                      onChange={handleFileSelect}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                    <div className="text-center">
                      <UploadCloud className="mx-auto h-12 w-12 text-gray-400" />
                      <p className="mt-2 text-sm text-gray-600">
                        Drag and drop your ZIP file here or click to browse
                      </p>
                      <p className="mt-1 text-xs text-gray-500">{fileName}</p>
                    </div>
                  </div>
                </div>

                {/* Status Messages */}
                {submitStatus === "success" && (
                  <div className="flex items-center gap-2 p-4 bg-green-50 border border-green-200 rounded-lg">
                    <CheckCircle className="h-5 w-5 text-green-500" />
                    <p className="text-sm text-green-700">
                      File uploaded successfully!
                    </p>
                  </div>
                )}

                {submitStatus === "error" && (
                  <div className="flex items-center gap-2 p-4 bg-red-50 border border-red-200 rounded-lg">
                    <AlertCircle className="h-5 w-5 text-red-500" />
                    <p className="text-sm text-red-700">
                      Error uploading file. Please try again.
                    </p>
                  </div>
                )}

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isSubmitting || !fileData}
                  className={`w-full px-4 py-3 flex items-center justify-center gap-2 text-sm font-medium rounded-lg
                    ${
                      isSubmitting || !fileData
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }
                    text-white transition-colors duration-200`}
                >
                  {isSubmitting && <Loader className="w-4 h-4 animate-spin" />}
                  {isSubmitting ? "Uploading..." : "Upload File"}
                </button>
              </form>

              {/* Information Section */}
              <div className="mt-8 bg-gray-50 rounded-lg p-6">
                <h2 className="text-sm font-medium text-gray-900 mb-4">
                  File Requirements
                </h2>
                <ul className="space-y-2">
                  {[
                    "Upload a ZIP file containing your wind risk analysis data",
                    "Ensure the file follows the required format and structure",
                    "Maximum file size: 50MB",
                  ].map((requirement, index) => (
                    <li
                      key={index}
                      className="flex items-start text-sm text-gray-600"
                    >
                      <span className="mr-2">•</span>
                      {requirement}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default WindRiskComponent;
