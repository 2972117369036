import React, { useState, useEffect } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { Trash2, AlertCircle, Loader2 } from "lucide-react";
import ProjectInfoService from "./services/ProjectInfoService";
import ProfileService from "./services/ProfileService";
import { FileUpload } from "./FileUpload";
import Toast from "./Toast";
import { ProjectInfoFields } from "./services/ProjectInfoService";

const EnergyConsumptionDashboard = () => {
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperantional = process.env.REACT_APP_API_ROOT_OPERATIONAL;

  const [scenarios, setScenarios] = useState([]);
  const [newScenario, setNewScenario] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);

  const profileService = new ProfileService(apiRoot, token);
  const projectInfoService = new ProjectInfoService(apiRoot, token);

  const showToast = (message, type = "success") => {
    setToast({ message, type });
  };

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${apiRoot}/api/project_info/?project_id=${projectID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch scenarios");
        }

        const data = await response.json();
        let scenarioList = data[0]?.room_level_scenarios;
        if (typeof scenarioList === "string") {
          scenarioList = JSON.parse(scenarioList);
        }
        setScenarios(Array.isArray(scenarioList) ? scenarioList : []);
      } catch (error) {
        console.error("Error fetching scenarios:", error);
        setError(error.message);
        showToast("Failed to fetch scenarios", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchScenarios();
  }, [apiRoot, projectID, token]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };
  const handleDeleteScenario = async (scenario) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete "${scenario}"?`
    );
    if (!confirmDelete) return;

    try {
      setIsProcessing(true);
      const response = await fetch(
        `${apiRootOperantional}/api/transient_response/scenario?project_id=${projectID}&scenario=${scenario}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete scenario");
      }
      const currentInfo = await projectInfoService.getProjectInfo(projectID);
      let roomLevelScenarios = projectInfoService.parseJsonField(
        currentInfo.room_level_scenarios
      );
      // let allScenarioList = projectInfoService.parseJsonField(
      //   currentInfo.all_scenario_list
      // );

      roomLevelScenarios = roomLevelScenarios.filter((s) => s !== scenario);
      // allScenarioList = allScenarioList.filter((s) => s !== scenario);

      await Promise.all([
        projectInfoService.updateProjectInfo(projectID, {
          [ProjectInfoFields.ROOM_LEVEL_SCENARIOS]:
            projectInfoService.stringifyJsonField(roomLevelScenarios),
          // [ProjectInfoFields.ALL_SCENARIO_LIST]:
          //   projectInfoService.stringifyJsonField(allScenarioList),
        }),
      ]);

      setScenarios(roomLevelScenarios);
      showToast(`Scenario "${scenario}" deleted successfully.`);
    } catch (error) {
      console.error("Error deleting scenario:", error);
      showToast(error.message || "Failed to delete scenario", "error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUploadScenario = async () => {
    if (!newScenario || !selectedFile) {
      showToast("Please enter a scenario name and select a file.", "error");
      return;
    }

    try {
      setIsProcessing(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("projectId", projectID);
      formData.append("scenario", newScenario);

      const response = await fetch(
        `${apiRootOperantional}/api/upload_dashboard_excel_file`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to upload scenario");
      }

      const data = await response.json();

      await Promise.all([
        projectInfoService.updateMetrics(projectID, [
          {
            level: "building",
            dataKey: "consumption",
          },
        ]),
        projectInfoService.updateListField(
          projectID,
          "room_level_scenarios",
          newScenario,
          { overwrite: false, unique: true }
        ),
        projectInfoService.updateListField(
          projectID,
          "room_level_scenarios",
          newScenario,
          { overwrite: false, unique: true }
        ),
        profileService.updateEnergyCO2(projectID, true),
      ]);

      setScenarios((prev) => [...new Set([...prev, newScenario])]);
      setNewScenario("");
      setSelectedFile(null);

      showToast(
        `Scenario "${newScenario}" uploaded successfully. ${
          data.records ? `${data.records} records processed.` : ""
        }`
      );
    } catch (error) {
      console.error("Error in scenario upload process:", error);
      showToast(error.message || "Error uploading scenario", "error");
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <MainLayout>
        <div className="flex items-center justify-center min-h-screen">
          <Loader2 className="h-12 w-12 animate-spin text-blue-600" />
        </div>
      </MainLayout>
    );
  }

  if (error) {
    return (
      <MainLayout>
        <div className="flex flex-col items-center justify-center min-h-screen">
          <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
          <p className="text-red-600">Error: {error}</p>
        </div>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Energy Consumption Scenarios
          </h1>
          <p className="mt-2 text-gray-600">
            Manage and analyze different energy consumption scenarios
          </p>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Manage Scenarios
              </h2>

              <div className="border rounded-lg overflow-hidden">
                {scenarios.length > 0 ? (
                  <ul className="divide-y divide-gray-200">
                    {scenarios.map((scenario, index) => (
                      <li key={index} className="p-4 hover:bg-gray-50">
                        <div className="flex items-center justify-between">
                          <span className="text-gray-900">{scenario}</span>
                          <button
                            onClick={() => handleDeleteScenario(scenario)}
                            disabled={isProcessing}
                            className="p-2 text-gray-400 hover:text-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {isProcessing ? (
                              <Loader2 className="h-4 w-4 animate-spin" />
                            ) : (
                              <Trash2 className="h-4 w-4" />
                            )}
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="p-4 text-center text-gray-500">
                    No scenarios available.
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Upload New Scenario
              </h2>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="scenario-name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Scenario Name
                  </label>
                  <input
                    id="scenario-name"
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter scenario name"
                    value={newScenario}
                    onChange={(e) => setNewScenario(e.target.value)}
                  />
                </div>
                <FileUpload
                  onFileSelect={handleFileSelect}
                  acceptedFileTypes={null}
                  acceptedExtensions=".xlsx,.xls"
                  label="Excel File"
                  description="Drag and drop your Excel file here or click to browse"
                />

                <button
                  onClick={handleUploadScenario}
                  disabled={!selectedFile || !newScenario || isProcessing}
                  className={`w-full px-4 py-2 text-sm font-medium rounded-md
                    ${
                      !selectedFile || !newScenario || isProcessing
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-600 hover:bg-blue-700"
                    }
                    text-white transition-colors duration-200 flex items-center justify-center`}
                >
                  {isProcessing && (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  )}
                  {isProcessing ? "Processing..." : "Upload Scenario"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default EnergyConsumptionDashboard;
