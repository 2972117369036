import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import CustomFileUpload from "./CustomFileUpload";

const RoomFileUpload = ({
  heading1,
  heading2,
  fileUploadComponents,
  apiContextArg,
  apiText,
  noWindows,
}) => {
  const [activeSection, setActiveSection] = useState("");
  const [windowsData, setWindowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [file, setFile] = useState(null);

  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const apiRootSecond = process.env.REACT_APP_API_ROOT;

  useEffect(() => {
    const fetchData = async () => {
      if (noWindows) {
        try {
          setIsLoading(true);
          const response = await fetch(
            `${apiRoot}/api/rooms?projectId=${projectID}&currentPage=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setWindowsData(data.list || []);
          setTotalPages(data.pages || 1);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching rooms data:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [noWindows, projectID, token, currentPage, apiRoot]);

  const handleFileSelect = (selectedFile) => {
    setFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadUrl = `${apiRoot}/api/rooms/upload_excel?projectId=${projectID}`;

      const res = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await res.json();

      if (res.ok) {
        toast.success("File uploaded successfully!");
        setFile(null);
      } else {
        toast.error(`Error: ${data.message || "Failed to upload file."}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file. Please try again.");
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="px-6 py-8 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900">{heading2}</h2>
        </div>

        <div className="p-6">
          {isLoading ? (
            <div className="text-center py-4">Loading Rooms...</div>
          ) : (
            <>
              {/* Available Rooms Section */}
              <div className="mb-8 border-2 border-dashed border-gray-300 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Available Rooms</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Area (m²)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {windowsData.map((item) => (
                        <tr key={item.room.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.room.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {item.room.area ||
                              item.room.volume ||
                              item.room.occupancy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Pagination */}
                <div className="flex items-center justify-center mt-4 space-x-2">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="px-4 py-2 text-sm font-medium rounded-md
                      disabled:bg-gray-100 disabled:text-gray-400
                      enabled:bg-blue-600 enabled:text-white enabled:hover:bg-blue-700"
                  >
                    ← Previous
                  </button>
                  <span className="text-sm text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 text-sm font-medium rounded-md
                      disabled:bg-gray-100 disabled:text-gray-400
                      enabled:bg-blue-600 enabled:text-white enabled:hover:bg-blue-700"
                  >
                    Next →
                  </button>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex justify-center space-x-4 mb-8">
                <button
                  onClick={() => setActiveSection("insert")}
                  className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Insert New Rooms
                </button>
                <button
                  onClick={() => setActiveSection("copy")}
                  className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Copy Rooms from Another Project
                </button>
              </div>

              {/* File Upload Section */}
              {activeSection === "insert" && (
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
                  <h3 className="text-lg font-semibold mb-4">
                    Insert New Rooms
                  </h3>
                  <CustomFileUpload
                    label="Select Room Data File"
                    onFileSelect={handleFileSelect}
                  />
                  <button
                    onClick={handleSubmit}
                    className="mt-4 w-full px-4 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Upload Rooms
                  </button>
                </div>
              )}

              <ToastContainer position="top-right" autoClose={5000} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomFileUpload;
