import React, { useState } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import { Calendar, Clock, Loader2 } from "lucide-react";
import { FileUpload } from "./FileUpload";
import ProjectInfoService from "./services/ProjectInfoService";
import Toast from "./Toast";

const LoadingSpinner = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center space-y-4">
      <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
      <p className="text-gray-700 font-medium">Uploading your file...</p>
    </div>
  </div>
);

const CustomDatePicker = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <div className="relative">
      <div
        className="relative rounded-md shadow-sm cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Calendar className="h-5 w-5 text-blue-500" />
        </div>
        <div className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md bg-white">
          {value ? formatDate(value) : "Select a date"}
        </div>
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full">
          <input
            type="date"
            value={value}
            onChange={(e) => {
              onChange(e);
              setIsOpen(false);
            }}
            className="absolute w-full p-2 border border-gray-300 rounded-md shadow-lg bg-white z-10"
          />
        </div>
      )}
    </div>
  );
};

const ShadingProgressionUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [interval, setInterval] = useState("");
  const [date, setDate] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState(null);

  const projectID = localStorage.getItem("projectID");
  const token = localStorage.getItem("token");
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperantional = process.env.REACT_APP_API_ROOT_OPERATIONAL;

  const projectInfoService = new ProjectInfoService(apiRoot, token);

  const intervalOptions = [5, 10, 15, 30, 60];

  const showToast = (message, type = "success") => {
    setToast({ message, type });
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (!selectedFile || !interval || !date) {
      showToast(
        "Please enter an interval, select a date, and choose a file.",
        "error"
      );
      return;
    }

    setIsProcessing(true);

    try {
      const formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("interval", interval);
      formData.append("file", selectedFile);
      formData.append("date", date);

      const response = await fetch(
        `${apiRootOperantional}/api/shading_progression`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to upload shading progression data"
        );
      }

      await projectInfoService.updateShadingProgression(projectID, {
        date: date,
        step: interval,
      });

      showToast("File uploaded successfully!");
      setSelectedFile(null);
      setInterval("");
      setDate("");

      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
      showToast(
        error.message || "Error uploading file. Please try again.",
        "error"
      );
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <MainLayout>
      {isProcessing && <LoadingSpinner />}
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="px-6 py-8 border-b border-gray-200">
              <h2 className="text-2xl font-bold text-gray-900 text-center">
                Upload Shading Progression Data
              </h2>
            </div>

            <div className="p-6 space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Interval (minutes)
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Clock className="h-5 w-5 text-blue-500" />
                  </div>
                  <select
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
                  >
                    <option value="">Select interval</option>
                    {intervalOptions.map((option) => (
                      <option key={option} value={option}>
                        {option} minutes
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Date
                </label>
                <CustomDatePicker
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>

              <FileUpload
                onFileSelect={handleFileSelect}
                acceptedFileTypes={[
                  "text/csv",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                ]}
                acceptedExtensions=".csv,.xlsx,.xls"
                label="Upload File"
                description="Drag and drop your file here or click to browse"
              />

              {toast && (
                <Toast
                  message={toast.message}
                  type={toast.type}
                  onClose={() => setToast(null)}
                />
              )}

              <button
                onClick={handleUpload}
                disabled={isProcessing}
                className={`w-full px-4 py-3 text-sm font-medium rounded-lg text-white
                  ${
                    isProcessing
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                  }
                  transition-colors duration-200`}
              >
                {isProcessing ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ShadingProgressionUploader;
