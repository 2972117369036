import React from "react";
import "./KMLSetup.css"; // Add your styles for the KML setup page

const KMLSetup = () => {
  return (
    <div className="kml-setup">
      <h1>KML Setup</h1>
      <p>Please follow the steps to set up your KML file:</p>
      <ol>
        <li>
          Watch the video below to learn how to create and correctly label your
          KML file on Google My Maps:
          <div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your video link
              title="KML Setup Tutorial"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </li>
        <li>
          Follow <a href="https://www.google.com/maps">this link</a> to Google
          My Maps, create your KML file, and download it.
        </li>
        <li>
          Upload your KML file using the form below:
          <form>
            <input type="file" accept=".kml" />
            <button type="submit">Next</button>
          </form>
        </li>
      </ol>
    </div>
  );
};

export default KMLSetup;
