import React, { useState } from "react";
import RoomFootprint from "./RoomFootprint";
import EnergyDataInsertion from "./EnergyDataInsertion";
import { MainLayout } from "../components/layout/MainLayout";

const CombinedComponent = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <MainLayout>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            {/* Options Container */}
            <div className="flex justify-center space-x-4 p-6 border-b border-gray-200">
              <button
                className={`px-6 py-3 rounded-lg font-medium transition-colors duration-200 
                  ${
                    selectedOption === "insertRoom"
                      ? "bg-blue-600 text-white shadow-md"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                onClick={() => handleOptionClick("insertRoom")}
              >
                Insert Room
              </button>
              <button
                className={`px-6 py-3 rounded-lg font-medium transition-colors duration-200 
                  ${
                    selectedOption === "insertEnergyData"
                      ? "bg-blue-600 text-white shadow-md"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                onClick={() => handleOptionClick("insertEnergyData")}
              >
                Insert Energy Data
              </button>
            </div>

            {/* Content Container */}
            <div className="p-6">
              <div className="bg-gray-50 rounded-lg min-h-[300px] p-6">
                {selectedOption === "insertRoom" && <RoomFootprint />}
                {selectedOption === "insertEnergyData" && (
                  <EnergyDataInsertion />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CombinedComponent;
