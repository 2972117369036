import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const fetchUtil = async (
  url,
  method,
  token,
  body = null,
  isFormData = false
) => {
  console.group(`API Request: ${method} ${url}`);
  console.log("Request Details:", {
    url,
    method,
    isFormData,
    body: body
      ? isFormData
        ? "FormData"
        : JSON.stringify(body, null, 2)
      : null,
  });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (!isFormData && method !== "GET") {
    headers["Content-Type"] = "application/json";
  }

  const fetchOptions = {
    method: method,
    headers: headers,
  };

  if (body && method !== "GET") {
    fetchOptions.body = isFormData ? body : JSON.stringify(body);
  }

  try {
    const response = await fetch(url, fetchOptions);

    console.log("Response Status:", response.status);

    if (!response.ok) {
      const contentType = response.headers.get("Content-Type") || "";

      let errorDetails = {};
      try {
        if (contentType.includes("application/json")) {
          errorDetails = await response.json();
        } else {
          errorDetails.message = await response.text();
        }
      } catch (parseError) {
        errorDetails.message = "Could not parse error response";
      }

      console.error("Error Response:", errorDetails);
      console.groupEnd();

      const errorMessage = errorDetails.message || "Error with API call";
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }

    const contentType = response.headers.get("Content-Type") || "";

    let responseData;
    if (contentType.includes("application/json")) {
      responseData = await response.json();
      console.log("Response Data:", responseData);
    } else {
      responseData = await response.text();
      console.log("Response Text:", responseData);
    }

    console.groupEnd();
    return responseData;
  } catch (error) {
    console.error("Fetch Error:", error);
    console.groupEnd();

    toast.error(
      error.message || "An error occurred while processing your request."
    );
    throw error;
  }
};

export default fetchUtil;
