import React, { useState, useCallback } from "react";
import { MainLayout } from "../components/layout/MainLayout";
import {
  Card,
  FormInput,
  Button,
  Alert,
  FormGroup,
} from "./ui/base-components";

const DeleteUserComponent = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [formData, setFormData] = useState({
    userSearchQuery: "",
    selectedUserId: "",
    selectedUserEmail: "",
  });
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({ type: "", text: "" });

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Fetch Users
  const fetchUsers = useCallback(
    async (query = "") => {
      try {
        if (query.length < 3) {
          setUsers([]);
          return;
        }

        const response = await fetch(`${apiRoot}/api/user?search=${query}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        const formattedUsers = data.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setUsers(formattedUsers);
      } catch (error) {
        setMessage({ type: "error", text: "Error searching users" });
      }
    },
    [apiRoot]
  );

  // Debounced search function
  const debouncedUserSearch = useCallback(
    debounce((query) => {
      if (query.length >= 3) {
        fetchUsers(query);
      } else {
        setUsers([]);
      }
    }, 300),
    [fetchUsers]
  );

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => {
      // If it's the user search query, trigger search
      if (id === "userSearchQuery") {
        debouncedUserSearch(value);
      }

      return {
        ...prev,
        [id]: value,
        // Reset selected user if search query changes
        ...(id === "userSearchQuery" && {
          selectedUserId: "",
          selectedUserEmail: "",
        }),
      };
    });
  };

  // Handle user selection
  const handleUserSelect = (userId, userEmail) => {
    setFormData((prev) => ({
      ...prev,
      selectedUserId: userId,
      selectedUserEmail: userEmail,
      userSearchQuery: userEmail,
    }));
    setUsers([]); // Clear search results after selection
  };

  // Handle Delete
  const handleDelete = async () => {
    // Confirm deletion
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the user with email ${formData.selectedUserEmail}?`
    );

    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${apiRoot}/user/delete/?email=${encodeURIComponent(
          formData.selectedUserEmail
        )}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        setMessage({
          type: "success",
          text: `User with email ${formData.selectedUserEmail} has been deleted successfully.`,
        });

        // Reset form
        setFormData({
          userSearchQuery: "",
          selectedUserId: "",
          selectedUserEmail: "",
        });
      } else {
        const errorData = await response.json();
        setMessage({
          type: "error",
          text: errorData.error || "Failed to delete the user",
        });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: "An error occurred while deleting the user",
      });
    }
  };

  return (
    <MainLayout>
      <div className="p-6 mt-4">
        <div className="max-w-md mx-auto">
          <Card>
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  Delete User
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Search and delete a user from the system.
                </p>
              </div>

              {message.text && (
                <Alert type={message.type} message={message.text} />
              )}

              <FormGroup>
                <div className="space-y-2">
                  <FormInput
                    id="userSearchQuery"
                    label="Search User"
                    type="text"
                    placeholder="Type at least 3 characters to search"
                    value={formData.userSearchQuery}
                    onChange={handleChange}
                  />

                  {users.length > 0 && (
                    <div className="border border-gray-300 rounded-md shadow-sm max-h-48 overflow-y-auto">
                      {users.map((user) => (
                        <div
                          key={user.value}
                          onClick={() =>
                            handleUserSelect(user.value, user.label)
                          }
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer 
                            transition-colors duration-200 
                            border-b border-gray-200 last:border-b-0"
                        >
                          {user.label}
                        </div>
                      ))}
                    </div>
                  )}

                  {formData.selectedUserEmail && (
                    <div className="mt-2 text-sm text-gray-700">
                      Selected User: {formData.selectedUserEmail}
                    </div>
                  )}
                </div>
              </FormGroup>

              <Button
                variant="danger"
                className="w-full"
                onClick={handleDelete}
                disabled={!formData.selectedUserId}
              >
                Delete User
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </MainLayout>
  );
};

export default DeleteUserComponent;
