import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import { TabButton } from "./ui/TabButton";
import { ImageUpload } from "./ui/ImageUpload";
import { ActionButton } from "./ui/ActionButton";
import { FormInput } from "./ui/FormInput";
import fetchUtil from "./utils/fetchUtil";

const BUILDING_TYPES = {
  official: "Official Building",
  commercial: "Commercial Building",
  public: "Public Building",
  retail_high_street: "Retail High Street",
  retail_shopping_center: "Retail Shopping Center",
  retail_warehouse: "Retail Warehouse",
  industrial_distribution_warehouse_cooled:
    "Industrial Distribution Warehouse Cooled",
  industrial_distribution_warehouse: "Industrial Distribution Warehouse",
  hotel: "Hotel",
  healthcare: "Healthcare",
  lodging_leisure_recreation: "Lodging Leisure Recreation",
  residential: "Residential",
  mixed_use: "Mixed Use",
};

const DigitalTwinViewerInsertion = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("Project");
  const [projectId, setProjectId] = useState(null);
  const [projectImage, setProjectImage] = useState(null);

  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");

  const fieldTypes = {
    integerFields: [
      "buildingTypeId",
      "constructionYear",
      "lastModernized",
      "rentalUnits",
      "floorAmount",
      "postCode",
    ],
    floatFields: [
      "latitude",
      "longitude",
      "totalArea",
      "grossAssetSize",
      "energyConsumption",
      "heatEnergyConsumption",
      "totalHeatEnergyConsumption",
      "electricityDemandKwhPerAnnum",
      "cashIn",
      "cashOut",
      "estimatedValue",
      "grossAssetValue",
      "renovationBudget",
    ],
  };

  const fieldCategories = {
    Project: {
      name: "",
      descriptiveName: "",
      type: "office",
      portfolio: "",
      assetID: "",
      buildingType: "",
      weatherApi: "off",
    },
    Location: {
      latitude: "",
      longitude: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      street: "",
      address: "",
    },
    Building: {
      buildingTypeId: "",
      constructionYear: "",
      lastModernized: "",
      totalArea: "",
      rentalUnits: "",
      floorAmount: "",
      grossAssetSize: "",
    },
    Energy: {
      energyLabel: "",
      energyConsumption: "",
      heatEnergyConsumption: "",
      totalHeatEnergyConsumption: "",
      districtHeatingAvailable: "",
      electricityDemandKwhPerAnnum: "",
    },
    Financial: {
      cashIn: "",
      cashOut: "",
      estimatedValue: "",
      grossAssetValue: "",
      renovationBudget: "",
    },
  };

  const [projectDetails, setProjectDetails] = useState(fieldCategories);

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails((prev) => ({
      ...prev,
      [activeTab]: { ...prev[activeTab], [name]: value },
    }));
  };

  const prepareDataForSubmission = (data) => {
    const preparedData = {};

    Object.entries(data).forEach(([key, value]) => {
      if (value === "" || value === undefined || value === null) {
        return;
      }

      if (fieldTypes.integerFields.includes(key)) {
        preparedData[key] = parseInt(value, 10);
        return;
      }

      if (fieldTypes.floatFields.includes(key)) {
        preparedData[key] = parseFloat(value);
        return;
      }

      preparedData[key] = value;
    });

    return preparedData;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const preparedData = prepareDataForSubmission(
        projectDetails[activeTab],
        activeTab
      );

      if (!projectId) {
        const projectData = await fetchUtil(
          `${apiRootOperational}/api/projects`,
          "POST",
          token,
          {
            ...preparedData,
            customerId: parseInt(userID, 10),
          }
        );
        setProjectId(projectData.id);
      } else {
        await fetchUtil(
          `${apiRootOperational}/api/projects/${projectId}`,
          "PUT",
          token,
          preparedData
        );
      }

      if (projectImage && projectId) {
        const formData = new FormData();
        formData.append("image_file", projectImage);
        formData.append("project", projectId);
        await fetchUtil(
          `${apiRoot}/api/images/`,
          "POST",
          token,
          formData,
          true
        );
      }

      const tabs = Object.keys(fieldCategories);
      if (activeTab === tabs[tabs.length - 1]) {
        navigate("/user-projects");
      } else {
        const nextTabIndex = tabs.indexOf(activeTab) + 1;
        setActiveTab(tabs[nextTabIndex]);
      }
    } catch (err) {
      setError("Failed to save project. Please try again.");
      console.error("API Error:", err);
    } finally {
      setIsLoading(false);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProjectImage(file);
  };

  const getInputType = (fieldName) => {
    if (fieldTypes.integerFields.includes(fieldName)) return "number";
    if (fieldTypes.floatFields.includes(fieldName)) return "number";
    return "text";
  };

  const renderField = (field, value) => {
    if (field === "weatherApi") {
      return (
        <div key={field} className="flex flex-col space-y-2">
          <label htmlFor={field} className="text-sm font-medium text-gray-700">
            Weather API
          </label>
          <select
            id={field}
            name={field}
            value={value}
            onChange={handleDetailChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="off">Off</option>
            <option value="on">On</option>
          </select>
        </div>
      );
    }

    if (field === "buildingType") {
      return (
        <div key={field} className="flex flex-col space-y-2">
          <label htmlFor={field} className="text-sm font-medium text-gray-700">
            Building Type
          </label>
          <select
            id={field}
            name={field}
            value={value}
            onChange={handleDetailChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="">Select a building type</option>
            {Object.entries(BUILDING_TYPES).map(([key, label]) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </div>
      );
    }

    return (
      <FormInput
        key={field}
        label={field.replace(/([A-Z])/g, " $1").trim()}
        name={field}
        value={value}
        onChange={handleDetailChange}
        type={getInputType(field)}
        step={fieldTypes.floatFields.includes(field) ? "0.01" : "1"}
      />
    );
  };

  return (
    <MainLayout>
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="bg-gray-100 px-6 py-4 border-b">
            <h1 className="text-2xl font-bold text-gray-800">
              Digital Twin Creation
            </h1>
            <p className="text-sm text-gray-600 mt-1">
              Configure your digital twin project settings
            </p>
          </div>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-4">
              <p className="text-red-700">{error}</p>
            </div>
          )}

          <div className="flex border-b">
            {Object.keys(fieldCategories).map((category, index) => (
              <TabButton
                key={category}
                active={activeTab === category}
                onClick={() => {
                  if (!projectId && category !== "Project") {
                    alert("Please create the project first");
                    return;
                  }
                  setActiveTab(category);
                }}
                step={index + 1}
              >
                {category}
              </TabButton>
            ))}
          </div>

          {activeTab === "Project" && (
            <div className="p-6">
              <ImageUpload
                imagePreview={projectImage}
                onImageChange={handleImageChange}
              />
            </div>
          )}

          <form onSubmit={handleSubmit} className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {Object.entries(projectDetails[activeTab]).map(([field, value]) =>
                renderField(field, value)
              )}
            </div>

            <div className="mt-6 flex justify-end space-x-4">
              <ActionButton
                variant="secondary"
                onClick={() => navigate(-1)}
                disabled={isLoading}
              >
                Cancel
              </ActionButton>
              <ActionButton variant="primary" type="submit" loading={isLoading}>
                {activeTab === "Financial"
                  ? "Finish"
                  : projectId
                  ? "Save and Continue"
                  : "Create Project"}
              </ActionButton>
            </div>
          </form>
        </div>
      </div>
    </MainLayout>
  );
};

export default DigitalTwinViewerInsertion;
