// import React from "react";
// import { Link } from "react-router-dom";
// import "./CardGrid.css"; // Make sure to import the updated CSS

// const Card = ({ icon, title, buttons }) => {
//   return (
//     <div className="card">
//       <div className="card-icon">
//         <img src={icon} alt={`${title} Icon`} />
//       </div>
//       <h2 className="card-title">{title}</h2>
//       <div className="card-buttons">
//         {buttons.map((button, index) => (
//           <div key={index} className="card-button">
//             {button.link ? (
//               <Link to={button.link}>{button.label}</Link>
//             ) : (
//               <button>{button.label}</button>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const CardGrid = () => {
//   const cards = [
//     {
//       icon: "https://via.placeholder.com/100", // Replace with your icon URL
//       title: "1. Initiation",
//       buttons: [
//         { label: "Configure Project" },
//         { label: "Set Up KML", link: "/kml-setup" },
//         { label: "Acquire Geodata" },
//       ],
//     },
//     {
//       icon: "https://via.placeholder.com/100",
//       title: "2. STL files",
//       buttons: [
//         { label: "Add Buildings" },
//         { label: "Add Terrain" },
//         { label: "Add Vegetation" },
//         { label: "Check Output Files" },
//       ],
//     },
//     {
//       icon: "https://via.placeholder.com/100",
//       title: "3. Ray tracing",
//       buttons: [
//         { label: "Configure Ray Tracing", link: "/raytracing-config" },
//         { label: "Run Pre-Ray Tracing" },
//         { label: "Run Ray Tracing" },
//         { label: "Run Post-Ray Tracing" },
//       ],
//     },
//     {
//       icon: "https://via.placeholder.com/100",
//       title: "4. Transient",
//       buttons: [
//         { label: "Configure Transient Response" },
//         { label: "Run Transient Response" },
//       ],
//     },
//   ];

//   return (
//     <div className="card-grid">
//       {cards.map((card, index) => (
//         <Card
//           key={index}
//           icon={card.icon}
//           title={card.title}
//           buttons={card.buttons}
//         />
//       ))}
//     </div>
//   );
// };

// export default CardGrid;
import { MainLayout } from "../components/layout/MainLayout";
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Loader,
  Play,
  Settings,
  Map,
  Database,
  LayersIcon,
  Zap,
  AlertCircle,
} from "lucide-react";

// Card Component
const Card = ({ icon: Icon, title, buttons }) => {
  return (
    <div
      className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden
      transition-all duration-200 hover:shadow-lg hover:-translate-y-1"
    >
      <div className="text-center p-6 bg-gray-50 border-b border-gray-200">
        <div className="inline-flex p-3 bg-blue-50 rounded-lg mb-4">
          <Icon className="w-8 h-8 text-blue-600" />
        </div>
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
      </div>

      <div className="p-6 space-y-3">
        {buttons.map((button, index) => (
          <div key={index}>
            {button.link ? (
              <Link
                to={button.link}
                className="block w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 
                  rounded-lg hover:bg-blue-700 transition-colors text-center"
              >
                {button.label}
              </Link>
            ) : (
              <button
                className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 
                  rounded-lg hover:bg-blue-700 transition-colors"
              >
                {button.label}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const CardGrid = () => {
  const cards = [
    {
      icon: Settings,
      title: "1. Initiation",
      buttons: [
        { label: "Configure Project" },
        { label: "Set Up KML", link: "/kml-setup" },
        { label: "Acquire Geodata" },
      ],
    },
    {
      icon: LayersIcon,
      title: "2. STL files",
      buttons: [
        { label: "Add Buildings" },
        { label: "Add Terrain" },
        { label: "Add Vegetation" },
        { label: "Check Output Files" },
      ],
    },
    {
      icon: Zap,
      title: "3. Ray tracing",
      buttons: [
        { label: "Configure Ray Tracing", link: "/raytracing-config" },
        { label: "Run Pre-Ray Tracing" },
        { label: "Run Ray Tracing" },
        { label: "Run Post-Ray Tracing" },
      ],
    },
    {
      icon: Play,
      title: "4. Transient",
      buttons: [
        { label: "Configure Transient Response" },
        { label: "Run Transient Response" },
      ],
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {cards.map((card, index) => (
        <Card
          key={index}
          icon={card.icon}
          title={card.title}
          buttons={card.buttons}
        />
      ))}
    </div>
  );
};
export default CardGrid;
